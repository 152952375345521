&-divenew {
    &-instructions {
        padding: 0 $size-default-lg;
        font-size: 16px;
        text-align: center;

        @include media-breakpoint-down(xs) {
            font-size: inherit;
        }
    }

    &-categories {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        margin: ($size-default-xl) (-$size-default-md) 0;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            margin: calc(#{$size-default-xl} - #{$size-default-sm}) 0 0;
        }
    }

    &-category {
        position: relative;
        display: block;
        flex: 1 0 auto;
        height: 400px;
        margin: 0 $size-default-md;
        border-radius: 10px;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
        transition: box-shadow .3s ease, transform .3s ease;

        @include media-breakpoint-down(lg) {
            height: 350px;
        }
        @include media-breakpoint-down(md) {
            height: 250px;
        }
        @include media-breakpoint-down(sm) {
            flex: 1 0 175px;
            width: 100%;
            margin: $size-default-sm 0;
        }

        &:hover {
            box-shadow: 0 0 $size-default-lg 1px rgba($color-black, .5);
            transform: scale(1.025);
            transition: box-shadow .3s ease, transform .3s ease;

            @include media-breakpoint-down(sm) {
                transform: scale(1.01);
            }

            .divelog-divenew-category-veil {
                opacity: 0;
                transition: opacity .3s ease;
            }

            .divelog-divenew-category-title {
                color: $color-yellow-laser-lemon;
                transition: color .3s ease;

                .cromis-body & {
                    color: $color-blue-aquamarine;
                }
            }
        }

        &-veil {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
            border-radius: 10px;
            background-color: $color-black;
            opacity: .25;
            transition: opacity .3s ease;
        }

        &-title {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
            padding-bottom: $size-default-xxl;
            color: $color-white;
            font-family: Gotham-Bold;
            font-size: 35px;

            @include media-breakpoint-down(md) {
                padding-bottom: $size-default-lg;
                font-size: 26px;
            }
        }
    }

    &-cromis-dive {
        position: relative;
        margin-top: 25px;
        width: max-content;

        .checkbox {
            position: relative;
            z-index: 10;
            display: block;
            height: 25px;
            margin-right: 0;
            margin-bottom: 0;
            padding-left: calc(20px + 15px);
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
        }
    }
}
