div.breadcrumb {
    position: absolute;
    top: -35px;
    margin: 0;
    padding: 0;
    color: $color-white;
    background-color: transparent;
    font-size: 12px;
    line-height: 12px;

    a {
        font-size: 14px;
        font-weight: 600;
        color: $color-white;
        text-shadow: 0 0 10px rgba($color-black, 0.75);

        &:hover {
            text-shadow: none;
        }
    }
}
