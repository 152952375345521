/* ==========================================================================
    ALERT GLOBAL
    ========================================================================== */
.alert-global-container {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
}

.alert.alert-global {
    margin-bottom: 0;
    padding: 15px 30px;
    color: #fff !important;
    border: none;
    box-shadow: 0 0 2px 2px rgba(black, .1);
    transform: translateY(100px);
    animation: pushTop ease-in-out 0.2s forwards;
    @keyframes pushTop {
        from {
            transform: translateY(100px);
        }

        to {
            transform: translateY(0);
        }
    }

    p {
        margin-bottom: 0;
        font-size: 1.2em;
        text-align: center;

        i {
            margin-right: 8px;
        }
    }

    .close {
        opacity: 1;
    }

    &.alert-success {
        background-color: #39d987 !important;
    }

    &.alert-info {
        background-color: #0d6aad !important;
    }

    &.alert-warning {
        background-color: #f7c64f !important;
    }

    &.alert-danger, &.alert-error {
        background-color: #f7574f !important;
    }
}

.fade.in {
    opacity: 1;
}
