&-divingsiteaffiliation {
    /////////////////////////////////////////////////////////////////
    // LIST
    /////////////////////////////////////////////////////////////////
    &-list {
        &-item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            padding: 15px;
            border: 2px solid $color-white;
            border-radius: 5px;
            cursor: pointer;

            @include media-breakpoint-down(xs) {
                padding: 12px;
                flex-direction: column;
                align-items: flex-start;
            }

            &.active {
                background-color: $color-white;
                color: $color-blue-cobalt;

                .cromis-body & {
                    color: $color-green-niagara;
                }

                .checkbox-checkmark {
                    border-color: $color-blue-cobalt;
                    background-color: $color-blue-cobalt;

                    .cromis-body & {
                        border-color: $color-green-niagara;
                        background-color: $color-green-niagara;
                    }
                }
            }

            label {
                font-family: $font-gotham-bold;
                font-size: 18px;
                line-height: 18px;
                cursor: pointer;

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }

                .checkbox-checkmark {
                    position: relative;
                    top: inherit;
                    left: inherit;
                    margin-right: 15px;
                }
            }

            em {
                margin-top: 2px;
                margin-left: 15px;
                font-size: 14px;
                line-height: 16px;
                user-select: none;

                @include media-breakpoint-down(xs) {
                    margin-top: 3px;
                    margin-left: 34px;
                }
            }
        }
    }
    /////////////////////////////////////////////////////////////////
    // SUMMARY
    /////////////////////////////////////////////////////////////////
    &-summary {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 100px;
        box-shadow: 0 -10px 90px 0 rgba($color-black, .16), 0 -6px 90px 0 rgba($color-black, .23);
        background-color: #13345d;

        @include media-breakpoint-down(sm) {
            height: 84px;
        }
        @include media-breakpoint-down(xs) {
            height: 105px;
        }

        &-inner {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 25px 0;

            @include media-breakpoint-down(sm) {
                padding: 15px 0;
            }
            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
        }

        &-title {
            margin-right: 50px;
            font-family: $font-gotham-bold;
            font-size: 24px;

            @include media-breakpoint-down(xs) {
                margin-right: 0;
                margin-bottom: 12px;
                font-size: 16px;
                line-height: 25px;
            }

            span {
                margin-right: 5px;
                vertical-align: -4px;
                font-size: 36px;

                @include media-breakpoint-down(xs) {
                    font-size: 30px;
                }
            }
        }

        &-button {
            @include media-breakpoint-down(xs) {
                padding: 5px 20px;
                font-size: 15px !important;
                letter-spacing: 3px;
            }
        }
    }
}
