.pagination {
    align-items: center;
    margin: 20px 0;
    text-align: center;

    .current,
    .page {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        border: 1px solid $color-grey-mercury;
        line-height: 40px;

        &:hover {
            border-color: $color-primary;

            .cromis-body & {
                border-color: $color-cromis-primary;
            }

            a {
                color: $color-primary;
                text-decoration: none;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }
        }

        a {
            padding: 10px;
        }
    }

    .current {
        border-color: $color-primary;
        color: $color-primary;

        .cromis-body & {
            border-color: $color-cromis-primary;
            color: $color-cromis-primary;
        }
    }

    .first,
    .last,
    .next,
    .previous {
        margin: 0 5px;
    }
}

.navigation--ffessm {
    @include responsive-xl {
        display: flex;
        justify-content: center;
    }

    .pagination {
        margin: $size-default-xxl 0 $size-default-md;

        @include responsive-sm {
            margin: $size-default-lg 0 $size-default-md;
        }

        &-item {
            width: 50px;
            height: 50px;
            margin: 0 $size-default-lg 0 0;
            border: 2px solid $color-primary;
            font-weight: bold;
            line-height: 50px;

            .cromis-body & {
                border: 2px solid $color-cromis-primary;
            }

            @include responsive-lg {
                width: 45px;
                height: 45px;
                margin: 0 25px 0 0;
                line-height: 45px;
            }
            @include responsive-sm {
                width: 30px;
                height: 30px;
                margin: 0 15px 0 0;
                line-height: 30px;
            }

            &:last-of-type {
                margin: 0;
            }
        }

        .page {
            a {
                display: block;
                height: 100%;
                padding: 0;
                color: $color-primary;

                .cromis-body & {
                    color: $color-cromis-primary;
                }

                &:hover {
                    color: $color-white;
                    background-color: $color-primary;

                    .cromis-body & {
                        background-color: $color-cromis-primary;
                    }
                }
            }
        }

        .current {
            color: $color-white;
            background-color: $color-primary;

            .cromis-body & {
                background-color: $color-cromis-primary;
            }
        }

        .next,
        .previous {
            width: inherit;
            min-width: 50px;
            font-family: 'bebas_neuebold';

            @include responsive-lg {
                min-width: 45px;
            }
            @include responsive-md {
                font-family: inherit;
            }
            @include responsive-sm {
                min-width: 30px;
            }

            a {
                display: block;
                height: 100%;
                padding: 0 30px;
                color: $color-primary;
                font-size: 18px;
                letter-spacing: 4px;

                .cromis-body & {
                    color: $color-cromis-primary;
                }

                @include responsive-md {
                    padding: 0;
                    font-size: inherit;
                }

                &:hover {
                    color: $color-white;
                    background-color: $color-primary;

                    .cromis-body & {
                        background-color: $color-cromis-primary;
                    }
                }
            }

            &-label {
                height: 100%;

                @include responsive-md {
                    display: none;
                }

                &--mobile {
                    display: none;

                    @include responsive-md {
                        display: block;
                    }
                }
            }
        }

        .previous a {
            @include responsive-sm {
                padding-left: $size-default-xxs;
            }
        }

        .next a {
            @include responsive-sm {
                padding-left: $size-default-xs;
            }
        }
    }
}

.navigation--divelog {
    display: flex;
    align-items: center;

    @include responsive-xl {
        display: flex;
        justify-content: center;
    }

    .pagination {
        margin: $size-default-xxl auto $size-default-md;

        @include responsive-sm {
            margin: $size-default-lg auto $size-default-md;
        }

        &-item {
            width: 50px;
            height: 50px;
            margin: 0 $size-default-lg 0 0;
            border: 2px solid $color-white;
            font-weight: bold;
            line-height: 50px;

            @include responsive-lg {
                width: 45px;
                height: 45px;
                margin: 0 25px 0 0;
                line-height: 45px;
            }
            @include responsive-sm {
                width: 30px;
                height: 30px;
                margin: 0 15px 0 0;
                line-height: 30px;
            }

            &:last-of-type {
                margin: 0;
            }
        }

        .page {
            a {
                display: block;
                height: 100%;
                padding: 0;
                color: $color-white;

                &:hover {
                    color: $color-blue-cobalt;
                    background-color: $color-white;

                    .cromis-body & {
                        color: $color-green-niagara;
                    }
                }
            }

            &:hover {
                border-color: $color-white !important;
            }
        }

        .current {
            color: $color-blue-cobalt;
            background-color: $color-white;

            .cromis-body & {
                color: $color-green-niagara;
            }

            &:hover {
                border-color: $color-white;
            }
        }

        .next,
        .previous {
            width: inherit;
            min-width: 50px;
            font-family: 'bebas_neuebold';

            @include responsive-lg {
                min-width: 45px;
            }
            @include responsive-md {
                font-family: inherit;
            }
            @include responsive-sm {
                min-width: 30px;
            }

            a {
                display: block;
                height: 100%;
                padding: 0 30px;
                color: $color-white;
                font-size: 18px;
                letter-spacing: 4px;

                @include responsive-md {
                    padding: 0;
                    font-size: inherit;
                }

                &:hover {
                    color: $color-blue-cobalt;
                    background-color: $color-white;

                    .cromis-body & {
                        color: $color-green-niagara;
                    }
                }
            }

            &-label {
                height: 100%;

                @include responsive-md {
                    display: none;
                }

                &--mobile {
                    display: none;

                    @include responsive-md {
                        display: block;
                    }
                }
            }
        }

        .previous a {
            @include responsive-sm {
                padding-left: $size-default-xxs;
            }
        }

        .next a {
            @include responsive-sm {
                padding-left: $size-default-xs;
            }
        }
    }
}
