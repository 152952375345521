[data-hidden=true] {
    display: none !important;
}

.ffessm-button {
    font-size: 18px;
    user-select: none;
    transition: color .3s ease, background-color .3s ease, border-color .3s ease;

    &:not(.ffessm-button--noclick):not([disabled]):hover, &-active {
        border-color: $color-white;
        background-color: $color-white !important;
        color: $color-blue-cobalt !important;
        transition: color .2s ease, background-color .2s ease, border-color .2s ease;

        .cromis-body & {
            color: $color-green-niagara !important;
        }
    }

    &--noclick {
        cursor: not-allowed;

        &-active {
            cursor: default;
        }
    }

    &--reverse {
        color: $color-blue-cobalt !important;

        .cromis-body & {
            color: $color-green-niagara !important;
        }

        &:not(.ffessm-button--noclick):not([disabled]):hover {
            background-color: transparent !important;
            color: $color-white !important;
            transition: color .2s ease, background-color .2s ease, border-color .2s ease;

            .cromis-body & {
                color: $color-white !important;
            }

            svg {
                fill: $color-white;
                transition: fill .2s ease;

                .cromis-body & {
                    fill: $color-white !important;
                }
            }
        }
    }

    svg {
        fill: $color-blue-cobalt;
        transition: fill .2s ease;

        .cromis-body & {
            fill: $color-green-niagara !important;
        }
    }
}

.contentpage-discoveries {
    &-element-title {
        color: $color-white;
    }

    &-metas {
        color: $color-blue-cyan;
    }
}
// &-input-button {
//     display: flex;
//     opacity: 1;
//     transition: $opacity-transition;
//
//     &.disabled {
//         opacity: .5;
//         transition: $opacity-transition;
//
//         & > * {
//             pointer-events: none;
//             user-select: none;
//         }
//     }
//
//     input {
//         border-top-right-radius: 0 !important;
//         border-bottom-right-radius: 0 !important;
//     }
//
//     button {
//         padding: 0 25px;
//         border: 2px solid $color-white;
//         border-top-right-radius: 5px;
//         border-bottom-right-radius: 5px;
//         outline: none;
//         color: $color-white;
//         background-color: $color-blue-cobalt;
//         font-size: 14px;
//         font-weight: 600;
//         line-height: 28px;
//         text-transform: none;
//         cursor: pointer;
//     }
// }
