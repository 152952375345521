$posts-alt-margin: calc(-100vw + (#{$container-width} + (100vw - #{$container-width}) / 2) - #{$size-default});
$posts-alt-padding: calc(100vw - (#{$container-width} + (100vw - #{$container-width}) / 2) + #{$size-default});

.posts {
    &-item {
        position: relative;
        padding: $size-default-lg 0;
        @include responsive-md {
            padding: $size-default-md 0;
        }

        &--first {
            padding-top: 0;
        }

        &-background {
            position: absolute;
            left: -100vw;
            top: 0;
            right: -45px;
            bottom: 0;
            background-color: $color-grey-mercury-lighter;
            z-index: -1;
        }

        &--alt {
            @include responsive-sm {
                .posts-item-row-colimage {
                    padding: 0 $size-default-md 0 0;
                }
            }
        }

        &-row {
            @include responsive-sm {
                flex-wrap: inherit;

                & > [class*="col-"] {
                    width: inherit;
                }
            }

            &--reverse {
                @include responsive-sm {
                    justify-content: space-between;
                }
            }

            &-colimage {
                @include responsive-md {
                    padding-right: $size-default-xs;
                }
                @include responsive-sm {
                    padding-right: 0;
                }
            }
        }

        &-image {
            &::after {
                padding-bottom: 100%;
            }
            @include responsive-sm {
                width: 120px;
            }
        }

        &-content {
            padding: 10px 0;
            @include responsive-lg {
                padding: 0;
            }

            &-title {
                line-height: 1.5;
            }
        }

        *.posts-item-title {
            display: block;
            margin-bottom: 30px;
            font-family: 'Gotham-Bold';
            font-size: 24px;

            &:hover {
                color: $color-primary;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }
            @include responsive-lg {
                margin-bottom: 15px;
            }
            @include responsive-sm {
                margin: 0;
                font-size: 18px;
            }
        }

        &-description {
            margin-bottom: 30px;
            @include responsive-lg {
                margin-bottom: 15px;
            }
            @include responsive-sm {
                display: none;
            }
        }

        &-infos {
            display: flex;
            align-items: center;

            &:not(.posts-item-infos--mobile) {
                @include responsive-sm {
                    display: none;
                }
            }

            &--mobile {
                display: none;
                margin-top: $size-default-md;
                @include responsive-sm {
                    display: flex;
                }
            }
        }
    }
}
