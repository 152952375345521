.main {
  flex: 1 1 auto;
  padding: 1.25rem;

  &_section {
    &--primary {
      .card {
        min-height: 12.5rem;
        color: $white;
      }
    }

    &--secondary {
      .card {
        min-height: 9.375rem;
      }
    }
  }

  @include respond-to('large') {
    padding: 3.75rem 1.25rem;
  }
}

.card--1 {
  grid-column: 1 / 5;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(../img/portal/img-ffe@2x.png) center center no-repeat;
    background-size: cover;
    z-index: 1;
  }

  @include respond-to('medium') {
    .card_description {
      font-size: 1.125rem;
      line-height: 1.6;
    }
  }

  @include respond-to('large') {
    grid-column: 1 / 7;
    grid-row: 1 / 3;
  }
}

.card--2 {
  grid-column: 1 / 5;
  background-color: $blue !important;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: url(../img/portal/Pin-big.svg) bottom -1rem left 2rem no-repeat;
    opacity: 0.1;
    z-index: 1;
  }

  @include respond-to('medium') {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  @include respond-to('large') {
    grid-column: 7 / 10;
    grid-row: 1;
  }
}

.card--3 {
  grid-column: 1 / 5;
  background-color: $red !important;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: url(../img/portal/User-big.svg) bottom -1rem left 2rem no-repeat;
    opacity: 0.1;
    z-index: 1;
  }

  @include respond-to('medium') {
    grid-column: 3 / 5;
    grid-row: 2;
  }

  @include respond-to('large') {
    grid-column: 10 / 13;
    grid-row: 1;
  }
}

.card--4 {
  grid-column: 1 / 5;
  background-image: url(../img/portal/img-trouver-club@2x.jpeg);
  background-size: cover;

  @include respond-to('medium') {
    grid-column: 1 / 3;
    grid-row: 3;
  }

  @include respond-to('large') {
    grid-column: 7 / 10;
    grid-row: 2;
  }
}

.card--5 {
  grid-column: 1 / 5;
  background-image: url(../img/portal/img-Carnet-plonge@2x.png);
  background-size: cover;

  @include respond-to('medium') {
    grid-column: 3 / 5;
    grid-row: 3;
  }

  @include respond-to('large') {
    grid-column: 10 / 13;
    grid-row: 2;
  }
}

.card--6 {
  grid-column: 1 / 5;

  @include respond-to('large') {
    grid-column: 1 / 5;

    .card_description {
      max-width: 17.5rem;
    }
  }
}

.card--7 {
  grid-column: 1 / 5;

  @include respond-to('large') {
    grid-column: 5 / 9;

    .card_description {
      max-width: 17.5rem;
    }
  }
}

.card--8 {
  grid-column: 1 / 5;
  padding-right: 6.25rem !important;
  background: url(../img/portal/img-Boutique@2x.png) bottom right 3rem no-repeat;
  background-size: 58px 135px;

  @include respond-to('large') {
    grid-column: 9 / 13;

    .card_description {
      max-width: 16.25rem;
    }
  }
}

.divider {
  margin: 3.125rem 0 2rem;
  color: $grey-100;
  font-family: $font-secondary;
  font-size: 1.125rem;
  letter-spacing: 0.25rem;
  line-height: 1.3;
  text-align: center;
}

.fake-button {
  margin-top: 0.75rem;
  background: url(../img/portal/pin-small.svg) center left 1.25rem no-repeat, 
              url(../img/portal/fleche.svg) center right 1.25rem no-repeat;
}