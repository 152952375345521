.section-title {
    margin-bottom: 20px;
    font-family: 'bebas_neuebold';
    font-size: 18px;
    letter-spacing: 3px;

    &--big {
        font-size: 36px;
        @include responsive-md() {
            font-size: 26px;
            text-align: center;
        }
    }
}

.homeslider {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
    margin-left: -15px;
    background: $gradient-cerulean-blumine;

    &-slide {
        display: flex;
        align-items: center;
        flex-basis: auto;
        justify-content: center;
        width: 100vw;
        height: 100%;
        min-height: 100%;
        background-position: center center;
        background-size: cover;

        &-video {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
        }

        &-caption {
            position: absolute;
            left: 0;
            width: 100%;
            text-align: center;
            color: $color-white;

            h2 {
                padding-left: 10px;
                font-family: 'bebas_neuebold';
                font-size: 120px;
                letter-spacing: 10px;
                @include responsive-md() {
                    font-size: 70px;
                }
            }

            p {
                font-family: 'Gotham-Book';
                font-size: 30px;
                @include responsive-md() {
                    font-size: 20px;
                }
            }

            &-cta {
                display: inline-block;
                padding: 10px 25px;
                font-family: 'bebas_neuebold';
                letter-spacing: 5px;
                text-transform: uppercase;
                color: $color-white;
                border: 2px solid $color-white;
                background-color: $color-primary;

                .cromis-body & {
                    background-color: $color-cromis-primary;
                }
            }
        }

        .image-responsive {
            width: 100%;
            min-height: 100%;
            background-size: cover;
            background-position: center center;
        }
    }
    
    .image-background {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .owl-item {
        height: 100vh;
    }

    .owl-dots {
        position: absolute;
        width: 100%;
        margin-top: -60px;
        text-align: center;

        .owl-dot {
            width: 15px;
            height: 15px;
            margin: 0 15px;
            border: 2px solid $color-white;
            border-radius: 50%;
            background-color: transparent;

            &.active {
                background-color: $color-white;
            }
        }
    }

    &-nav {
        position: absolute;
        z-index: 10;
        top: 50%;
        width: 100vw;
        margin-left: -15px;
        padding: 0 100px;
        transform: translateY(-50%);

        nav {
            display: flex;
            justify-content: space-between;

            a {
                display: flex;
                overflow: hidden;
                align-items: center;
                flex-wrap: nowrap;
                justify-content: space-between;
                position: relative;
                width: 20px;
                height: 50px;
                color: $color-white;
                transition: width 0.5s;

                &:active,
                &:focus,
                &:hover {
                    color: $color-white;
                }

                svg {
                    position: absolute;
                    width: 10px;
                }

                span {
                    display: none;
                }

                &:hover {
                    width: 150px;
                    padding: 0 10px;
                    border: 2px solid $color-white;

                    span {
                        display: block;
                        position: absolute;
                    }
                }
            }

            a.homeslider-nav-prev {
                @include responsive-md() {
                    display: none;
                }

                svg {
                    left: 10px;
                }

                &:hover {
                    span {
                        right: 10px;
                    }

                    &::before {
                        content: '';
                        width: 20px;
                        height: 2px;
                        margin-left: 3px;
                        background-color: $color-white;
                    }
                }
            }

            a.homeslider-nav-next {
                @include responsive-md() {
                    display: none;
                }

                svg {
                    right: 10px;
                }

                &:hover {
                    span {
                        left: 10px;
                    }

                    &::after {
                        content: '';
                        width: 20px;
                        height: 2px;
                        margin-left: 103px;
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}

.newssection {
    position: relative;
    height: 600px;
    margin: 120px -15px 0;
    background: $gradient-cerulean-blumine;
    @include responsive-xl() {
        height: 450px;
    }
    @include responsive-md() {
        height: 250px;
        margin-top: 98px;
    }

    &-slider {
        z-index: 0;

        &-link,
        &-slide {
            position: relative;
            width: 100vw;
            height: 600px;
            display: block;
            @include responsive-xl() {
                height: 450px;
            }
            @include responsive-md() {
                height: 250px;
            }
        }

        &-slide {
            background-position: center center;
            background-size: cover;
            @include responsive-md() {
                height: 250px;
            }

            &-gradient {
                position: absolute;
                bottom: 0;
                width: 100vw;
                height: 200px;
                opacity: 0.5;
                background: $gradient-blackrussian;
            }

            &-caption {
                position: absolute;
                bottom: 40px;
                left: 100px;
                width: 37vw;
                color: $color-white;
                @include responsive-xl() {
                    bottom: 70px;
                    left: 55px;
                }
                @include responsive-lg() {
                    bottom: 90px;
                }
                @include responsive-md() {
                    left: 15px;
                    bottom: 55px;
                    width: calc(100% - 95px);
                }

                span {
                    font-family: 'bebas_neuebold';
                    font-size: 18px;
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    @include responsive-md() {
                        font-size: 16px;
                    }
                }

                h3 {
                    margin-top: 20px;
                    font-family: 'Gotham-Bold';
                    font-size: 30px;
                    @include responsive-lg() {
                        font-size: 26px;
                    }
                    @include responsive-md() {
                        font-size: 18px;
                    }
                }
            }
        }

        .owl-dots {
            position: absolute;
            left: 44vw;
            margin-top: -60px;
            text-align: center;
            @include responsive-xl() {
                left: 40vw;
            }
            @include responsive-lg() {
                left: 32vw;
            }
            @include responsive-md() {
                right: 60px;
                left: inherit;
                margin-top: -45px;
            }

            .owl-dot {
                width: 15px;
                height: 15px;
                margin: 0 15px;
                border: 2px solid $color-white;
                border-radius: 50%;
                background-color: transparent;
                @include responsive-md() {
                    margin-left: 5px;
                }

                &.active {
                    background-color: $color-white;
                }
            }
        }
    }

    &-events {
        margin-top: -200px;
        padding: 35px;
        background-color: $color-white;
        @include responsive-lg() {
            margin-right: -15px;
            padding: 20px 15px 20px 20px;
        }
        @include responsive-md() {
            margin-top: 0;
            margin-right: -15px;
            margin-left: -15px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &-files {
        padding: 20px;
        background-color: #e9e9e9;
        @include responsive-lg() {
            margin-right: -15px;
        }
        @include responsive-md() {
            margin-left: -15px;
            margin-right: -15px;
            padding: 20px 15px 15px;
        }
        @include responsive-sm() {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &-list {
            padding: 0;
            list-style: none;

            &-file {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;

                &-link {
                    display: flex;
                    align-items: center;
                    @include responsive-lg {
                        font-size: 14px;
                    }
                }

                &-image {
                    width: 100px;
                    margin-right: 15px;
                    flex: 1 0 auto;
                }
            }
        }

        &-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            background-color: #e9e9e9;
        }

        &-wrapper {
            position: relative;
            z-index: 3;
        }
    }

    &-news {
        &-title {
            margin-top: $size-default-xl;
            @include responsive-xl {
                margin-top: 25px;
            }
        }

        &-slider {
            padding-right: 50px;
            @include responsive-xl() {
                padding-right: 30px;
            }
            @include responsive-lg() {
                padding-right: 20px;
            }
            @include responsive-md() {
                padding-right: 0;
            }

            &-container {
                position: relative;
                @include responsive-md {
                    margin-right: -15px;
                }
            }

            &-slide {
                display: block;
                @include responsive-xl {
                    width: 286px;
                }
                @include responsive-lg {
                    width: 221px;
                }
                @include responsive-md {
                    width: 225px;
                }

                &-caption {
                    margin-top: 15px;

                    &-title {
                        font-size: 1.5rem;
                        @include responsive-xl() {
                            font-size: 20px;
                        }
                        @include responsive-md() {
                            font-size: 15px;
                        }
                    }

                    span {
                        margin-top: 15px;
                    }
                }
            }

            .owl-dots {
                position: absolute;
                right: 50px;
                bottom: -60px;
                text-align: center;
                @include responsive-md {
                    right: 15px;
                    bottom: -35px;
                }

                .owl-dot {
                    width: 15px;
                    height: 15px;
                    margin: 0 15px;
                    border: 2px solid $color-grey-dovegray;
                    border-radius: 50%;
                    background-color: transparent;

                    &.active {
                        background-color: $color-grey-dovegray;
                    }
                }
            }

            &-nav {
                position: absolute;
                z-index: 2;
                top: calc(293px - 60px);
                right: 50px;
                @include responsive-xl() {
                    top: calc(226px - 60px);
                    right: 30px;
                }
                @include responsive-lg() {
                    top: calc(171px - 50px);
                    right: 20px;
                }
                @include responsive-md() {
                    top: -50px;
                    right: 0;
                }

                button {
                    display: block;
                    width: 60px;
                    height: 60px;
                    border: 0;
                    background-color: $color-grey-wild-sand;
                    cursor: pointer;
                    @include responsive-lg {
                        width: 50px;
                        height: 50px;
                    }

                    &:last-of-type > svg {
                        @include responsive-lg {
                            margin-left: 4px;
                        }
                    }

                    &:hover {
                        background-color: $color-primary;

                        .cromis-body & {
                            background-color: $color-cromis-primary;
                        }

                        svg {
                            path {
                                fill: $color-white;
                            }
                        }
                    }

                    svg {
                        @include responsive-lg {
                            vertical-align: -3px;
                            width: 15px;
                        }
                    }
                }
            }
        }

        &-more {
            margin-top: $size-default-xl;
            margin-bottom: $size-default-xl;
            @include responsive-md {
                margin-top: $size-default-md;
                margin-bottom: $size-default-md;
            }
        }
    }
}

.activitiessection {
    margin-top: $size-default-xxl;
    @include responsive-md {
        margin-top: $size-default-lg;
    }

    &-title {
        margin-bottom: $size-default-xl;
        @include responsive-md {
            margin-bottom: $size-default-lg;
        }
    }

    &-row {
        padding-left: $size-default-md;
    }

    &-element {
        margin-bottom: $size-default-xs;
        text-align: center;
        @include responsive-md() {
            margin-bottom: $size-default-xxs;
            font-size: 13px;
        }

        &-img {
            margin-bottom: 10px;
            overflow: hidden;

            .col-md-3rd & {
                height: 105px;
            }

            .col-md-4th & {
                height: 105px;
            }

            &:hover {
                img {
                    transform: scale(1.3);
                }
            }

            img {
                display: block;
                width: 100%;
                transition: transform 0.3s;
            }
        }

        img {
            @include responsive-md() {
                width: 100%;
            }
        }
    }

    &-more {
        margin-top: $size-default-xl;
        @include responsive-md {
            margin-top: $size-default-md;
        }
    }
}

.ecosystemsection {
    margin-top: $size-default-xl;
    padding: 50px 0 80px;
    background-color: $color-grey-wild-sand;
    @include responsive-md {
        margin-top: $size-default-lg;
        padding: 30px 0 15px;
    }

    .row:last-child {
        .ecosystemsection-element {
            padding-bottom: 0;
            @include responsive-md {
                padding-bottom: 30px;
            }
        }
    }

    &-element {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 30px 30px;

        h4 {
            font-family: 'Gotham-Bold';
            font-size: 18px;
        }

        p {
            margin: 0;
            font-family: 'Gotham-Book';
            font-size: 14px;
        }

        img {
            width: 100px;
            margin-right: 15px;
            filter: grayscale(100%);
            @include responsive-lg {
                width: 75px;
            }
        }

        &:hover {
            img {
                filter: grayscale(0);
            }
        }
    }
}

body.mobile,
body.tablet {
    .homeslider {
        &-slide {
            .image-responsive {
                display: block;
                width: 100vw;
                height: 100vh;
                background-position: center center;
                background-size: cover;
            }
        }
    }
}
