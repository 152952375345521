/////////////////////////////////////////////////////////////////
// OBSERVATIONS
/////////////////////////////////////////////////////////////////
&-diveeditobservations {
    &-search {
        padding-right: 21px;

        @include media-breakpoint-down(md) {
            padding-right: 0;
        }
    }

    &-colright {
        padding-left: 0;

        @include media-breakpoint-down(md) {
            margin-top: 50px;
            padding-left: 15px;
        }
        @include media-breakpoint-down(xs) {
            margin-top: 30px;
        }
    }

    &-list {
        &-title {
            margin-bottom: 15px;
            color: $color-yellow-laser-lemon;
            font-family: $font-gotham-bold;
            font-size: 30px;
            line-height: 40px;

            .cromis-body & {
                color: $color-blue-aquamarine;
            }

            small {
                margin-left: 10px;
                vertical-align: 3px;
                font-family: $font-gotham;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
            }
        }

        &-overflow {
            overflow-y: scroll;
            height: 450px;
            padding-right: 15px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba($color-blue-regal, .5);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $color-blue-malibu;
            }

            .form-group {
                margin-bottom: 0;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            height: 105px;
            width: 100%;
            border: 2px solid $color-white;
            border-radius: 5px;
            background-color: $color-blue-cobalt;

            .cromis-body & {
                background-color: $color-green-niagara;
            }

            @include media-breakpoint-down(xs) {
                height: 65px;
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            &.active {
                background-color: $color-white;
                color: $color-blue-cobalt;

                .cromis-body & {
                    color: $color-green-niagara;
                }

                .checkbox-checkmark {
                    border-color: $color-blue-cobalt;
                    background-color: $color-blue-cobalt;

                    .cromis-body & {
                        border-color: $color-green-niagara;
                        background-color: $color-green-niagara;
                    }
                }

                .divelog-diveeditobservations-list-item-content-link {
                    img {
                        display: none;

                        & + img {
                            display: block;
                        }
                    }
                }
            }

            &-picture {
                flex-basis: 150px;
                flex-grow: 0;
                flex-shrink: 0;
                width: 150px;
                border-right: 2px solid $color-white;
                cursor: pointer;

                @include media-breakpoint-down(xs) {
                    flex-basis: 85px;
                    width: 85px;
                }
            }

            &-content {
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                user-select: none;

                &-label,
                &-link {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding: 20px;

                    @include media-breakpoint-down(xs) {
                        padding: 15px;
                    }
                }

                &-label {
                    position: relative;
                    flex-grow: 1;
                    //flex-direction: column;
                    //justify-content: left;
                    cursor: pointer;

                    label {
                        font-family: $font-gotham-bold;
                        cursor: pointer;
                        user-select: none;
                        //align-self: stretch;

                        @include media-breakpoint-down(xs) {
                            align-items: center;
                        }

                        .checkbox-checkmark {
                            position: relative;
                            top: inherit;
                            left: inherit;
                            margin-right: 20px;

                            @include media-breakpoint-down(xs) {
                                margin-right: 15px;
                            }
                        }
                    }

                    &-features {
                        //align-self: stretch;
                        img {
                            margin: 5px 0 0 5px;
                            max-width: 25px;
                            max-height: 25px;
                            width: auto;
                            height: auto;
                        }
                    }
                }

                &-link {
                    @include media-breakpoint-down(xs) {
                        flex-basis: 45px;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }

                    img {
                        width: 15px;

                        & + img {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &-summary {
        height: 100%;
        width: 100%;
        padding: 20px 15px;
        border-radius: 5px;
        background-color: $color-white;
        color: $color-blue-cobalt;

        &-title {
            margin-bottom: 15px;
            color: $color-blue-cobalt;
            font-family: $font-gotham-bold;
            font-size: 30px;
            line-height: 40px;
            text-align: center;

            .cromis-body & {
                color: $color-cromis-primary;
            }

            @include media-breakpoint-down(xs) {
                font-size: 22px;
                line-height: 30px;
            }

            small {
                margin-left: 10px;
                vertical-align: 3px;
                font-family: $font-gotham;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
            }
        }

        &-overflow {
            overflow-y: scroll;
            height: 366px;
            padding-right: 10px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba($color-blue-regal, .25);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $color-blue-malibu;
            }
        }

        &-items {
            display: flex;
            flex-wrap: wrap;
            margin-right: -5px;
            margin-left: -5px;
        }

        &-item {
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            width: calc((100% - 30px) / 3);
            margin: 0 5px 10px;
            border: 2px solid $color-blue-cobalt;
            border-radius: 5px;
            background-color: $color-white;

            .cromis-body & {
                border: 2px solid $color-green-niagara;
            }

            @include media-breakpoint-down(xs) {
                width: calc((100% - 20px) / 2);
            }

            &-checkbox {
                position: absolute;
                top: -2px;
                left: -2px;
                z-index: 1;

                div.checkbox {
                    height: 20px;
                    width: 20px;

                    &-checkmark {
                        top: 0;
                        border: 2px solid $color-blue-cobalt;
                        background-color: $color-white;

                        .cromis-body & {
                            border: 2px solid $color-green-niagara;
                        }

                        &-inner {
                            display: block;
                            background-image: url('/img/ui/check-blue.svg');
                        }
                    }
                }
            }

            &-picture {
                position: relative;
                height: 113px;
                border-bottom: 2px solid $color-blue-cobalt;

                .cromis-body & {
                    border-bottom: 2px solid $color-green-niagara;
                }

                @include media-breakpoint-down(lg) {
                    height: 78px;
                }

                &:hover {
                    & > .divelog-picture-action {
                        opacity: 1;
                        transition: $opacity-slow-transition;
                    }
                }

                & > .divelog-picture-action {
                    opacity: 0;
                    transition: $opacity-slow-transition;
                }

                & > .divelog-species-item-hover {
                    opacity: 0;
                    transition: $opacity-slow-transition;
                }
            }

            &-label {
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                min-height: 50px;
                color: $color-blue-cobalt !important;
                font-family: $font-gotham-bold;
                font-size: 16px !important;
                line-height: 18px !important;
                text-align: center;
                user-select: none;

                .cromis-body & {
                    color: $color-green-niagara !important;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 13px !important;
                    line-height: 16px !important;
                }

                &:hover {
                    & + .divelog-diveeditobservations-summary-item-picture {
                        & > .divelog-species-item-hover {
                            opacity: 1;
                            transition: $opacity-slow-transition;
                        }
                    }
                }

                &-text {
                    padding: 15px;

                    @include media-breakpoint-down(lg) {
                        padding: 13px 15px;
                    }
                    @include media-breakpoint-down(xs) {
                        padding: 10px 15px;
                    }
                }
            }
        }

        &-doris {
            display: flex;
            align-items: flex-end;
            margin-top: 20px;
            color: $color-blue-azure-radiance !important;

            img {
                max-width: 100px;
            }

            strong {
                margin-left: 20px;

                @include media-breakpoint-down(xs) {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
}
