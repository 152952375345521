.collection {
    padding: $size-default-md 0;

    &--inline {
        .collection-item-content {
            .form-group {
                margin-bottom: 0;
            }
        }
    }

    & > .form-group {
        margin-bottom: $size-default-md;
        padding: $size-default-md;
        border: 2px solid $color-grey-alto;
    }

    & > .ffessm-button {
        padding: $size-default-sm $size-default-md;
        font-size: 13px;
        letter-spacing: 3px;
    }

    &-item {
        &-body {
            display: flex;
            align-items: flex-end;
        }

        &-content {
            display: flex;
            flex-flow: row wrap;
            flex: 0 0 calc(100% - 37px);
            margin: -$size-default-sm;

            .form-group {
                flex: 0 0 calc(50% - #{$size-default-md});
                margin: $size-default-sm !important;

                @include media-breakpoint-down(sm) {
                    flex: 0 0 calc(100% - #{$size-default-md});
                }

                .control-label {
                    font-size: 12px !important;
                }
            }
        }

        &-actions {
            margin-left: $size-default-md;

            &-remove {
                display: block;
                width: 37px;
                height: 37px;
                margin-bottom: 2px;
                background-color: rgba($color-red-alizarin, 0.75);
                color: $color-white !important;
                text-align: center;
                line-height: 37px;

                &:hover {
                    background-color: $color-red-alizarin;
                }
            }
        }
    }
}
