&--login {
    .ssoLogin {
        i, small {
            display: none;
        }

        &--loading {
            cursor: initial !important;

            i {
                display: inline-block;
            }

            small {
                display: block;
            }
        }
    }
}