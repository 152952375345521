&-divingsitenew {
    /////////////////////////////////////////////////////////////////
    // COORDINATED
    /////////////////////////////////////////////////////////////////
    &-coordinates {
        margin-bottom: 20px;
        padding: 25px 30px 30px;
        border: 2px solid rgba($color-white, .5);
        border-radius: 5px;

        @include media-breakpoint-down(sm) {
            padding: 20px;
        }

        @include media-breakpoint-down(xs) {
            padding: 15px;
        }

        &-fields {
            display: flex;
            margin-bottom: 10px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        &-left {
            flex: 0 0 calc(36.5% - 40px);
            margin-right: 80px;

            @include media-breakpoint-down(lg) {
                flex: 0 0 calc(32.5% - 40px);
                margin-right: 50px;
            }
            @include media-breakpoint-down(md) {
                flex: inherit;
                margin-right: 0;
            }
        }

        &-right {
            flex: 1 0 auto;
        }

        &-longitude {
            .form-group {
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
        }

        &-dms-helper {
            margin-left: 10px;
            font-size: 25px;
            font-weight: 600;
            line-height: 30px;

            @include media-breakpoint-down(sm) {
                margin-left: 5px;
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
    /////////////////////////////////////////////////////////////////
    // ACCESS
    /////////////////////////////////////////////////////////////////
    &-access {
        &-item {
            &.active {
                .divelog-divingsitenew-access {
                    &-row {
                        height: 200px;
                        transition: height .3s ease 0s;

                        @include media-breakpoint-down(sm) {
                            height: 170px;
                        }
                    }
                }
            }
        }

        &-row {
            overflow: hidden;
            height: 0;
            transition: height .3s ease 0s;
        }
    }
    /////////////////////////////////////////////////////////////////
    // FURTHER INFO
    /////////////////////////////////////////////////////////////////
    &-furtherinfo {
    }
}
