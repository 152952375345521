form {
    // margin-top: 35px;
}

.help-block {
    ul {
        margin-top: $size-default-xs;
        margin-bottom: 0;
        font-size: 12px;
        color: $color-red-alizarin;
    }
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;

    .form-control-label {
        position: absolute;
        top: 0;
        padding: 7px 0 0 13px;
        font-family: 'Gotham-Bold';
        font-size: 14px;
        opacity: 1;
        transition: all 200ms;
    }

    .col-form-label {
        padding-top: calc(.375rem + 1px);
        padding-bottom: calc(.375rem + 1px);
        font-family: 'Gotham-Bold';
        font-size: 14px;
    }

    .required {
        &::after {
            content: '*';
        }
    }
}

.form-group-labelOut {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    input,
    label {
        display: inline-block;
        margin: 0 15px 0 0;
        font-family: 'Gotham-Bold';
        font-size: 14px;
    }

    input {
        position: static;
        width: 80%;
    }
}

input[type=date] {
    padding-right: 30px;
    font-size: 12px;
    background: url("/img/cal.svg") $color-white no-repeat center right 10px;
}

.form-group-labelUp {
    position: relative;
    margin-bottom: 1.5rem;
    @extend .form-group;

    .form-control-label,
    .form-control:invalid ~ .form-control-label {
        font-size: 75%;
        opacity: 1;
        transform: translate3d(0, -110%, 0);
    }
}

.form-control:focus ~ .form-control-label,
.form-control:valid ~ .form-control-label {
    font-size: 75%;
    opacity: 1;
    transform: translate3d(0, -100%, 0);
}

select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    border: 1px solid $color-grey-mercury;
    border-radius: 0.25rem;
    background: $color-white url('/img/arrow down.svg') no-repeat right 0.75rem center;
    background-size: auto auto;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-password {
    span.form-password-span {
        position: absolute;
        top: 7px;
        right: 10px;
        width: 23px;
        height: 23px;
        cursor: pointer;

        &.form-password-visible {
            &::before {
                content: url('/img/Eye Close.svg');
            }
        }

        &::before {
            content: url('/img/Eye.svg');
        }
    }
}

.form-checkboxes,
.form-radio {
    .form-check {
        min-width: 100px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding-left: 0;
        font-size: 14px;
        text-align: center;
    }

    .form-check input[type='checkbox'],
    .form-check input[type='radio'] {
        z-index: 100;
        width: 0;
        height: 0;
        opacity: 0;
    }

    .Checked + label,
    .form-check input[type='checkbox']:checked + label,
    .form-check input[type='radio']:checked + label {
        color: $color-white;
        border-color: $color-primary;
        background-color: $color-primary;

        .cromis-body & {
            background-color: $color-cromis-primary;
            border-color: $color-cromis-primary;
        }
    }

    .form-check label {
        z-index: 90;
        width: 100%;
        padding: 5px;
        border: 1px solid $color-grey-mercury;
        border-radius: 3px;
        background-color: $color-white;
        cursor: pointer;
    }

    .form-check label:hover {
        color: $color-primary;
        border-color: $color-primary;

        .cromis-body & {
            color: $color-cromis-primary;
            border-color: $color-cromis-primary;
        }
    }

    & > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.custom-input-file {
    .form-control-file {
        overflow: hidden;
        position: absolute;
        z-index: -1;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;

        + label {
            display: inline-block;
            overflow: hidden;
            max-width: 80%;
            margin-right: 15px;
            padding: 0 15px 0 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #f1e5e6;
            background-color: $color-grey-mineshaft;
            cursor: pointer;

            span {
                display: inline-block;
                overflow: hidden;
                width: 290px;
                height: 40px;
                margin-right: 15px;
                padding-left: 15px;
                font-size: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: $color-grey-mineshaft;
                border: $color-grey-mineshaft;
                background-color: $color-white;
                vertical-align: top;
            }
        }

        &:focus + label,
        .form-control-file.has-focus + label {
            outline: 1px dotted #000;
            outline: -webkit-focus-ring-color auto 5px;
        }
    }
}
// input[type=date] {
//     padding-left: 55%;
// }

p.group-label {
    font-family: 'Gotham-Bold';
    font-size: 14px;
    font-weight: 500;
}

.classic-checkbox {
    [type='checkbox']:checked,
    [type='checkbox']:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
        position: relative;
        padding-left: 1.95em;
        cursor: pointer;
    }

    [type='checkbox']:checked + label:before,
    [type='checkbox']:not(:checked) + label:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25em;
        height: 1.25em;
        border: 2px solid #ccc;
        border-radius: 2px;
        background: #fff;
    }

    [type='checkbox']:checked + label:after,
    [type='checkbox']:not(:checked) + label:after {
        content: '\f00c';
        position: absolute;
        top: 3px;
        left: 2px;
        font-family: FontAwesome;
        font-size: 16px;
        line-height: 0.8;
        color: $color-primary;
        transition: all 0.2s;

        .cromis-body & {
            color: $color-cromis-primary;
        }
    }

    [type='checkbox']:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    [type='checkbox']:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    [type='checkbox']:disabled:checked + label:before,
    [type='checkbox']:disabled:not(:checked) + label:before {
        border-color: #bbb;
        background-color: #ddd;
        box-shadow: none;
    }

    [type='checkbox']:disabled:checked + label:after {
        color: #999;
    }

    [type='checkbox']:disabled + label {
        color: #aaa;
    }
}
@media screen and (max-width: 762px) {
    .form-radio {
        > div {
            display: block;
            text-align: center;
        }

        .form-check {
            display: inline-block;
            margin-right: 15px;
        }
    }
}
