.megamenu {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: $color-white;
    animation-duration: 0.5s;
    @include responsive-lg {
        width: 100%;
    }

    &--visible {
        display: block;
    }

    &-content {
        display: none;
        height: 100%;
        padding: 120px 0 0;
        animation-delay: 0.5s;
        animation-duration: 0.5s;
        @include responsive-md() {
            padding: 60px 15px 0;
        }

        &--visible {
            opacity: 1;
        }

        &-main {
            overflow-x: auto;
            height: calc(100% - 30px);
            margin: 15px;
            // TODO This part is not present in the MVP
            padding: 0px 20px;
            @include responsive-md() {
                padding: 15px 22.5px;
            }

            &-element {
                margin-bottom: $size-default-lg;
                font-size: 0.9em;

                &--last {
                    margin-bottom: $size-default-lg;
                }

                &-title {
                    font-family: 'Gotham-Bold';
                    font-size: 16px;

                    &::after {
                        content: '';
                        display: block;
                        width: 30px;
                        height: 2px;
                        margin-top: 8px;
                        border: 1px solid $color-primary;

                        .cromis-body & {
                            border: 1px solid $color-cromis-primary;
                        }
                    }
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    ul {
                        li {
                            margin: 10px 0;
                            padding-right: 15px;

                            &.has-submenus {
                                & > div > a {
                                    position: relative;
                                    padding-right: 20px;

                                    &::after {
                                        content: url('../img/ui/sm-menu-carret.svg');
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        right: -15px;
                                        transform: scale(1.75);
                                        top: -5px;
                                    }
                                }
                            }

                            a {
                                color: $color-grey-mineshaft;
                            }

                            ul {
                                display: none;
                                margin-bottom: 12.5px;
                                padding-left: 15px;
                                border: 1px solid transparent;
                                font-size: 13px;
                                line-height: 15px;

                                li {
                                    a {
                                        color: $color-grey-dovegray;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            ul.submenu-last {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-right: -15px;
                margin-left: -15px;

                li {
                    width: 33%;
                    margin: 5px 0 0 0px;
                    padding-right: 15px;
                    padding-left: 15px;
                    font-size: 13px;

                    span {
                        &::after {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        &-aside {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
            padding-bottom: 50px;
            padding-left: 0;
            background-image: url("/img/menu-aside-bkg.png");
            background-position: center top;
            background-size: cover;

            ul {
                list-style: none;
                margin-bottom: 80px;
                padding: 0;

                li {
                    padding: 0;

                    span {
                        display: block;
                        color: $color-white;
                        font-family: 'Gotham-Bold';
                        font-size: 16px;

                        &::after {
                            content: '';
                            display: block;
                            width: 30px;
                            height: 2px;
                            margin-top: 12px;
                            border: 1px solid $color-primary;

                            .cromis-body & {
                                border: 1px solid $color-cromis-primary;
                            }
                        }
                    }

                    ul {
                        li {
                            margin: 15px 0;
                            padding-right: 15px;

                            a {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }
}
// For colapsible responsive menu

.megamenu-content-main-sm {
    &-lvl1 {
        padding: 10px 0;
        @include responsive-md {
            padding: 5px 0;
        }

        button {
            position: relative;
            padding-left: 30px;
            border: 0;
            background-color: transparent;
            font-family: 'Gotham-Bold';
            font-size: 16px;
            text-align: left;
            cursor: pointer;

            &::before {
                content: url("/img/ui/sm-menu-carret.svg");
                position: absolute;
                left: 0;
                display: inline-block;
                margin-right: 10px;
                transform: rotate(-90deg);
                transition-duration: 0.3s;
            }
        }

        button[aria-expanded=true] {
            &::before {
                transform: rotate(0deg);
            }
        }
    }

    &-lvl2 {
        padding: 2px 0 2px 30px;
        @include responsive-md() {
            padding: 5px 0 5px 30px;
        }
        button {
            position: relative;
            padding-left: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;

            &::after {
                content: url("/img/ui/sm-menu-carret.svg");
                position: absolute;
                right: -10px;
                top: 1px;
                display: inline-block;
                transform: rotate(-90deg);
                transition-duration: 0.3s;
            }
        }

        button[aria-expanded=true] {
            &::after {
                transform: rotate(0deg);
            }
        }
    }

    &-lvl3parent {
        margin-top: 5px;
    }

    &-lvl3 {
        padding: 2px 0 2px 30px;
        line-height: 15px;
        @include responsive-md() {
            padding: 5px 0 5px 30px;
        }
        a {
            font-size: 13px;
            // line-height: 15px;
            color: $color-grey-dovegray !important;
        }
    }

    nav {
        margin-top: 20px;
        font-size: 13px;

        ul {
            li {
                margin: 0 20px 10px 0;

                a {
                    color: #888;
                }
            }
        }
    }
}
