.brand {
  display: flex;
  align-items: center;

  &_logo {
    flex-shrink: 0;
    min-width: 3.625rem;
  }

  &_label {
    display: none;
    max-width: 22.5rem;
    margin: 0 2.5rem;

    @include respond-to('medium') {
      display: block;
    }

  }
}