.socials {
  display: flex;
  justify-content: center;

  @include respond-to('medium') {
    justify-content: flex-end;
  }

  &_link {
    margin-top: 2.5rem;

    &:not(:last-child) {
      margin-right: 2.875rem;
    }
  }
}