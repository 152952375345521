.more-link {
    font-family: 'bebas_neuebold';
    font-size: 13px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    overflow: hidden;

    &-link,
    a {
        display: inline-block;
        margin-bottom: 2px;
        padding: 0;
        color: $color-primary !important;
        background-color: transparent;
        letter-spacing: 2.89px;
        border: none;
        outline: 0;

        .cromis-body & {
            color: $color-cromis-primary !important;
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: $color-primary;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        &:hover {
            margin-bottom: 0;
            border-bottom: 2px solid $color-secondary;
        }
    }

    span {
        display: inline-block;
        width: 7px;
        height: 12px;
        margin-left: 20px;
        margin-bottom: 5px;
        background-image: url("/img/ui/more-link.svg");
        vertical-align: middle;
    }
}
