.form-group {
    &--date {
        &-container {
            position: relative;

            &-image {
                position: absolute;
                top: 9px;
                right: 12px;
            }
        }
    }
}

.form-control.datepicker {
    padding: .375rem 40px .375rem .75rem;
}

.datepicker.dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: 0 5px 20px rgba($color-black, .2);
}

.datepicker.datepicker-inline th,
.datepicker.dropdown-menu th {
    text-transform: capitalize;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
    background-color: $color-primary !important;
    background-image: none;

    .cromis-body & {
        background-color: $color-cromis-primary !important;
    }
}

.datepicker.datepicker-inline td,
.datepicker.datepicker-inline th,
.datepicker.dropdown-menu td,
.datepicker.dropdown-menu th {
    padding: 6px 5px 3px;
    border-radius: 0;
}

.datepicker-dropdown.datepicker-orient-bottom:before,
.datepicker-dropdown.datepicker-orient-top:before {
    border: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
    background-color: $color-grey-silver;
    background-image: none;
    color: $color-grey-mineshaft;
    font-weight: 600;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover:active {
    color: $color-white !important;
}

.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled] {
    background: #eee;
    color: $color-grey-mineshaft;
}
