.loginbar {
    cursor: pointer;

    &-close {
        position: absolute;
        z-index: -1;
        top: -5px;
        right: 0;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &-toggle {
        cursor: pointer;
    }

    &-inputs {
        display: flex;
        overflow: hidden;
        position: absolute;
        top: -5px;
        right: -25px;
        width: 0;
        margin-right: 25px;
        padding-left: 10px;
        opacity: 0;
        background-color: $color-white;
        transition: width 0.3s, opacity 0.3s;

        input {
            width: 100%;
            margin-top: 5px;
            border: 0;
            border-bottom: 1px solid #f0f0f0;
            outline: none;
        }
    }

    &--opened {
        .loginbar-inputs {
            width: 545px;
            margin-right: 65px;
            opacity: 1;
            @include responsive-sm() {
                width: 300px;
            }
        }

        .loginbar-close {
            z-index: 0;
            opacity: 1;
        }
    }
}
