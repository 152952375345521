/////////////////////////////////////////////////////////////////
// PICTURES
/////////////////////////////////////////////////////////////////

&-diveeditpictures {
    &-medias {
        @include media-breakpoint-down(sm) {
            display: flex;
        }
        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        & > * {
            @include media-breakpoint-down(sm) {
                width: 50%;
            }
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    .drag-and-drop {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        width: 100%;
        border: 2px dashed $color-white;
        border-radius: 5px;
        background-color: rgba($color-black, .5);
        user-select: none;
        opacity: .7;
        cursor: pointer;
        transition: $opacity-transition;

        @include media-breakpoint-down(sm) {
            height: 195px;
        }

        &.disabled {
            opacity: .5;
            user-select: none;
            pointer-events: none;
            transition: $opacity-transition;
        }

        &:hover {
            opacity: 1;
        }

        .content {
            color: $color-white;
            text-align: center;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }

            img {
                display: inline-block;
                width: 40px;
                margin-bottom: 10px;
            }

            strong {
                display: block;
                font-size: 20px;
                line-height: 28px;
                max-width: 250px;

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
            }
        }
    }

    textarea {
        display: block;
        height: 250px;
        width: 100%;
        margin-top: 32px;
        opacity: 1;
        transition: $opacity-transition;

        @include media-breakpoint-down(sm) {
            height: 195px;
            margin-top: 0;
        }

        &.disabled {
            opacity: .5;
            user-select: none;
            pointer-events: none;
            transition: $opacity-transition;
        }
    }

    .photos,
    .videos {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        user-select: none;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-left: 15px;
        }
        @include media-breakpoint-down(xs) {
            margin-top: 15px;
            margin-left: 0;
        }

        .divelog-media {
            .divelog-action {
                img {
                    transform: rotate(45deg);
                }
            }
        }
    }
}
