//------------------------------------------------------------
// Screen >= 1440px
//------------------------------------------------------------
@mixin responsive-xxl() {
    @media (max-width: $large-container-width - 1px) {
        @content;
    }
}
//------------------------------------------------------------
// Screen >= 1200px
//------------------------------------------------------------
@mixin responsive-xl() {
    @media (max-width: $container-width - 1px) {
        @content;
    }
}

//------------------------------------------------------------
// Screen >= 992px
//------------------------------------------------------------
@mixin responsive-lg() {
    @media (max-width: 991px) {
        @content;
    }
}

//------------------------------------------------------------
// Screen >= 768px
//------------------------------------------------------------
@media screen and (max-width: 767px) {
    $responsive: true;

    .md {
        @import 'abstract/mixins';
    }
}

@media screen and (max-resolution: 192dpi) and (max-width: 767px),
    screen and (max-resolution: 2dppx) and (max-width: 767px) {
    $responsive: true;

    .md {
        @import 'abstract/mixins';
    }
}

@mixin responsive-mdh() {
    @media screen and (max-resolution: 192dpi) and (max-width: 767px),
    screen and (max-resolution: 2dppx) and (max-width: 767px) {
        @content;
    }
}

@mixin responsive-md() {
    @media (max-width: 767px) {
        @content;
    }
}
//------------------------------------------------------------
// Screen >= 576px
//------------------------------------------------------------
@media screen and (max-width: 575px) {
    $responsive: true;

    .sm {
        @import 'abstract/mixins';
    }
}

@mixin responsive-sm() {
    @media (max-width: 575px) {
        @content;
    }
}
//------------------------------------------------------------
// Screen < 576px
//------------------------------------------------------------
@mixin responsive-xs() {
    @media (max-width: $screen-xs-max) {
        @content;
    }
}
