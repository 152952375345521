/////////////////////////////////////////////////////////////////
// BADGES
/////////////////////////////////////////////////////////////////

&-badges {
    text-align: center;

    &--small {
        text-align: inherit;

        .divelog-badges {
            &-item {
                width: inherit;
                margin: 0 $size-default-xxs $size-default-sm;
                padding: 2px 10px;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    &-item {
        position: relative;
        display: inline-block;
        width: 140px;
        margin: 0 5px 10px;
        padding: 9px 15px;
        border: 2px solid $color-white;
        border-radius: 5px;
        background-color: $color-blue-cobalt;
        color: $color-white;
        opacity: .5;
        font-family: $font-gotham-bold;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        transition: $opacity-transition;

        .cromis-body & {
            background-color: $color-green-niagara;
        }

        &--reverse {
            background-color: transparent;
            border: 2px solid $color-primary;
            color: $color-primary;

            .cromis-body & {
                border: 2px solid $color-cromis-primary;
                color: $color-cromis-primary;
            }

            &.active {
                background-color: $color-primary !important;
                color: $color-white !important;

                .cromis-body & {
                    background-color: $color-cromis-primary !important;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            width: 125px;
            font-size: 12px;
            line-height: 20px;
        }

        &:hover {
            opacity: 1;
            transition: $opacity-transition;
        }

        &.active {
            background-color: $color-white;
            color: $color-blue-cobalt;
            opacity: 1;
            transition: $opacity-transition;

            .cromis-body & {
                color: $color-green-niagara;
            }
        }
    }
}

/////////////////////////////////////////////////////////////////
// CLUBS
/////////////////////////////////////////////////////////////////

&-clubs {
    padding: 20px;
    background-color: rgba(0, 0, 0, .1);

    @include media-breakpoint-down(md) {
        padding: 15px;
    }

    &-inner {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-bottom: -20px;
        margin-left: -20px;

        @include media-breakpoint-down(md) {
            margin-right: -15px;
            margin-bottom: -15px;
            margin-left: -15px;
        }
    }

    &-item {
        position: relative;
        display: block;
        display: flex;
        align-items: center;
        max-width: calc((100% / 3) - 40px);
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 20px;

        @include media-breakpoint-down(md) {
            max-width: calc((100% / 2) - 40px);
        }
        @include media-breakpoint-down(sm) {
            max-width: calc((100% / 2) - 30px);
            margin-right: 15px;
            margin-bottom: 15px;
            margin-left: 15px;
        }

        img {
            display: block;
            max-height: 65px;
            max-width: 100%;

            @include media-breakpoint-down(xs) {
                max-height: 50px;
            }
        }
    }

    a.divelog-clubs-item {
        opacity: .75;
        transition: $opacity-transition;

        &:hover {
            opacity: 1;
            transition: $opacity-transition;
        }
    }
}
/////////////////////////////////////////////////////////////////
// FIELD VIEW
/////////////////////////////////////////////////////////////////

&-fieldview {
    &--temperature {
        display: flex;
        align-items: center;
        justify-content: space-between;

        // @include media-breakpoint-down(xs) {
        //     font-size: 12px;
        // }

        .water {
            display: flex;
            align-items: center;

            .title {
                margin-right: 30px;
                text-transform: uppercase;
                font-size: 10px;
                letter-spacing: 1px;
                color: rgba($color-white, 0.8);

                @include media-breakpoint-down(xs) {
                    margin-right: 15px;
                    font-size: 13px;
                }
            }

            .bottom {
                color: $color-blue-curious;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }

            img {
                width: 40px;
                margin: 5px 0 7.5px;

                @include media-breakpoint-down(xs) {
                    max-width: 40px;
                }
            }
        }

        .outside {
            display: flex;
            align-items: center;

            .text {
                margin-right: 30px;
                color: $color-white;
                font-size: 36px;
                font-weight: 600;
                line-height: 36px;

                @include media-breakpoint-down(xs) {
                    margin-right: 15px;
                    font-size: 26px;
                }
            }

            img {
                @include media-breakpoint-down(xs) {
                    width: 30px;
                }
            }
        }
    }

    &-row {
        display: flex;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }

        &--space-between {
            justify-content: space-between;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }

            .divelog-fieldview-field {
                @include media-breakpoint-down(xs) {
                    width: 100% !important;
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
            }
        }

        &--xs-50 {
            .divelog-fieldview-field {
                @include media-breakpoint-down(xs) {
                    width: calc(50% - (15px / 2)) !important;
                }
            }
        }

        .divelog-fieldview-field {
            @include media-breakpoint-down(xs) {
                width: 100%;
                margin-bottom: 15px;
            }

            &:not([class^='col']) + .divelog-fieldview-field:not([class^='col']) {
                padding-left: 50px;

                @include media-breakpoint-down(lg) {
                    padding-left: 30px;
                }
                @include media-breakpoint-down(md) {
                    padding-left: 50px;
                }
                @include media-breakpoint-down(xs) {
                    padding-left: 0;
                }
            }

            &:nth-of-type(odd) {
                @include media-breakpoint-down(xs) {
                    margin-right: 7.5px;
                }
            }

            &:nth-of-type(even) {
                @include media-breakpoint-down(xs) {
                    margin-left: 7.5px;
                }
            }
        }
    }

    &-column {
        .divelog-fieldview-field {
            margin-bottom: 30px;
        }
    }

    &-field {
        max-width: 100%;

        &--empty {
            margin-bottom: 0 !important;
        }

        &--collection {
            margin-bottom: $size-default-sm + $size-default-xs !important;
        }

        &-title {
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 1px;
            color: rgba($color-white, .8);

            @include media-breakpoint-down(xs) {
                margin-bottom: 2.5px;
            }
        }

        &-icon {
            width: 25px;
            max-height: 25px;
            max-width: 25px;
            margin-right: 10px;
        }

        &-value {
            display: block;
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            overflow: hidden;

            @include media-breakpoint-down(lg) {
                font-size: 14px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 13px;
            }

            &--comment {
                margin-top: 5px;
                font-family: $font-gotham !important;
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 22px !important;
            }

            &--link {
                a {
                    color: rgba($color-white, .7) !important;
                    overflow-wrap: break-word;
                    word-break: break-word;
                    word-wrap: break-word;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $color-white !important;
                    }
                }
            }
        }

        &-small {
            padding-left: 10px;
        }

        &-flex {
            display: flex;
            flex-flow: row nowrap;
            align-items: baseline;

            &--center {
                align-items: center;
            }

            .divelog-fieldview-field-value {
                width: inherit;
            }
        }
    }

    &-comment {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
        border: 1px solid rgba($color-white, .5);
        border-radius: 5px;

        .evaluation {
            width: 25px;
            margin-right: 15px;
        }

        .text {
            padding-top: 3px;
        }
    }

    &-block {
        padding: 12px 15px;
        border: 2px solid rgba($color-white, .2);
        border-radius: 5px;

        @include media-breakpoint-down(xs) {
            padding: 8px;
        }

        & + & {
            margin-top: 15px;
        }

        &-row {
            display: flex;
            flex-flow: row wrap;
            border-top: 2px dashed rgba($color-white, .3);
            margin-top: $size-default-xs;
            padding-top: $size-default-sm;
        }

        &-fullcol {
            flex: 0 0 100%;
            margin-top: $size-default-xs;
            margin-bottom: $size-default-sm;
        }

        &-col {
            width: calc(100% / 3);
        }


        .divelog-fieldview-field {
            margin-bottom: 0;
        }
    }
}

/////////////////////////////////////////////////////////////////
// FILTERS
/////////////////////////////////////////////////////////////////
&-filters {
    padding: 20px 30px;
    border: 2px solid rgba($color-white, .5);
    border-radius: 5px;

    select option:disabled {
        display: none;
    }

    &--reverse {
        border-color: rgba($color-grey-silver, .5);

        .divelog-filters-legend {
            border-color: rgba($color-grey-silver, .5);

            &-element-title {
                color: $color-grey-mineshaft;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        padding: 15px;
    }

    &-search {
        max-width: 490px;
        margin: 0 auto;
    }

    &-title {
        font-family: $font-gotham-bold;
        font-size: 16px;
        line-height: 28px;
    }

    &-content {
        margin-top: 15px;

        &--flex {
            display: flex;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
    }

    &-left {
        width: 280px;
        margin-right: 80px;

        @include media-breakpoint-down(lg) {
            margin-right: 40px;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-right: 0;
        }
    }

    &-badges {
        flex: 1 0;

        &-flex {
            display: flex;
            flex-flow: row nowrap;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
        }

        &-icon {
            flex: 0 0 100px;
            margin-left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(106.5px - #{$size-default-sm});
            height: calc(106.5px - #{$size-default-sm});
            padding: 10px;
            background-color: $color-blue-regal;
            border-radius: 5px;

            .cromis-body & {
                background-color: $color-cromis-primary;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $size-default-md;
                margin-left: auto;
                margin-right: auto;
            }

            img {
                flex: 0 0 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-description {
            margin-top: $size-default-sm;
            padding: $size-default-sm;
            background-color: $color-blue-regal;
            font-size: 11px;
            border-radius: 5px;

            .cromis-body & {
                background-color: $color-cromis-primary;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $size-default-md;
            }

            &-title {
                font-family: $font-gotham-bold;
                font-size: 13px;
                line-height: 14px;
            }
        }
    }

    &-placebo {
        font-size: 16px;
        font-family: $font-gotham-bold;
        line-height: 40px;
        border: 2px solid rgba($color-white, .5);
        padding: 3px 13px;
        border-radius: 5px;
    }

    &-legend {
        display: flex;
        flex-flow: row nowrap;
        border-top: dashed 2px rgba($color-white, .5);
        margin-top: $size-default-md;
        padding-top: $size-default-md;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        &-element {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            & + & {
                margin-left: $size-default-lg;

                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                    margin-top: $size-default-xs;
                }
            }

            img {
                height: 25px;

                @include media-breakpoint-down(xs) {
                    height: 20px;
                }
            }

            &-title {
                flex: 1 0 auto;
                text-transform: uppercase;
                font-family: $font-gotham-bold;
                letter-spacing: 1px;
                font-size: 12px;
                color: rgba($color-white, 0.8);
                margin-left: 10px;

                @include media-breakpoint-down(xs) {
                    font-size: 10px;
                }
            }
        }
    }
}

/////////////////////////////////////////////////////////////////
// MAP
/////////////////////////////////////////////////////////////////

&-map {
    position: relative;
    z-index: 0;
    width: 100%;
    background-color: rgba($color-white, .1);

    &--h-500 {
        height: 500px;

        @include media-breakpoint-down(md) {
            height: 400px;
        }
        @include media-breakpoint-down(sm) {
            height: 350px;
        }
        @include media-breakpoint-down(xs) {
            height: 300px;
        }
    }

    &--h-full {
        height: 100%;
        min-height: 300px;
    }

    .leaflet-top.leaflet-left {
        @include media-breakpoint-down(xs) {
            top: 44px;
        }
    }
    .leaflet-top.leaflet-right {
        @include media-breakpoint-down(xs) {
            left: 0;
        }
    }

    &-info {
        padding: $size-default-sm 10px;
        width: 100%;
        font-size: 12px;
        background-color: rgba($color-white, .3);
    }
}
/////////////////////////////////////////////////////////////////
// MEDIA
/////////////////////////////////////////////////////////////////

&-media {
    height: 150px;
    width: calc((100% - 30px) / 2);
    margin-right: 15px;
    margin-bottom: 30px;
    margin-left: 15px;
    background-color: rgba($color-black, .5);

    @include media-breakpoint-down(lg) {
        height: 140px;
    }
    @include media-breakpoint-down(md) {
        height: 90px;
    }
    @include media-breakpoint-down(sm) {
        width: calc((100% - 15px) / 2);
        margin-right: 7.5px;
        margin-bottom: 15px;
        margin-left: 7.5px;
    }

    &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: opacity .5s ease 0s, visibility 0s ease .5s;
    }

    &.loading {
        visibility: visible;
        opacity: 1;
        animation: loading 2s infinite 1.5s;
        transition: opacity 1s ease 0s, visibility 0s ease 0s;
    }

    &.hidden,
    &.loading {
        .divelog-picture {
            &-action,
            img {
                visibility: hidden;
                opacity: 0;
                transition: opacity .5s ease 0s, visibility 0s ease .5s;
            }
        }
    }

    &.visible {
        visibility: visible;
        opacity: 1;
    }

    &:nth-of-type(odd) {
        margin-left: 0;
    }

    &:nth-of-type(even) {
        margin-right: 0;
    }

    .divelog-picture {
        height: 100%;

        &-action,
        img {
            visibility: visible;
            opacity: 1;
            transition: opacity .5s ease 0s, visibility 0s ease 0s;
        }
    }

    .form-group,
    input {
        display: none;
    }
}
/////////////////////////////////////////////////////////////////
// PICTURE
/////////////////////////////////////////////////////////////////

&-picture {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $color-blue-regal;

    & > img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &-action {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: rgba($color-black, .25);
        cursor: pointer;
        transition: background-color .1s ease;

        &--rotate-45 {
            & > img {
                transform: rotate(45deg);
            }
        }

        &:hover {
            background-color: rgba($color-black, .7);
            transition: background-color .1s ease;
        }

        & > img {
            position: inherit;
            height: 40px;
            width: 40px;
            object-fit: inherit;
        }
    }

    iframe {
        height: 100% !important;
        width: 100% !important;
    }
}
/////////////////////////////////////////////////////////////////
// SECTION
/////////////////////////////////////////////////////////////////

&-section {
    margin-top: 50px;

    & + .divelog-section:not(.divelog-section--no-separator),
    &--separator {
        &:before {
            content: '';
            display: block;
            height: 13px;
            margin-right: 100px;
            margin-bottom: 50px;
            margin-left: 100px;
            background-image: url('/img/divelog/separator.svg');
            background-repeat: repeat-x;

            @include media-breakpoint-down(xs) {
                margin-right: 30px;
                margin-left: 30px;
            }
        }
    }

    &-title {
        margin-bottom: 30px;
        color: $color-yellow-laser-lemon;
        font-family: $font-gotham-bold;
        font-size: 30px;
        line-height: 50px;
        text-align: center;

        .cromis-body & {
            color: $color-blue-aquamarine;
        }

        @include media-breakpoint-down(xs) {
            font-size: 25px;
            line-height: 30px;
        }

        small {
            margin-left: 5px;
            vertical-align: 2px;
            font-size: 60%;
        }
    }

    &-subtitle {
        margin-bottom: 15px;
        color: $color-white;
        font-family: $font-gotham-bold;
        font-size: 16px;
        line-height: 28px;
    }

    &-subsubtitle {
        color: $color-white;
        font-family: $font-gotham-bold;
        font-size: 22px;
        line-height: 26px;
    }
}

&-list-delete-btn {
    margin-top: 50px;
    margin-left: auto;
    margin-right: 0;
    display: block;
    transition: .3s !important;

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
        transition-property: all;
        transition-duration: .3s;
    }
}

/////////////////////////////////////////////////////////////////
// SPECIES
/////////////////////////////////////////////////////////////////

&-species {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    @include media-breakpoint-down(sm) {
        margin-right: -7.5px;
        margin-left: -7.5px;
    }

    &--dashboard {
        margin-right: -10px;
        margin-left: -10px;

        @include media-breakpoint-down(lg) {
            margin-right: -7.5px;
            margin-left: -7.5px;
        }
        @include media-breakpoint-down(xs) {
            margin-right: -7.5px;
            margin-left: -7.5px;
        }

        .divelog-species {
            &-item {
                width: calc(20% - (80px / 4));
                margin-right: 10px;
                margin-bottom: 0;
                margin-left: 10px;

                @include media-breakpoint-down(lg) {
                    width: calc(20% - (60px / 4));
                    margin-right: 7.5px;
                    margin-left: 7.5px;
                }
                @include media-breakpoint-down(xs) {
                    width: calc(50% - 15px);
                    margin-right: 7.5px;
                    margin-left: 7.5px;
                }

                &:not(:nth-last-child(-n+3)) {
                    @include media-breakpoint-down(xs) {
                        margin-bottom: 15px;
                    }
                }

                &:last-of-type {
                    @include media-breakpoint-down(xs) {
                        display: none;
                    }
                }

                &-picture {
                    height: 95px;
                }

                &-label {
                    font-size: 14px;
                    line-height: 16px;

                    @include media-breakpoint-down(xs) {
                        margin-top: 5px;
                    }
                }

                &-hover {
                    font-size: 14px;

                    @include media-breakpoint-down(lg) {
                        font-size: 12px;
                        line-height: 20px;
                    }

                    img {
                        width: 85px;

                        @include media-breakpoint-down(lg) {
                            width: 75px;
                        }
                    }
                }
            }
        }
    }

    &--row-5 {
        .divelog-species {
            &-item {
                width: calc(20% - (120px / 4));

                @include media-breakpoint-down(md) {
                    width: calc(25% - (90px / 3));
                }
                @include media-breakpoint-down(sm) {
                    width: calc(25% - (45px / 3));
                }
                @include media-breakpoint-down(xs) {
                    width: calc(50% - 15px);
                }

                &-picture {
                    height: 150px;

                    @include media-breakpoint-down(lg) {
                        height: 115px;
                    }
                    @include media-breakpoint-down(md) {
                        height: 100px;
                    }
                }
            }
        }
    }

    a.divelog-species-item:hover {
        .divelog-species-item-hover {
            opacity: 1;
            transition: $opacity-slow-transition;
        }
    }

    &-item {
        width: calc(25% - (90px / 3));
        margin-right: 15px;
        margin-bottom: 30px;
        margin-left: 15px;

        @include media-breakpoint-down(sm) {
            width: calc(25% - (45px / 3));
            margin-right: 7.5px;
            margin-bottom: 15px;
            margin-left: 7.5px;
        }
        @include media-breakpoint-down(xs) {
            width: calc(50% - 15px);
            margin-right: 7.5px;
            margin-left: 7.5px;
        }

        &-picture {
            height: 180px;

            @include media-breakpoint-down(lg) {
                height: 145px;
            }
            @include media-breakpoint-down(md) {
                height: 100px;
            }
        }

        &-hover {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color-black, .7);
            color: $color-white !important;
            opacity: 0;
            font-family: $font-gotham-bold;
            font-size: 16px;
            line-height: 25px;
            transition: $opacity-slow-transition;

            img {
                width: 98px;
            }
        }

        &-label {
            margin-top: 10px;
            color: $color-white;
            font-family: $font-gotham-bold;
            font-size: 16px;
            line-height: 20px;

            @include media-breakpoint-down(sm) {
                margin-top: 5px;
                font-size: 14px;
                line-height: 18px;
            }
        }

        &-features {
            //align-self: stretch;
            img {
                margin-left: 5px;
                max-width: 20px;
                max-height: 20px;
            }
        }
    }
}
/////////////////////////////////////////////////////////////////
// STAMPS, STAMP
/////////////////////////////////////////////////////////////////

&-stamps {
    display: flex;
    flex-wrap: wrap;
}

&-stamp {
    width: 100%;

    &--right {
        margin-left: auto;
    }

    &--force-height {
        .divelog-stamp-inner {
            height: 155px;
        }
    }

    &--small {
        .divelog-stamp-title {
            font-size: 12px;
            line-height: 18px;
        }
    }

    &--row-2 {
        width: calc(50% - (20px /2));
        margin-bottom: 20px;

        &:nth-of-type(odd) {
            margin-right: 20px;
        }

        .divelog-stamp {
            &-inner {
                height: 100%;
            }

            &-img {
                img {
                    max-height: 75px;
                    max-width: 55% !important;
                }
            }
        }
    }

    &-inner {
        display: flex;
        flex-direction: column;
        padding: 10px 15px 15px;
        border-radius: 5px;
        background-color: $color-white;
    }

    &-title {
        margin-bottom: 5px;
        color: #012753;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
    }

    &-img {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;

        &.export {
            position: absolute;
            top: 10px;
            right: 50px;
            width: 100px;
            height: 100px;
            background-size: cover;
        }

        img {
            display: block;
            max-height: 97px;
            max-width: 200px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &-link {
        font-size: 12px;

        a {
            display: block;
            margin-top: 5px;
            color: #012753 !important;
            text-align: center;

            &:active,
            &:focus,
            &:hover {
                color: #012753 !important;
            }
        }
    }
}
/////////////////////////////////////////////////////////////////
// TABLE
/////////////////////////////////////////////////////////////////

&-table {
    overflow-x: auto;
    max-width: 100%;

    &-inner {
        display: table;
        width: 100%;
        background-color: rgba($color-black, .5);
    }

    &-row {
        display: table-row;
        color: $color-white !important;
        transition: $opacity-transition;

        .row-link, .action-link {
            color: $color-white !important;
        }

        .action-link {
            cursor: pointer;
            padding: 5px 0;

            svg {
                pointer-events: none;
                width: 24px;
                min-width: 24px;
                height: 24px;
            }
        }

        &:first-child {
            & > * {
                font-size: 12px;
                font-weight: 300;
                line-height: 20px;

                @include media-breakpoint-down(sm) {
                    font-size: 11px;
                    line-height: 15px;
                }
            }

            span {
                &:first-of-type {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &:last-of-type {
                    display: none;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }
            }
        }

        &:not(:first-child) {
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
                background-color: rgba($color-blue-cobalt, .5);
                transition: background-color 0.2s ease;

                .cromis-body & {
                    background-color: rgba($color-green-niagara, .5);
                }
            }

            & > * {
                border-top: 1px solid $color-blue-cobalt;

                .cromis-body & {
                    border-top: 1px solid $color-green-niagara;
                }
            }
        }

        & > * {
            display: table-cell;
            height: 40px;
            padding: 12px;
            vertical-align: middle;
            font-size: 16px;
            line-height: 22px;

            @include media-breakpoint-down(md) {
                padding: 8px;
                font-size: 13px;
                line-height: 20px;
            }
            @include media-breakpoint-down(sm) {
                padding: 6px;
                font-size: 11px;
                line-height: 16px;
            }

            img {
                max-height: 20px;

                @include media-breakpoint-down(sm) {
                    max-height: 15px;
                }
            }
        }
    }
}
/////////////////////////////////////////////////////////////////
// TITLE
/////////////////////////////////////////////////////////////////

&-title {
    margin-bottom: 50px;
    color: $color-white;
    font-family: $font-gotham-bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;

    @include media-breakpoint-down(xs) {
        font-size: 25px;
        line-height: 30px;
    }

    & > span {
        margin-left: 10px;
        vertical-align: 3px;
        font-family: $font-gotham;
        font-size: 22px;
        font-weight: 400;
    }

    .select {
        position: relative;
        display: inline-block;
        height: inherit !important;
        width: inherit;
        margin: 0;
        padding: 0 !important;
        vertical-align: inherit;
        border: none !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        font-size: 40px !important;
        line-height: 50px !important;
        user-select: none;

        @include media-breakpoint-down(xs) {
            font-size: 25px !important;
            line-height: 30px !important;
        }

        &.active {
            .select {
                &-label {
                    opacity: .7;
                    transition: color .2s ease, opacity .2s ease;

                    &::after {
                        transform: matrix3d(1, -0, 0, -0, 0, -1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 1);
                        transition: transform .2s ease;
                    }
                }

                &-dropdown {
                    visibility: visible;
                    opacity: 1;
                    transition: opacity .3s ease 0s, visibility 0s ease 0s;
                }
            }
        }

        &-label {
            color: $color-white;
            opacity: .7;
            cursor: pointer;
            transition: color .2s ease, opacity .2s ease;

            &:hover {
                opacity: 1;
                transition: color .2s ease, opacity .2s ease;
            }

            &:after {
                content: '';
                display: inline-block;
                height: 20px;
                width: 30px;
                background-image: url('/img/ui/arrow-down-white.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                opacity: .7;
                transition: transform .2s ease;

                @include media-breakpoint-down(xs) {
                    height: 12px;
                    width: 20px;
                }
            }
        }

        &-dropdown {
            position: absolute;
            bottom: 50px;
            width: 100%;
            min-width: 210px;
            visibility: hidden;
            opacity: 0;
            text-align: left;
            transition: opacity .2s ease 0s, visibility 0s ease .2s;

            @include media-breakpoint-down(xs) {
                bottom: 30px;
            }

            & > a {
                display: block;
                color: $color-white;
                transition: color .2s ease;

                &:active,
                &:focus,
                &:hover {
                    color: $color-yellow-laser-lemon;
                    transition: color .2s ease;

                    .cromis-body & {
                        color: $color-blue-aquamarine;
                    }
                }
            }
        }
    }
}
