&-userdivesvalidation {
    &-subtitle {
        width: 500px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    /////////////////////////////////////////////////////////////////
    // LIST
    /////////////////////////////////////////////////////////////////
    &-list {
        display: flex;
        flex-wrap: wrap;

        &-item {
            display: flex;
            align-items: center;
            width: calc(33.333333% - 30px);
            margin: 0 7.5px 15px 7.5px;
            padding: 15px 20px;
            border: 2px solid $color-white;
            border-radius: 5px;
            cursor: pointer;

            @include media-breakpoint-down(lg) {
                width: calc(50% - 30px);
            }
            @include media-breakpoint-down(md) {
                width: calc(100%);
                margin-right: 0;
                margin-left: 0;
            }

            &.active {
                background-color: $color-white;
                color: $color-blue-cobalt;

                .cromis-body & {
                    color: $color-green-niagara;
                }

                .checkbox-checkmark {
                    border-color: $color-blue-cobalt;
                    background-color: $color-blue-cobalt;

                    .cromis-body & {
                        border-color: $color-green-niagara;
                        background-color: $color-green-niagara;
                    }
                }
            }

            label {
                cursor: pointer;

                .checkbox-checkmark {
                    position: relative;
                    top: inherit;
                    left: inherit;
                    margin-right: 15px;
                }
            }

            &-content {
                margin-top: 5px;
                margin-top: 2px;
                margin-right: 15px;
                padding-left: 5px;
                user-select: none;

                &-name {
                    margin-bottom: 5px;
                    font-family: $font-gotham-bold;
                    font-size: 18px;
                    line-height: 18px;

                    @include media-breakpoint-down(xs) {
                        font-size: 15px;
                    }
                }

                small {
                    margin-left: 5px;
                    vertical-align: 1px;
                }
            }
        }
    }
}
