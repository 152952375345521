/////////////////////////////////////////////////////////////////
// WELCOME
/////////////////////////////////////////////////////////////////
&-welcome {

    iframe {
        margin-bottom: $size-default-lg;
    }

    // &-cgu {
    //     height: 500px;
    //     margin-bottom: $size-default-lg;
    //     padding: $size-default-lg;
    //     overflow-y: scroll;
    //     color: $color-white;
    //     background-color: rgba($color-white, 0.1);
    //
    //     @include media-breakpoint-down(xs) {
    //         padding: $size-default-md;
    //         height: 250px;
    //     }
    //
    //     h1 {
    //         margin-bottom: $size-default-md;
    //         color: $color-white;
    //         font-family: 'bebas_neuebold';
    //         font-size: 36px;
    //         font-weight: 600;
    //         letter-spacing: 3px;
    //         text-transform: uppercase;
    //
    //         @include media-breakpoint-down(xs) {
    //             font-size: 22px;
    //         }
    //     }
    //
    //     h2 {
    //         margin-bottom: $size-default-md;
    //         color: $color-white;
    //         font-weight: 600;
    //         font-size: 20px;
    //
    //         @include media-breakpoint-down(xs) {
    //             font-size: 16px;
    //         }
    //     }
    //
    //     p + h1 {
    //         margin-top: $size-default-xxxl;
    //
    //         @include media-breakpoint-down(xs) {
    //             margin-top: $size-default-xxl;
    //         }
    //     }
    //
    //     p + h2 {
    //         margin-top: $size-default-lg;
    //     }
    // }
}
