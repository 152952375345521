.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-white;

    &-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'bebas_neuebold';
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
        letter-spacing: 0.75px;
        // flex: 1;
        text-transform: uppercase;
        color: $color-grey-mineshaft;

        &:hover {
            text-decoration: none;
        }

        img {
            margin: 15px 33px 15px 0;
        }
    }

    &-logout {
        align-items: center;
    }
}

.nav {
    &-link {
        margin: 1rem 0;
        padding: 0 1rem;
        color: $color-grey-light;

        &:hover {
            text-decoration: none;
        }

        &--blue {
            color: $color-primary;

            .cromis-body & {
                color: $color-cromis-primary;
            }

            &:hover {
                text-decoration: none;
            }
        }

        & + .nav-link {
            border-left: 1px solid $color-grey-mercury;
        }
    }
}
@media screen and (max-width: 762px) {
    .header {
        flex-wrap: wrap;

        &-title {
            justify-content: space-around;
            width: 100%;
            margin: 10px 0 10px 10px;

            img {
                margin: 15px 15px 15px 0;
            }
        }

        &-logout {
            width: 100%;
            margin-right: 0;
            text-align: center;
        }
    }
}
