.ffessm_header-container {
    position: relative;
    margin-right: -$size-default-md;
    margin-left: -$size-default-md;
}

.ffessm_header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 6;
    display: block;
    min-height: 120px;
    width: 100vw;
    padding-right: $size-default-md;
    background-color: $color-white;
    color: $color-grey-light;

    @include responsive-md() {
        height: 60px;
        min-height: inherit;
        width: 100%;
        padding: 0 15px;
    }

    a {
        cursor: pointer;
    }

    .ffessm_header-top {
        a {
            cursor: pointer;

            &:active,
            &:focus,
            &:hover {
                color: $color-red-alizarin !important;
            }
        }
    }

    &--dark {
        background-color: rgba(15, 29, 72, .2);
        color: $color-white;

        .ffessm_header {
            &-top {
                border-bottom: 1px solid rgba(240, 240, 240, .2);

                a {
                    color: $color-white;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $color-red-alizarin !important;
                    }

                    svg {
                        fill: $color-white;
                    }
                }

                ul {
                    & > li {
                        & > a {
                            color: $color-white !important;
                            cursor: pointer;

                            &:active,
                            &:focus,
                            &:hover {
                                color: $color-red-alizarin !important;
                            }
                        }
                    }
                }
            }

            &-bottom {
                &-name a {
                    color: $color-white !important;
                }

                &-nav {
                    & > ul {
                        a {
                            color: $color-white !important;
                            cursor: pointer;

                            &:active,
                            &:focus,
                            &:hover {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }

    &--commission {
        .ffessm_header {
            &-top {
                @include responsive-md {
                    display: none;
                }

                &-caption {
                    display: flex;
                    flex-flow: row nowrap;

                    @include responsive-md {
                        display: none;
                    }
                }

                a {
                    svg {
                        fill: $color-white;
                    }
                }
            }

            &-bottom {
                height: inherit;

                &-name {
                    display: block;
                    overflow: hidden;
                    max-height: 63px;
                    width: 230px;
                    color: $color-blue-zodiac;
                    font-family: 'Gotham-Bold';
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: left;

                    @include responsive-md {
                        width: 190px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                &-logo {
                    img {
                        @include responsive-xxl() {
                            padding-top: 8px;
                        }
                        @include responsive-lg() {
                            padding-top: 10px;
                        }
                        @include responsive-md() {
                            padding-top: 0;
                            padding-left: 5px;
                        }
                    }
                }

                &-nav {
                    overflow: hidden;
                    width: auto;
                    padding-left: 10px;

                    &-dropdown {
                        top: 85px;

                        a {
                            font-size: 16px;
                            cursor: pointer;
                        }
                    }

                    & > ul {
                        font-size: 16px;
                        line-height: 18px;
                        letter-spacing: 2px;

                        li {
                            display: flex;
                            align-items: center;
                            height: 80px !important;
                            border-bottom: 3px solid $color-white;
                            text-align: center !important;
                        }
                    }
                }
            }
        }
    }

    &-bottom,
    &-top {
        padding-right: 40px;
        padding-left: 40px;

        @include responsive-lg {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &-top {
        position: relative;
        display: flex;
        order: 2;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        border-bottom: 1px solid rgba(240, 240, 240, .6);
        font-size: 12px;

        @include responsive-md() {
            display: none;
        }

        &-logo {
            max-height: 20px;
            margin-right: 15px;
        }

        &-caption {
            @include responsive-lg {
                padding-right: $size-default-lg;
                font-size: 11px;
            }
            @include responsive-md {
                width: 130px;
                padding-right: 0;
                font-size: 10px;
            }

            &-desktop {
                display: block;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &-mobile {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }

        &-nav {
            ul {
                display: flex;
                justify-content: flex-end;
                max-width: 650px;
                margin: 0;
                padding: 0;
                list-style: none;
                align-items: center;

                li + li {
                    margin-left: 20px;

                    @include responsive-lg {
                        margin-left: 12px;
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                min-height: 40px;
                line-height: 1rem;

                &:hover,
                &:active,
                &:focus {
                    svg {
                        fill: $color-secondary;
                    }
                }

                svg {
                    margin-right: $size-default-xs;
                }
            }
        }
    }

    &-bottom {
        height: 80px;

        @include responsive-md() {
            height: 60px;
            line-height: 60px;
        }
        @include responsive-md() {
            padding-right: 0;
            padding-left: 0;
        }

        &-inner {
            display: flex;
            flex-wrap: wrap;
            order: 1;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding-right: 50px;
        }

        &-toggle {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 28px;
            margin-right: $size-default-xl;
            font-family: 'bebas_neuebold';

            @include media-breakpoint-down(md) {
                margin-right: 25px;
            }

            div {
                height: 16px;
                color: $color-white;

                @include responsive-md() {
                    display: none;
                }

                &:hover,
                &:focus {
                    color: $color-grey-mineshaft !important;
                }
            }
        }

        &-logo {
            img {
                max-height: 55px;
                width: auto;
                max-width: 400px;

                @include responsive-md() {
                    max-height: 39px;
                    max-width: 100%;
                }
            }
        }

        &-name {
            display: none;
            width: 130px;
            margin-right: 30px;
            margin-left: 15px;
            padding-right: 0;
            color: $color-grey-mineshaft !important;
            font-size: 10px;
            line-height: 15px;

            @include responsive-md {
                display: block;
                opacity: 1;
                line-height: 12px;
                transition: opacity .25s;
            }

            &--hidden {
                opacity: 0;
                transition: opacity .25s;
            }
        }

        &-nav {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            width: 550px;
            opacity: 1;
            font-family: 'bebas_neuebold';
            font-size: 20px;
            transition: opacity .25s .125s;

            @include responsive-md() {
                width: inherit;
            }

            &--hidden {
                opacity: 0;
                transition: opacity .25s;
            }

            &-inner {
                display: none;

                @include responsive-md {
                    display: flex;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                letter-spacing: 3.33px;

                a:href {
                    color: $color-grey-mineshaft;
                    cursor: pointer;

                    &:focus,
                    &:hover {
                        color: $color-grey-mineshaft;
                    }
                }

                li {
                    &:not(.li--no-margin) {
                        margin-right: 30px;
                    }

                    &.has-dropdown {
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 100%;
                        text-align: right;
                        cursor: pointer;

                        &:hover {
                            .ffessm_header-bottom-dropdown {
                                right: -50%;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &-responsive-container {
                display: none;

                @include responsive-md {
                    display: block;
                }
            }

            &-responsive {
                position: absolute;
                bottom: -38px;
                left: 0;
                height: 38px;
                width: 100vw;
                background-color: $color-grey-gallery;

                @include responsive-md {
                    width: 100%;
                }

                .owl-dots {
                    display: none;
                }

                ul.owl-carousel {
                    display: flex;
                    justify-content: space-around;
                    margin: 0;
                    padding: 7px 0;
                    list-style: none;
                    font-family: 'bebas_neuebold';

                    li {
                        cursor: pointer;
                    }
                }

                &-link {
                    margin-right: 15px;
                    margin-left: 15px;

                    a {
                        color: #ffffff !important;
                    }
                }

                &-submenu {
                    position: absolute;
                    left: 0;
                    width: 100vw;
                    padding: 0 15px;
                    background-color: white;

                    @include responsive-md {
                        width: 100%;
                        padding: 15px;
                    }

                    ul {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        font-family: 'bebas_neuebold';

                        li {
                            margin: 10px 0;

                            &:first-of-type {
                                margin-top: 0;
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            a {
                                color: $color-grey-mineshaft;
                            }
                        }
                    }
                }
            }
        }

        &-dropdown {
            position: absolute;
            right: -150%;
            padding-top: 22px;
            opacity: 0;
            transition: right .6s, opacity .8s .4s;

            &-element {
                top: 60%;
                right: -9999%;
                left: -25%;
                height: 200px;
                width: 350px;
                background-color: $color-white;
                color: $color-grey-mineshaft;
            }
        }

        &-searchbar {
            position: absolute;
            right: 0;

            @include responsive-md {
                top: 0;
                bottom: 0;
                height: 60px;
                right: 50px;
            }
        }
        &-login-icon {
            position: absolute;
            right: 0;
            display: none;

            @include responsive-md {
                display: block;
                top: 5px;
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 9999;
}
