.button {
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
    border: none;
    border-radius: 3px;
    background-color: $color-primary;
    color: $color-white;
    font-family: 'Gotham-Book';
    font-size: 14px;
    cursor: pointer;

    .cromis-body & {
        background-color: $color-cromis-primary;
    }
}

.ffessm-button {
    display: inline-block;
    padding: 10px 25px;
    border: 2px solid $color-white;
    background-color: $color-primary;
    color: $color-white !important;
    font-family: 'bebas_neuebold';
    letter-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;

    .cromis-body & {
        background-color: $color-cromis-primary;
    }

    &:hover {
        color: $color-white;
    }

    &--no-borders {
        border: 0;
    }

    &--small {
        padding: 8px 15px;
        font-size: 13px !important;
        letter-spacing: 3px;

        &.ffessm-button--icon {
            img,
            svg {
                @include media-breakpoint-down(md) {
                    vertical-align: -3px;
                }
            }
        }

        &.ffessm-button--singleicon {
            padding: 8px 10px;

            img,
            svg {
                @include media-breakpoint-down(xs) {
                    max-height: 16px;
                    max-width: 16px;
                    margin-left: 0;
                    vertical-align: -3px;
                }
            }
        }
    }

    &--reverse {
        background-color: $color-white;
        color: $color-primary !important;

        .cromis-body & {
            color: $color-cromis-primary !important;
            background-color: $color-white;
        }
    }

    &--borders {
        border: 2px solid $color-primary;

        .cromis-body & {
            border: 2px solid $color-cromis-primary;
        }
    }

    &--disabled {
        border: 2px solid $color-grey-gray;
        background-color: transparent;
        color: $color-grey-gray !important;
        opacity: .5;
    }

    &--transparent {
        background-color: transparent;
    }

    &--icon {
        img,
        svg {
            max-height: 16px;
            max-width: 16px;
            margin-left: 15px;
            vertical-align: -2px;
        }
    }

    .fa {
        letter-spacing: 0;
    }
}
