.fc-month-view {
    background-color: $color-white;
}

.fc-unthemed td.fc-today {
    background: rgba($color-primary, .2);

    .cromis-body & {
        background: rgba($color-cromis-primary, .2);
    }
}

.fc-event {
    padding: 2px 5px 0;
    transition: opacity .2s ease 0s;
    cursor: pointer;

    &:hover {
        opacity: .7;
        transition: opacity .2s ease 0s;
    }
}

.fc-header-toolbar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    @include responsive-sm {
        flex-direction: column-reverse;
    }

    .fc-left {
        float: none;
        flex-grow: 1;

        h2 {
            display: block;
            float: none;
            margin: 0 auto;
            transform: translateX(-106px);

            @include responsive-lg {
                font-size: 20px;
                text-align: right;
                transform: none;
            }
            @include responsive-sm {
                margin-top: $size-default-md;
            }
        }
    }

    .fc-right {
        display: flex;
        flex-direction: row-reverse;

        @include responsive-sm {
            justify-content: space-between;
            width: 100%;
        }

        .fc-button-group {
            margin-right: 1em;
            margin-left: 0;
        }

        .fc-button {
            height: 40px;
            border-color: transparent;
            border-radius: 3px;
            box-shadow: none;
            color: $color-white;
            background-image: none;
            font-family: $font-gotham-bold;
            font-size: 14px;
            text-shadow: none;
            transition: opacity .2s ease 0s;

            @include responsive-lg {
                height: 32px;
            }

            &.fc-state-disabled {
                cursor: not-allowed;
            }

            &:not(.fc-state-disabled):hover {
                opacity: .7;
                transition: opacity .2s ease 0s;
            }
        }

        .fc-today-button {
            padding: 0 15px;
            // background-color: $color-blue-curious;
            background-color: $color-primary;

            .cromis-body & {
                background-color: $color-cromis-primary;
            }

            @include responsive-lg {
                padding: 0 10px;
            }
        }

        .fc-next-button,
        .fc-prev-button {
            background-color: $color-primary;

            .cromis-body & {
                background-color: $color-cromis-primary;
            }

            .fc-icon {
                @include responsive-lg {
                    top: 0;
                }
            }
        }

        .fc-prev-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .fc-next-button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
