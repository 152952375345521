.image-fitter {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: rgba($color-black, 0.05);

    &:after {
        content: "";
        display: block;
        overflow: hidden;
        padding-bottom: 100%;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--90::after {
        padding-bottom: 90%;
    }

    &--80::after {
        padding-bottom: 80%;
    }

    &--70::after {
        padding-bottom: 70%;
    }

    &--65::after {
        padding-bottom: 65%;
    }

    &--60::after {
        padding-bottom: 60%;
    }

    &--md-30::after {
        @include responsive-md {
            padding-bottom: 30%;
        }
    }

    &--sm-60::after {
        @include responsive-sm {
            padding-bottom: 60%;
        }
    }
}
