.leaflet-control-geosearch {
    /* global styling */
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    /* leaflet button styling */
    .leaflet-bar-part {
        border-bottom: none;
        border-radius: 4px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
        }

        /* magnifying glass */
        &:before {
            top: 19px;
            left: 16px;
            width: 8px;
            border-top: 2px solid #555;
            transform: rotateZ(45deg);
        }

        &:after {
            top: 6px;
            left: 6px;
            height: 14px;
            width: 14px;
            border: 2px solid #555;
            border-radius: 50%;
        }
    }

    /* resets for pending and error icons */
    &.error a.leaflet-bar-part:before,
    &.pending a.leaflet-bar-part:before {
        display: none;
    }

    &.pending a.leaflet-bar-part:after,
    &.error a.leaflet-bar-part:after {
        top: 50%;
        left: 50%;
        height: 18px;
        width: 18px;
        margin: -9px 0 0 -9px;
        border-radius: 50%;
    }

    /* pending icon */
    &.pending a.leaflet-bar-part:after {
        content: '';
        border: 2px solid #555;
        border-top: 2px solid #f3f3f3;
        animation: spin 1s linear infinite;
    }

    /* error icon */
    &.error a.leaflet-bar-part:after {
        content: '!';
        border: none;
        font-size: 18px;
        font-weight: 600;
        line-height: initial;
    }

    /* search form styling */
    form {
        position: absolute;
        top: -2px;
        left: 28px;
        z-index: -1;
        display: none;
        height: auto;
        margin: 0;
        border: 2px solid rgba($color-black, .2);
        border-left: none;
        border-radius: 0 4px 4px 0;
        background-clip: padding-box;
        background-color: $color-white;

        input {
            height: 30px;
            width: 100%;
            min-width: 200px;
            margin: 0;
            padding: 0;
            border: none;
            border-radius: 0 4px 4px 0;
            font-size: 12px;
            text-indent: 8px;
            outline: none;
        }
    }

    &.active form {
        display: block;
    }

    .results {
        background: $color-white;

        & > * {
            overflow: hidden;
            padding: 0 8px;
            color: $color-black;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.active,
            &:hover {
                background-color: $color-blue-cobalt;
                color: $color-white;
                cursor: pointer;

                .cromis-body & {
                    background-color: $color-green-niagara;
                }
            }
        }

        &.active {
            border-top: 1px solid #c6c6c6;

            /* add missing border to form */
            &:after {
                content: '';
                position: absolute;
                top: 30px;
                bottom: -2px;
                left: -2px;
                display: block;
                width: 0;
                border-left: 2px solid rgba(0, 0, 0, .2);
            }
        }
    }

    &.bar {
        position: relative;
        z-index: 1000;
        display: block;
        height: auto;
        width: 400px;
        margin: 10px 10px 0 auto;
        cursor: auto;

        @include media-breakpoint-down(xs) {
            width: calc(100% - 20px);
            margin: 10px 10px 0 10px;
        }

        form {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            border: 2px solid rgba($color-black, .2);
            border-radius: 4px;

            input {
                height: 30px;
                width: 100%;
                min-width: 100%;
                padding: 0 $size-default-xs;
                border: 0;
                color: $color-black;
                font-family: $font-gotham;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .results.active:after {
            opacity: .2;
        }
    }

    a.reset {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 8px;
        border: none;
        background-color: $color-white;
        color: $color-black;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        cursor: pointer;

        &:hover {
            background-color: $color-blue-cobalt;
            color: $color-white;

            .cromis-body & {
                background-color: $color-green-niagara;
            }
        }
    }
}

/* animations */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
