.thematic {
    margin-right: 35px;
    font-family: 'bebas_neuebold';
    font-size: 17px;
    letter-spacing: 3px;
    @include responsive-sm() {
        margin-right: 0;
        font-size: 15px;
        letter-spacing: 2.50px;
    }

    &--posts {
        @include responsive-sm() {
            margin-right: $size-default-md;
        }
    }
}

.post-date {
    font-size: 12px;
    color: $color-primary;

    .cromis-body & {
        color: $color-cromis-primary;
    }
}
