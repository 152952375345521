&-dashboard {
    /////////////////////////////////////////////////////////////////
    // HEADER
    /////////////////////////////////////////////////////////////////
    &-header {
        margin-top: 0;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }
        @include media-breakpoint-down(xs) {
            margin-bottom: 50px;
        }

        &-left {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
        }

        &-picture {
            height: 100px;
            width: 100px;
            margin-right: 30px;
            background-color: transparent;

            @include media-breakpoint-down(sm) {
                height: 85px;
                width: 85px;
            }
            @include media-breakpoint-down(xs) {
                height: 70px;
                width: 70px;
                margin-right: 0;
                margin-bottom: 20px;
            }

            & > img {
                border-radius: 50%;
            }
        }

        .ffessm-button {
            @include media-breakpoint-down(lg) {
                padding: 10px 20px;
            }
            @include media-breakpoint-down(md) {
                padding: 8px 15px;
                font-size: 13px !important;
                letter-spacing: 3px;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // DIVES
    /////////////////////////////////////////////////////////////////
    &-dives {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
            }
        }

        .divelog-section-subtitle {
            margin-bottom: 0 !important;
        }

        .divelog-table {
            margin-top: 30px;

            &-row {
                & > * {
                    padding: 6px 12px;

                    @include media-breakpoint-down(xs) {
                        padding: 4px 8px;
                    }
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // STATS
    /////////////////////////////////////////////////////////////////
    &-stats {
        &-top {
            margin-bottom: $size-default-lg;

            @include media-breakpoint-down(xs) {
                margin-bottom: 15px;
            }

            .divelog-dashboard-stats-item {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }

        &-bottom {
            display: flex;
            flex-flow: row wrap;
            margin: -$size-default-md;

            @include media-breakpoint-down(xs) {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: -$size-default-sm;
            }

            .divelog-dashboard-stats-item {
                flex: 0 0 calc(50% - #{$size-default-lg} - #{$edge-flex-calc-adjuster});
                width: calc(50% - #{$size-default-lg} - #{$edge-flex-calc-adjuster});
                min-height: 155px;
                margin: $size-default-md;

                @include media-breakpoint-down(lg) {
                    min-height: 145px;
                }
                @include media-breakpoint-down(xs) {
                    min-height: inherit;
                    margin: $size-default-sm;
                    flex: 0 0 100%;
                }
            }
        }

        &-item {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            padding: 15px 20px;
            background-color: rgba($color-black, .1);

            @include media-breakpoint-down(lg) {
                padding: 15px;
            }
            @include media-breakpoint-down(xs) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &--depth {
                .divelog-dashboard-stats-item-value {
                    font-size: 75px;
                    line-height: 65px;
                }
            }

            &--duration {
                .divelog-dashboard-stats-item-value {
                    font-size: 55px;
                    line-height: 60px;

                    @include media-breakpoint-down(lg) {
                        font-size: 45px;
                    }
                }
            }

            &-title {
                color: $color-white;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;

                @include media-breakpoint-down(lg) {
                    font-size: 14px;
                    line-height: 20px;
                }

                small {
                    display: block;
                    line-height: 15px;
                }
            }

            &-content {
                margin-top: 15px;
            }

            &-value {
                color: $color-blue-cyan;
                font-size: 90px;
                font-weight: 300;
                line-height: 75px;
                text-align: center;

                @include media-breakpoint-down(lg) {
                    font-size: 70px;
                }
                @include media-breakpoint-down(xs) {
                    font-size: 59px;
                    line-height: 50px;
                }

                small {
                    font-size: 60%;
                }
            }

            &-ratio {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                color: $color-white;
                font-family: $font-gotham-bold;
                font-size: 20px;
                font-weight: bold;
                line-height: 50px;

                &-element {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    background-color: rgba($color-white, .3);

                    @include media-breakpoint-down(lg) {
                        min-width: 17%;
                    }

                    &--black {
                        background-color: rgba($color-black, .3);
                    }
                }

                &-separator {
                    height: 65px;
                    width: 5px;
                    background-color: rgba($color-white, .8);
                }
            }
        }

        &-chart {
            display: flex;
            flex-grow: 1;

            &-content {
                display: flex;
                align-items: stretch;
                min-height: 250px;
                width: 100%;

                @include media-breakpoint-down(xs) {
                    min-height: 225px;
                }

                .column {
                    position: relative;
                    display: flex;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-direction: column;
                    justify-content: flex-end;

                    &:nth-child(1) > .bar {
                        background-color: #1a9ee8;

                        .cromis-body & {
                            background-color: #27e498;
                        }
                    }

                    &:nth-child(2) > .bar {
                        background-color: #178dd1;

                        .cromis-body & {
                            background-color: #22d48d;
                        }
                    }

                    &:nth-child(3) > .bar {
                        background-color: #157cba;

                        .cromis-body & {
                            background-color: #1CBA7B;
                        }
                    }

                    &:nth-child(4) > .bar {
                        background-color: #126ba2;

                        .cromis-body & {
                            background-color: #18A36C;
                        }
                    }

                    &:nth-child(5) > .bar {
                        background-color: #105a8b;

                        .cromis-body & {
                            background-color: #168F5F;
                        }
                    }

                    &:nth-child(6) > .bar {
                        background-color: #0d4974;

                        .cromis-body & {
                            background-color: #0F7355;
                        }
                    }

                    &:nth-child(7) > .bar {
                        background-color: #0a385d;

                        .cromis-body & {
                            background-color: #0C5C44;
                        }
                    }

                    &:nth-child(8) > .bar {
                        background-color: #082745;

                        .cromis-body & {
                            background-color: #094533;
                        }
                    }

                    &:nth-child(9) > .bar {
                        background-color: #05162e;

                        .cromis-body & {
                            background-color: #062E22;
                        }
                    }

                    .value {
                        padding: 12px 6px;

                        @include media-breakpoint-down(xs) {
                            padding: 8px 1px;
                        }

                        &-number {
                            font-family: $font-gotham-bold;
                            font-size: 26px;
                            font-weight: bold;
                            line-height: 30px;
                            text-align: center;

                            @include media-breakpoint-down(xs) {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    .boundary {
                        flex: 0 0 30px;
                        height: 30px;
                        font-size: 11px;

                        span {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transform: translateX(-50%);

                            @include media-breakpoint-down(xs) {
                                font-size: 8px;
                            }

                            & + span {
                                right: 0;
                                left: auto;
                                transform: translateX(50%);
                            }
                        }
                    }
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // LOREM
    /////////////////////////////////////////////////////////////////
    &-lorem {
        padding: $size-default-md;
        background-color: rgba($color-black, .1);
        font-size: 12px;

        @include media-breakpoint-down(md) {
            display: none;
            margin-top: 30px;
        }

        .title {
            margin-bottom: $size-default-md;
            color: $color-white;
            font-family: $font-gotham-bold;
            font-size: 16px;
            line-height: 20px;
        }

        p {
            margin-bottom: 0;

            & + p {
                margin-top: $size-default-md;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // PARTNERS
    /////////////////////////////////////////////////////////////////
    &-partners {
        margin-top: $size-default-lg;

        @include media-breakpoint-down(xs) {
            margin-top: $size-default-md;
        }

        &-title {
            margin-bottom: $size-default-md;
            color: $color-white;
            font-family: $font-gotham-bold;
            font-size: 16px;
            line-height: 28px;
        }

        &-images {
            margin-top: $size-default-lg;
            width: 85%;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
            @include media-breakpoint-down(xs) {
                margin-top: $size-default-md;
            }

            img {
                max-width: 100%;
                opacity: .9;

                @include media-breakpoint-down(md) {
                    max-width: 300px;
                }
                @include media-breakpoint-down(xs) {
                    max-width: 260px;
                }
            }
        }
    }

    // /////////////////////////////////////////////////////////////////
    // // ADS
    // /////////////////////////////////////////////////////////////////
    // &-ads {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-end;
    //     justify-content: space-between;
    //
    //     @include media-breakpoint-down(md) {
    //         align-items: center;
    //         margin-top: 30px;
    //     }
    //
    //     img {
    //         // width: 100%;
    //         max-width: 100%;
    //     }
    // }

    /////////////////////////////////////////////////////////////////
    // SITES
    /////////////////////////////////////////////////////////////////
    &-sites {
        ul {
            margin-bottom: 0;
            padding-right: 25px;
            padding-left: 0;
            list-style-type: none;

            @include media-breakpoint-down(md) {
                padding-right: 0;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
            }

            li {
                a {
                    display: block;
                    padding: 10px 0;
                    border-top: 1px solid rgba($color-white, .1);
                    color: $color-white;
                    opacity: .7;
                    font-size: 18px;
                    line-height: 28px;
                    transition: $opacity-transition;

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                    }
                    @include media-breakpoint-down(sm) {
                        padding: 6px 0;
                    }

                    &:hover {
                        color: $color-white;
                        opacity: 1;
                        transition: $opacity-transition;
                    }

                    strong {
                        margin-right: 10px;

                        @include media-breakpoint-down(sm) {
                            line-height: 16px;
                        }
                    }

                    small {
                        display: block;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
