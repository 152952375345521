/////////////////////////////////////////////////////////////////
// FORM ELEMENTS
/////////////////////////////////////////////////////////////////

label {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    user-select: none;

    &.label--checkbox-aligner {
        margin-bottom: 15px;
    }
}

input:not([type=submit]),
select,
textarea,
.fake-input {
    padding: 3px 13px;
    border: 2px solid $color-white;
    border-radius: 5px;
    background-color: $color-white;
    color: #333;
    font-family: $font-gotham;
    font-size: 16px;
    line-height: 28px;
    outline: none;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        padding: 8px 13px;
        font-size: 14px;
        line-height: 20px;
    }
}

select {
    padding-right: 30px;
}

.has-error {
    input:not([type=submit]),
    select,
    textarea {
        border: 2px solid $color-red-alizarin;
        color: $color-red-alizarin;
    }
}

input[type=number],
input[type=text],
select,
.fake-input {
    height: 40px;
    max-width: 100%;
}

input[type=submit],
[type=submit] {
    cursor: pointer;

    &.disabled {
        cursor: progress;
        opacity: .5;
        transition: opacity .2s ease;
    }
}

select {
    cursor: pointer;
}

.checkbox {
    display: flex;
    margin-bottom: 0;

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked ~ .checkbox-checkmark {
            .checkbox-checkmark-inner {
                display: block;
            }
        }
    }

    label {
        margin-bottom: 0;
        padding-left: calc(20px + 15px);
        // font-family: $font-gotham-bold;
        // font-size: 16px;
        line-height: 28px;
        cursor: pointer;
        user-select: none;
    }

    &-checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        flex-basis: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 20px;
        width: 20px;
        border: 2px solid $color-grey-gallery;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;

        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            height: 16px;
            width: 16px;
            background-image: url('/img/ui/check.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px;
        }
    }

    & + .help-block {
        ul {
            padding-left: 37px;
        }
    }
}

.form-link {
    color: $color-blue-picton;
    font-family: $font-gotham-bold;
    font-size: 14px;
    line-height: 17px;

    .cromis-body & {
        color: $color-blue-aquamarine;
    }

    &:active,
    &:focus,
    &:hover {
        color: $color-white;
    }

    &[disabled] {
        color: rgba($color-blue-picton, .3);
        pointer-events: none;
        cursor: default;

        .cromis-body & {
            color: rgba($color-blue-aquamarine, .3);
        }
    }
}

.form-with-unit {
    display: flex;
    height: 45px;
    margin-top: 10px;
    font-family: $font-gotham;
    font-size: 40px;
    line-height: 48px;
    opacity: 1;
    transition: opacity 0.3s ease;

    @include media-breakpoint-down(sm) {
        font-size: 25px;
        height: 30px;
        line-height: 32px;
    }

    &--small {
        font-size: 25px;
        height: 30px !important;
        line-height: 32px !important;

        input {
            height: 30px !important;
            font-size: 30px !important;
        }
    }

    &.hidden {
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    &-minutes {
        margin-left: 7px;
    }

    input {
        height: 45px;
        width: 100px;
        padding: 0;
        border: none;
        border-bottom: 2px dashed;
        border-color: rgba($color-white, .3);
        border-radius: 0;
        background-color: transparent;
        color: rgba($color-white, .7);
        font-family: $font-gotham;
        font-size: 50px;
        letter-spacing: -2px;
        text-align: right;
        transition: border-color .3s ease, color .3s ease;

        @include media-breakpoint-down(sm) {
            height: 30px;
            font-size: 30px;
        }

        &::placeholder {
            color: rgba($color-white, .3);
        }

        &:hover {
            border-color: rgba($color-white, .7);
            transition: border-color .3s ease, color .3s ease;
        }

        &:active,
        &:focus {
            border-color: $color-white;
            color: $color-white;
            transition: border-color .3s ease, color .3s ease;
        }

        &.error {
            border-color: $color-red-alizarin;
            color: $color-red-alizarin;
            transition: border-color .3s ease, color .3s ease;
        }
    }

    input[type=number] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            display: none;
        }
    }

    div {
        margin-left: 7px;
    }
}
/////////////////////////////////////////////////////////////////
// FORM ERRORS
/////////////////////////////////////////////////////////////////
.form-errors {
    color: $color-white;
    list-style-type: square;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 30px;
    border: 1px solid rgba($color-white, .5);
    border-radius: 5px;
    margin-bottom: 30px;

    a {
        color: $color-red-alizarin;
        font-style: italic;
    }
}
/////////////////////////////////////////////////////////////////
// COLLECTION
/////////////////////////////////////////////////////////////////
.collection {
    padding-top: 5px;

    & > .form-group {
        margin-bottom: $size-default-md;
        padding: 12px 15px;
        border: 2px solid rgba($color-white, .2);
        border-radius: 5px;
        background-color: rgba($color-white, .1);

        .form-group {
            margin-bottom: 10px;
        }
    }

    .divelog-collection {
        &-item {
            &-body {
                display: flex;
                align-items: flex-end;
            }

            &-content {
                display: flex;
                flex-flow: row wrap;
                width: 100%;

                .form-group {
                    .control-label {
                        font-size: 12px !important;
                    }
                }
            }
            &-actions {
                &-remove {
                    display: inline-block;
                    margin-top: 5px;
                    color: $color-red-alizarin-crimson !important;
                    font-family: $font-gotham-bold;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    text-align: left;

                    &:hover {
                        color: $color-white !important;
                    }
                }
            }
        }
    }
}

/////////////////////////////////////////////////////////////////
// SELECT 2
/////////////////////////////////////////////////////////////////

*.select2-container {
    width: 100% !important;

    &.select2-container--default {
        &.select2-container--focus {
            .select2-selection--multiple {
                border-color: $color-blue-malibu;
                box-shadow: none;
                color: $color-blue-cobalt;
                outline: 0;
                transition: border-color .2s ease 0s;

                .cromis-body & {
                    color: $color-green-niagara;
                }
            }
        }

        &.select2-container--disabled {
            .select2-selection--single {
                background-color: $color-white;
                opacity: .3;
                cursor: inherit;
            }
        }
    }
}

&-select2-container {
    &.select2-selection {
        &--single {
            height: 40px;
            border: 2px solid $color-white;
            outline: none;

            .select2-selection__rendered {
                padding: 3px 13px;
                color: $color-grey-mineshaft;
                font-family: $font-gotham-bold;
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
            }

            .select2-selection__arrow {
                position: absolute;
                top: 1px;
                right: 2px;
                height: 38px;
                width: 25px;
                background: $color-white url('/img/arrow down.svg') no-repeat right .75rem center;
                background-size: auto auto;

                b {
                    display: none;
                }
            }
        }

        &--multiple {
            padding: 3px 5px;
            border-radius: 5px;
            transition: border-color .2s ease 0s;

            .select2-selection__choice {
                margin: 2.5px;
                border-radius: 5px;
                background-color: $color-blue-cobalt;

                .cromis-body & {
                    background-color: $color-green-niagara;
                }
            }
        }
    }

    .select2-search--inline {
        .select2-search__field {
            margin-top: 0;
            margin-left: 7px;
            color: $color-blue-cobalt;

            .cromis-body & {
                color: $color-green-niagara;
            }
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            height: 40px;
            padding: 3px 10px;
            border: 2px solid $color-blue-cobalt;
            border-radius: 5px;
            color: $color-blue-cobalt;
            font-family: $font-gotham-bold;
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            outline: none;

            .cromis-body & {
                border: 2px solid $color-green-niagara;
                color: $color-green-niagara;
            }
        }
    }

    &.select2-dropdown {
        border: 0;
    }

    .select2-results__option {
        color: $color-blue-cobalt;
        font-family: $font-gotham-bold;
        font-size: 14px;

        .cromis-body & {
            color: $color-cromis-primary;
        }

        &--highlighted[aria-selected] {
            background-color: $color-blue-cobalt !important;
            color: $color-white;

            .cromis-body & {
                background-color: $color-green-niagara !important;
                color: $color-white;
            }
        }

        &[aria-selected=true] {
            background-color: $color-blue-malibu;

            .cromis-body & {
                background-color: $color-green-niagara;
            }
        }
    }
}
/////////////////////////////////////////////////////////////////
// FORM GROUP
/////////////////////////////////////////////////////////////////

.form-group {
    width: 100%;
}

.form-control {
    &:disabled,
    &[readonly],
    &.disabled {
        opacity: .3;
        cursor: inherit;
    }

    &:active,
    &:focus {
        border-color: $color-blue-malibu;
        box-shadow: none;
        color: $color-blue-cobalt;
        outline: 0;

        .cromis-body & {
            color: $color-green-niagara;
        }
    }
}
/////////////////////////////////////////////////////////////////
// SPECIFIC FORM GROUPS
/////////////////////////////////////////////////////////////////

.form-group-checkbox-vertical {
    .form-group {
        margin-bottom: 1rem;
    }

    .checkbox {
        flex-direction: column-reverse;
        align-items: center;

        input {
            top: inherit;
            bottom: 0;
            left: 50%;
        }

        label {
            margin-top: 15px;
            padding-left: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;
        }

        &-checkmark {
            position: relative;
            top: inherit;
            left: inherit;
        }
    }
}

.form-group-customradio,
.form-group-radio {
    .control-label {
        margin-top: 4px !important;
        margin-right: 20px !important;
        text-align: left !important;
    }

    div[id*='diving_site_'],
    div[id*='informations_'],
    div[id*='stat_filter_'] {
        display: flex;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }

        label {
            position: relative;
            z-index: 10;
            display: block;
            height: 25px;
            margin-right: 0;
            margin-bottom: 0;
            padding-left: calc(20px + 15px);
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;

            @include media-breakpoint-down(lg) {
                font-size: 13px;
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
            opacity: 0;
            cursor: pointer;
        }

        .radio {
            & + .radio {
                margin-left: 20px;

                @include media-breakpoint-down(xs) {
                    margin-bottom: 10px;
                    margin-left: 0;
                }
            }

            &:not(:last-of-type) {
                @include media-breakpoint-down(xs) {
                    margin-right: 15px;
                }
            }
        }
    }
}

.form-group-customradio {
    div[id*='diving_site_'],
    div[id*='informations_'] {
        label {
            height: 37px;
            padding: 3px 10px 3px 45px;
            border: 2px solid transparent;
            border-radius: 5px;
            background-color: transparent;
            font-weight: 600;
            transition: border-color .2s ease 0s, background-color .2s ease 0s;

            &:hover {
                background-color: #049;
                transition: border-color .2s ease 0s, background-color .2s ease 0s;

                .cromis-body & {
                    background-color: $color-cromis-primary;
                }
            }

            &.active {
                border-color: rgba($color-white, .7);
                background-color: #049;
                transition: border-color .2s ease 0s, background-color .2s ease 0s;

                .cromis-body & {
                    background-color: $color-cromis-primary;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 3px;
                left: 10px;
                height: 25px;
                width: 25px;
                background-position-x: center;
                background-position-y: center;
                background-repeat: no-repeat;
            }

            &[for*='informations_dayPeriod_0']:before {
                background-image: url('/img/divelog/dayperiod-day.svg');
            }

            &[for*='informations_dayPeriod_1']:before {
                background-image: url('/img/divelog/dayperiod-night.svg');
            }

            &[for*='informations_seaAgitation_0']:before {
                background-image: url('/img/divelog/seaagitation-calm.svg');
            }

            &[for*='informations_seaAgitation_1']:before {
                background-image: url('/img/divelog/seaagitation-rough.svg');
            }

            &[for*='informations_weather_'] {
                height: 60px;
                width: 60px;
                padding: 0;
                border-radius: 50%;

                &:before {
                    top: 8px;
                    left: 8px;
                    height: 40px;
                    width: 40px;
                }
            }

            &[for*='informations_weather_0']:before {
                background-image: url('/img/divelog/weather-sunny.svg');
            }

            &[for*='informations_weather_1']:before {
                background-image: url('/img/divelog/weather-cloudy.svg');
            }

            &[for*='informations_weather_2']:before {
                background-image: url('/img/divelog/weather-rainy.svg');
            }

            &[for*='informations_weather_3']:before {
                background-image: url('/img/divelog/weather-snowy.svg');
            }

            &[for*='informations_visibility_0']:before {
                background-image: url('/img/divelog/visibility-good.svg');
            }

            &[for*='informations_visibility_1']:before {
                background-image: url('/img/divelog/visibility-average.svg');
            }

            &[for*='informations_visibility_2']:before {
                background-image: url('/img/divelog/visibility-bad.svg');
            }

            &[for*='informations_seaStream_0']:before {
                background-image: url('/img/divelog/seastream-none.svg');
            }

            &[for*='informations_seaStream_1']:before {
                background-image: url('/img/divelog/seastream-weak.svg');
            }

            &[for*='informations_seaStream_2']:before {
                background-image: url('/img/divelog/seastream-strong.svg');
            }

            &[for*='informations_evaluation_'] {
                height: 42px;
                width: 42px;
                padding-left: 0;
                border-radius: 50%;

                &:before {
                    top: 6px;
                    left: 6px;
                    height: 26px;
                    width: 26px;
                }
            }

            &[for*='informations_evaluation_0']:before {
                background-image: url('/img/divelog/evaluation-good.svg');
            }

            &[for*='informations_evaluation_1']:before {
                background-image: url('/img/divelog/evaluation-average.svg');
            }

            &[for*='informations_evaluation_2']:before {
                background-image: url('/img/divelog/evaluation-bad.svg');
            }

            .cromis-body & {
                &[for*='informations_seaAgitation_0']:before {
                    background-image: url('/img/divelog/svg-white/seaagitation-calm.svg');
                }
    
                &[for*='informations_seaAgitation_1']:before {
                    background-image: url('/img/divelog/svg-white/seaagitation-rough.svg');
                }

                &[for*='informations_seaStream_0']:before {
                    background-image: url('/img/divelog/svg-white/seastream-none.svg');
                }
    
                &[for*='informations_seaStream_1']:before {
                    background-image: url('/img/divelog/svg-white/seastream-weak.svg');
                }
    
                &[for*='informations_seaStream_2']:before {
                    background-image: url('/img/divelog/svg-white/seastream-strong.svg');
                }
            }

            .checkbox-checkmark {
                display: none;
            }
        }
    }
}

.form-group-radio {
    div[id*='diving_site_'],
    div[id*='informations_'] {
        display: flex;
    }

    &--column {
        div[id*='diving_site_'],
        div[id*='informations_'] {
            flex-direction: column;
            align-items: flex-start;

            .radio {
                & + .radio {
                    margin-left: 0;
                }

                label {
                    height: 30px;
                }
            }
        }
    }

    &--wrap {
        div[id*='diving_site_'],
        div[id*='informations_'] {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;

            .radio {
                margin: 0 30px 5px 0 !important;

                label {
                    height: 30px;
                }
            }
        }
    }
}

.form-group-dateinterval {
    label {
        max-width: inherit;
    }

    div[id*='diving_site_'],
    div[id*='informations_'] {
        display: flex;

        input,
        select {
            max-width: 90px;

            & + input,
            & + select {
                margin-left: 15px;
            }
        }
    }

    div[id*='scuba_diving_informations_'] {
        input,
        select {
            width: 75px;
        }
    }
}

.form-group-helper {
    margin-top: 5px;
    color: rgba($color-white, .8);
    font-size: 12px;
}

.form-group-horizontaler {
    .form-group {
        display: flex;
        align-items: center;

        label {
            margin-right: 15px;
            margin-bottom: 0;
            text-align: right;
        }
    }
}

.form-group-inliner {
    display: inline-block;

    .form-group {
        width: inherit !important;
    }
}

.form-group-nomargin {
    .form-group {
        margin-bottom: 0;
    }
}

.form-group-shorter {
    input,
    select {
        max-width: 100px !important;
    }
}

.form-group-textarea {
    textarea {
        min-height: 150px;
        width: 600px;
        margin-right: auto;
        margin-left: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.form-group-coordinate-direction {
    .form-group {
        padding-right: 20px;

        @include media-breakpoint-down(sm) {
            padding-right: 15px;
        }
    }
}

/////////////////////////////////////////////////////////////////
// DIVING HABITATS
/////////////////////////////////////////////////////////////////
.form-group-multiple-checkboxes {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: flex-start !important;
    margin-left: -5px;

    @include media-breakpoint-down(xs) {
        justify-content: center;
    }

    label {
        display: flex !important;
        align-items: center;
        min-height: 52px;
        margin: 0 5px 10px !important;
        padding: 9px 15px;
        padding-left: 15px !important;
        border: 2px solid $color-white;
        border-radius: 5px;
        background-color: $color-blue-cobalt;
        opacity: .5;
        font-family: $font-gotham-bold;
        font-size: 14px !important;
        line-height: 18px !important;
        cursor: pointer !important;
        user-select: none;
        transition: $opacity-transition;

        .cromis-body & {
            background-color: $color-green-niagara;
        }

        @include media-breakpoint-down(xs) {
            min-height: 40px;
            padding: 8px;
            font-size: 12px;
            line-height: 20px;
        }

        &:hover {
            opacity: 1;
            transition: $opacity-transition;
        }

        &.active {
            background-color: $color-white;
            color: $color-blue-cobalt;
            opacity: 1;
            transition: $opacity-transition;

            .cromis-body & {
                color: $color-green-niagara;
            }

            .checkbox-checkmark {
                border: 2px solid $color-blue-cobalt;
                background-color: $color-blue-cobalt;

                .cromis-body & {
                    border: 2px solid $color-green-niagara;
                    background-color: $color-green-niagara;

                }
            }
        }

        small {
            display: block;
            font-family: $font-gotham;
            line-height: 14px;
        }
    }

    .checkbox-checkmark {
        position: relative;
        top: 6px;
        top: inherit;
        margin-top: 1px;
        margin-right: 10px;
    }
}
