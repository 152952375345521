body.ffessm-body {
    .commissionpage {
        position: relative;
        margin-top: 120px;
        padding-top: 35px;
        padding-bottom: 0;
        background-color: $color-grey-wild-sand;
        @include responsive-md {
            margin-top: 90px;
            padding-top: $size-default-lg;
        }

        .more-link {
            a {
                color: $color-grey-mineshaft !important;
            }
        }

        &-background {
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: -15px;
            right: -15px;
            background-color: $color-grey-wild-sand;
        }

        &-aside {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            height: 100%;
            padding-left: $size-default-lg;
            @include responsive-xl {
                padding-left: 0;
            }
        }

        &-highlights {
            position: relative;
            width: 100%;
            height: 300px;
            color: $color-white;
            background-color: $color-grey-dovegray;
            @include responsive-lg {
                height: 240px;
            }
            @include responsive-md {
                width: auto;
                height: auto;
                margin-top: -22px;
                margin-left: -15px;
                margin-right: -15px;
                background-color: inherit;
            }

            &-slider {
                height: 300px;
                @include responsive-lg {
                    height: 240px;
                }
                @include responsive-md {
                    height: auto;
                }

                &-slide {
                    height: 300px;
                    display: flex;
                    @include responsive-lg {
                        height: 240px;
                    }
                    @include responsive-md {
                        height: calc(100% - 170px);
                        margin: 170px $size-default-md 0;
                        background-color: $color-grey-mineshaft;
                        z-index: 1;
                    }

                    &-leftcontainer {
                        width: 50%;
                        @include responsive-md {
                            width: inherit;
                        }
                    }

                    &-rightcontainer {
                        width: 50%;
                        @include responsive-md {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            width: auto;
                            height: 250px;
                            z-index: -1;
                        }
                    }

                    &-left {
                        padding: $size-default-lg;
                        @include responsive-md {
                            padding: 20px 20px 60px;
                        }
                    }

                    &-caption {
                        span {
                            font-family: 'bebas_neuebold';
                            font-size: 18px;
                            letter-spacing: 3px;
                            text-transform: uppercase;
                            color: $color-grey-gray;
                        }

                        &-title {
                            margin-top: $size-default-md;
                            margin-bottom: 0;
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 34px;
                            @include responsive-lg {
                                font-size: 18px;
                                line-height: 28px;
                            }
                            @include responsive-md {
                                font-size: 24px;
                                line-height: 34px;
                            }

                            a {
                                color: $color-white !important;
                            }
                        }
                    }

                    &-image {
                        height: 100%;
                        width: 100%;
                        background-position: center center;
                        background-size: cover;
                    }
                }

                &-nav {
                    position: absolute;
                    bottom: 0;
                    left: calc(50% - 120px);
                    display: flex;
                    z-index: 2;
                    @include responsive-md {
                        position: absolute;
                        display: flex;
                        right: 16px;
                        bottom: 0;
                        left: inherit;
                        z-index: 1;
                    }

                    button {
                        display: block;
                        width: 60px;
                        height: 60px;
                        border: 0;
                        background-color: transparent;
                        cursor: pointer;
                        @include responsive-md {
                            width: 50px;
                            height: 50px;
                        }

                        svg {
                            vertical-align: -3px;

                            path {
                                fill: $color-white;
                            }
                        }
                    }
                }

                .owl-stage-outer {
                    @include responsive-md {
                        overflow: inherit;
                    }

                    .owl-stage {
                        @include responsive-md {
                            display: flex;
                        }
                    }
                }

                .owl-nav {
                    display: none;
                }

                .owl-dots {
                    position: absolute;
                    bottom: 20px;
                    margin-bottom: 0;
                    left: $size-default-lg;
                    @include responsive-md {
                        left: 35px;
                        bottom: 15px;
                    }

                    .owl-dot {
                        width: 10px;
                        height: 10px;
                        border: 1px solid $color-grey-gray;
                        border-radius: 50%;
                        background-color: transparent;

                        &:not(:last-of-type) {
                            margin-right: 10px;
                        }

                        &.active {
                            background-color: $color-grey-gray;
                        }
                    }
                }
            }
        }

        &-news {
            margin-top: $size-default-xl;
            @include responsive-md {
                padding-top: 10px;
            }

            &-slider {
                @include responsive-sm() {
                    padding-right: 0;
                }

                &-container {
                    position: relative;
                    @include responsive-md {
                        margin-right: -15px;
                    }
                }

                &-slide {
                    display: block;
                    width: 280px;
                    @include responsive-xl() {
                        width: 296px;
                    }
                    @include responsive-lg() {
                        width: 226px;
                    }

                    &-caption {
                        margin-top: 15px;

                        &-title {
                            margin-bottom: 10px;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 24px;
                            color: $color-grey-mineshaft;
                            @include responsive-sm() {
                                font-size: 15px;
                            }
                        }
                    }
                }

                .owl-dots {
                    position: absolute;
                    right: 0;
                    bottom: -45px;

                    .owl-dot {
                        width: 10px;
                        height: 10px;
                        border: 1px solid $color-grey-gray;
                        border-radius: 50%;
                        background-color: transparent;

                        &:not(:first-of-type) {
                            margin-left: 10px;
                        }

                        &.active {
                            background-color: $color-grey-gray;
                        }
                    }
                }

                &-nav {
                    position: absolute;
                    right: 0;
                    top: calc(182px - 60px);
                    z-index: 2;
                    @include responsive-xl() {
                        top: calc(132px - 60px);
                    }
                    @include responsive-lg {
                        top: calc(97px - 50px);
                    }
                    @include responsive-md() {
                        top: -50px;
                        right: 0;
                    }

                    button {
                        display: block;
                        width: 60px;
                        height: 60px;
                        border: 0;
                        background-color: $color-grey-wild-sand;
                        cursor: pointer;
                        @include responsive-lg {
                            width: 50px;
                            height: 50px;
                        }

                        svg {
                            vertical-align: -3px;
                        }

                        &:hover {
                            background-color: $color-primary;

                            .cromis-body & {
                                background-color: $color-cromis-primary;
                            }

                            svg {
                                path {
                                    fill: $color-white;
                                }
                            }
                        }
                    }
                }
            }

            &-list {
                margin-top: $size-default-md;
                align-items: center;

                &-element {
                    display: flex;
                    padding: 20px 0;
                    border-top: 2px solid $color-grey-alto;

                    &:last-of-type {
                        border-bottom: 2px solid $color-grey-alto;
                    }

                    &-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    &-image {
                        width: 120px;
                        margin-right: $size-default-lg;
                    }

                    &-title {
                        margin-bottom: $size-default-xs;
                        font-size: 16px;
                    }
                }
            }

            &-more {
                margin-top: 20px;
                @include responsive-md {
                    margin-top: 15px;
                }
            }
        }

        &-events {
            @include responsive-md {
                margin-top: $size-default-xl;
            }

            &-element {
                display: flex;
                margin-bottom: $size-default-md;

                &-content {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    padding: 10px 15px;
                    font-size: 16px;
                    background-color: $color-white;
                }

                &-calendar {
                    width: 70px;
                    height: 70px;
                    margin-right: 10px;
                    background-color: $color-white;
                    z-index: 1;
                    @include responsive-lg {
                        width: 75px;
                        height: 75px;
                    }

                    &-inner {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        padding: 10px;
                        text-align: center;
                        @include responsive-lg {
                            padding: 10px;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: -8px;
                            left: 12.5px;
                            width: 6px;
                            height: 12.5px;
                            background-color: $color-grey-gallery;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: -8px;
                            right: 12.5px;
                            width: 6px;
                            height: 12.5px;
                            background-color: $color-grey-gallery;
                        }
                    }

                    &-day {
                        color: $color-primary;
                        font-weight: bold;
                        font-family: 'Gotham-Bold';
                        font-size: 30px;
                        line-height: 37px;

                        .cromis-body & {
                            color: $color-cromis-primary;
                        }

                        @include responsive-lg {
                            font-size: 30px;
                            line-height: 42.5px;
                        }
                    }

                    &-month {
                        color: $color-primary;
                        font-size: 11px;
                        text-transform: uppercase;
                        text-align: center;

                        .cromis-body & {
                            color: $color-cromis-primary;
                        }

                        @include responsive-lg {
                            font-size: 9px;
                        }
                    }
                }
            }

            &-more {
                margin-top: $size-default-sm;
            }
        }

        &-slides {
            margin-bottom: $size-default-xxl;
            @include responsive-xl {
                margin-bottom: $size-default-lg;
            }
            @include responsive-md {
                margin-top: $size-default-xl;
            }
        }

        &-social {
            flex-grow: 1;

            @include responsive-md {
                max-width: 500px;
                height: 500px;
                margin-top: $size-default-xl;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .partnerssection {
            margin-top: $size-default-lg;
            background-color: $color-white;
            padding: $size-default-lg $size-default-lg $size-default-xl;
            margin: 0;
            @include responsive-md {
                margin-right: -15px;
                margin-left: -15px;
            }
        }

        &-footer-container {
            position: relative;
            padding-top: 45px;
        }

        &-footer {
            padding: $size-default-lg 0 0;
            background-color: $color-white;
            @include responsive-md {
                display: none;
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }

            &--responsive {
                display: none;
                padding-bottom: $size-default-lg;
                @include responsive-md {
                    display: block;
                }
            }

            &-left {
                padding-left: $size-default-lg;
                @include responsive-md {
                    padding-left: 0;
                }
            }

            &-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                height: 100%;
                padding-right: $size-default-lg;
                padding-bottom: $size-default-lg;
            }

            &-logo {
                max-width: 150px;
            }

            &-address {
                margin: $size-default-md 0 $size-default-lg;
                font-size: 13px;
                text-align: right;
            }

            &-links {
                font-size: 12px;

                a {
                    color: $color-grey-gray;
                    @include responsive-md {
                        display: block;
                        margin: 0;
                    }
                }
            }

            &-nav {
                margin: 0;
                padding: 0;
                list-style: none;
                @include responsive-md {
                    margin-left: -15px;
                    margin-right: -15px;
                }

                &-element {
                    margin-bottom: $size-default-lg;

                    & > span {
                        display: block;
                        margin-bottom: 10px;
                        color: $color-grey-mineshaft;
                        font-family: 'Gotham-Bold';
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                    }

                    & > ul {
                        padding: 0;
                        list-style: none;

                        li {
                            font-size: 12px;

                            a {
                                color: $color-grey-gray;
                            }
                        }
                    }
                }
            }

            &-top {
                display: flex;
                justify-content: space-between;
                padding-bottom: $size-default-md;
                @include responsive-md {
                    border-bottom: 2px solid $color-grey-gallery;
                }

                img {
                    width: 120px;
                    margin-left: $size-default-lg;
                }
            }

            &-bottom {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-top: $size-default-md;

                &-item {
                    &-title {
                        position: relative;

                        &:before {
                            content: url("/img/ui/sm-menu-carret.svg");
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            margin-right: 10px;
                            transition-duration: 0.3s;
                            transform: rotate(-90deg);
                        }
                    }

                    &-content {
                        font-size: 12px;
                        padding: 5px 0 5px 30px;
                    }

                    button {
                        position: relative;
                        padding-left: 30px;
                        font-family: 'Gotham-Bold';
                        font-size: 13px;
                        text-align: left;
                        border: 0;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }

                &-image {
                    margin-left: 30px;
                }
            }
        }
    }
}
