.has-error {
    *.select2-container--default {
        .select2-selection--multiple {
            border-color: $color-red-alizarin;
        }
    }
}

*.select2-container {
    &--default {
        &.select2-container--focus {
            .select2-selection--multiple {
                border: 2px solid $color-primary;
                box-shadow: none;

                .cromis-body & {
                    border: 2px solid $color-cromis-primary;
                }
            }
            .select2-selection--single {
                outline: 0;
            }
        }

        .select2-selection--multiple,
        .select2-selection--single {
            border: 2px solid $color-grey-gallery;
            border-radius: 0;
            font-weight: 600;

            .select2-selection__rendered {
                display: block;
                padding: 0;
            }

        }

        .select2-selection--multiple {
            min-height: 40px;
            padding: 0.5px 5.5px 5.5px;

            .select2-selection__choice {
                background-color: $color-primary;
                border: 0;
                border-radius: 0;
                cursor: default;
                float: left;
                margin-right: 5px;
                margin-top: 5px;
                padding: 0 5px;
                color: $color-white;
                padding: 3.5px 7px 3px;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;

                .cromis-body & {
                    background-color: $color-cromis-primary;
                }
            }

            .select2-selection__choice__remove {
                color: rgba($color-white, 0.5);
                cursor: pointer;
                display: inline-block;
                font-weight: bold;
                margin-right: $size-default-xxs;
                vertical-align: -1px;
                height: 8px;
                line-height: 10px;

                &:hover {
                    color: $color-white;
                }
            }
        }

        .select2-selection--single {
            min-height: 38px;
            padding: .375rem .75rem;
            padding-right: 35px;

            .select2-selection__rendered {
                line-height: 25px;
            }

            .select2-selection__arrow {
                height: 38px;
                right: 5px;
            }
        }

        .select2-results__option {
            font-size: 12px;

            &--highlighted[aria-selected] {
                background-color: $color-primary !important;

                .cromis-body & {
                    background-color: $color-cromis-primary !important;
                }
            }
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            border-width: 2px;

            &:focus,
            &:active {
                outline: none;
                border-color: $color-primary;

                .cromis-body & {
                    border-color: $color-cromis-primary;
                }
            }
        }
    }

    .select2-search--inline {
        .select2-search__field {
            margin-top: 7px;
        }
    }
}

.select2-dropdown {
    border-radius: 0;
    border-color: $color-grey-gallery;
}
