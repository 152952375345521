.scroll-top {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 3;
    height: 75px;
    width: 75px;
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;

    @include media-breakpoint-down(md) {
        height: 50px;
        width: 50px;
    }
    @include media-breakpoint-down(xs) {
        right: 12.5px;
        bottom: 12.5px;
    }

    &--visible {
        opacity: .5;
        cursor: pointer;
        pointer-events: inherit;
        transition: opacity .3s ease;

        &:active,
        &:focus,
        &:hover {
            opacity: 1;
            transition: opacity .2s ease;
        }
    }

    img {
        height: 100%;
        width: 100%;
    }
}
