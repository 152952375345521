.menu {
  .menu_link {
    color: white;
  }
  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &_item {
    font-size: 0.75rem;
    line-height: 1.33;
    &--highlight {
      margin-bottom: 0.75rem;
    }
  }

  &_link {
    color: $white;
  }
}