.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 18.75rem;
  min-height: 3.75rem;
  padding: 0.5rem 1rem;
  background-color: $white;
  color: $grey-100;
  font-size: 1rem;
  line-height: 1.25;
  text-align: center;
  box-shadow: none;
  border: none;
  border-radius: 2rem;
}