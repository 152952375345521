body,
html {
    // height: 100%;
    overflow-x: hidden;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    background-color: $color-grey-wild-sand;
    font-family: 'Gotham-Book';

    &.ffessm-body {
        height: initial;
        background-color: $color-white;

        a {
            color: $color-grey-mineshaft;

            &:active,
            &:focus,
            &:hover {
                color: $color-secondary;
            }

            &:not([href]):not([tabindex]) {
                color: $color-grey-mineshaft;
            }
        }
    }

    &.body--contentpage {
        background-color: $color-grey-wild-sand;
    }
}

button {
    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
}

h1 {
    text-transform: uppercase;
    color: $color-primary;
    font-size: 48px;
    font-family: 'bebas_neuebold';

    .cromis-body & {
        color: $color-cromis-primary;
    }
}

.line-top {
    border-top: 1px solid $color-grey-mercury;
    padding-top: 30px;
}

.line-bottom {
    border-bottom: 1px solid $color-grey-mercury;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.line-left {
    border-left: 1px solid $color-grey-mercury;
    padding-left: 70px;
    margin-left: 70px;
    @media screen and (max-width: 762px) {
        border-left: none;
        padding-left: initial;
        margin-left: initial;
    }
}

.container {
    @include media-breakpoint-down(sm) {
        max-width: calc(576px - 30px);
    }
}

.containerwrapper {
    max-width: $container-width;
    margin: 0 auto;
}

a {
    color: $color-grey-light;

    &:hover {
        text-decoration: none;
    }
}

.center-h {
    margin: 0 auto;
}

.center-text {
    text-align: center;
}

@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'), url('../fonts/bebasneue_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/Gotham-Bold.eot');
    src: url('../fonts/Gotham-Bold.woff') format('woff'), url('fonts/Gotham-Bold.ttf') format('truetype'), url('fonts/Gotham-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/Gotham-Book.eot');
    src: url('../fonts/Gotham-Book.woff') format('woff'), url('fonts/Gotham-Book.ttf') format('truetype'), url('fonts/Gotham-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

//---------------------------------------------------
// Dropdown menu from CodyHouse (https://codyhouse.co/gem/stripe-navigation)
//---------------------------------------------------
// breakpoints
$S: 480px;
$M : 600px;
// $L : 992px;
$L : 768px;
// media queries
@mixin MQ($canvas) {
     @if $canvas==S{
        @media only screen and (min-width: $S) {
             @content;
        }
    }
    @else if $canvas==M {
         @media only screen and (min-width: $M){
            @content;
        }
    }
    @else if $canvas==L {
         @media only screen and (min-width: $L){
            @content;
        }
    }
}

// super light grid system
@mixin column($percentage, $float-direction:left) {
    width: 100% * $percentage;
    float: $float-direction;
}
