blockquote {
    position: relative;
    padding: $size-default-lg !important;
    border: 1px solid $color-grey-gallery;
    border-left-color: rgba($color-primary, .5);
    border-left-width: 5px;
    color: $color-primary;
    background-color: rgba($color-primary, .05);

    .cromis-body & {
        border-left-color: rgba($color-cromis-primary, .5);
        color: $color-cromis-primary;
        background-color: rgba($color-cromis-primary, .05);
    }

    a {
        color: $color-primary;

        .cromis-body & {
            color: $color-cromis-primary;
        }
    }

    i {
        margin-right: $size-default-md;
    }

    &.error {
        border-left-color: rgba($color-red-alizarin, .5);
        color: $color-red-alizarin;
        background-color: rgba($color-red-alizarin, .05);
        font-size: 16px;
    }

    &.success {
        border-left-color: rgba($color-green-apple, .5);
        color: $color-green-apple;
        background-color: rgba($color-green-apple, .05);
        font-size: 16px;
    }
}
