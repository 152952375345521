@media (min-width: 768px) {
    .col-md-5th {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 percentage(1/5);
        flex: 0 0 percentage(1/5);
        max-width: percentage(1/5);
    }

    .col-md-4th {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 percentage(1/4);
        flex: 0 0 percentage(1/4);
        max-width: percentage(1/4);
    }

    .col-md-3rd {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 percentage(1/3);
        flex: 0 0 percentage(1/3);
        max-width: percentage(1/3);
    }
}

.col-md-5th {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: $size-default-md;
    padding-left: $size-default-md;
}

.col-md-4th {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: $size-default-md;
    padding-left: $size-default-md;
}

.col-md-3rd {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: $size-default-md;
    padding-left: $size-default-md;
}