.searchbar {
    position: relative;
    width: 18px;
    height: 30px;
    font-family: 'Gotham-Book';
    font-size: 16px;

    &--dark {
        .searchbar {
            &-input {
                &::before {
                    color: $color-white;
                }

                input {
                    background-color: transparent;
                    color: $color-white;

                    &::placeholder {
                        color: rgba($color-white, 0.8);
                    }
                }

                button {
                    svg {
                        path {
                            fill: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
        }
    }

    &-toggle {
        position: absolute;
        z-index: 5;
        top: 5px;
        right: 0;
        cursor: pointer;
        @include responsive-md {
            right: -1px;
        }
    }

    &-input {
        overflow: hidden;
        position: absolute;
        right: -26px;
        top: -2px;
        width: 0;
        margin-right: 20px;
        padding: 0 30px 15px 15px;
        opacity: 0;
        background-color: transparent;
        transition: width 0.5s, opacity 0.3s;
        @include responsive-md() {
            height: 60px;
            padding-bottom: 0;
            top: 0;
            margin-top: 0;
            display: flex;
            padding-right: 0;
        }

        &::before {
            content: 'Rechercher';
            position: absolute;
            top: 5px;
            left: 15px;
            color: $color-grey-mineshaft;
            @include responsive-md() {
                display: none;
            }
        }

        input {
            width: 100%;
            margin-top: 4px;
            padding-left: 100px;
            border: 0;
            border-bottom: 1px solid #f0f0f0;
            outline: none;
            background-color: $color-white;
            color: inherit;
            @include responsive-md() {
                padding-left: 0;
                border-bottom: 0;
                font-size: 14px;
                margin-top: 1px;
            }

            &::placeholder {
                color: inherit;
            }
        }

        button {
            position: absolute;
            top: 6px;
            right: 0;
            border: 0;
            background-color: transparent;
            @include responsive-md() {
                top: 5px;
                position: relative;
            }

            svg {
                path {
                    fill: inherit;
                }
            }
        }
    }

    &--opened {
        .searchbar-input {
            width: 500px;
            opacity: 1;
            transition: width 0.3s, opacity 0.3s;

            @include responsive-md() {
                width: 200px;
            }
        }

        .searchbar-toggle {
            display: none;
        }
    }
}
