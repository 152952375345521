@mixin fontSizesLoop() {
    @each $name,
    $property in $font-sizes-helper-properties {
        @each $font-size,
        $value in $font-sizes {
            .#{$name}-#{$font-size} {
                #{$property}: #{$value} !important;
            }
        }
    }
}

//---------------------------------------------------
// Prefix ('', 'is-', 'has-')
//---------------------------------------------------
$helper-prefix: '';
//---------------------------------------------------
// General mixins
//---------------------------------------------------
@mixin renderClass($classNames, $properties, $responsive) {
    @if $responsive {
        @each $className in $classNames {
            &-#{$helper-prefix}#{$className} {
                @each $property,
                $value in $properties {
                    #{$property}: #{$value} !important;
                }
            }
            &-#{$helper-prefix}#{$className}-children > * {
                @each $property,
                $value in $properties {
                    #{$property}: #{$value} !important;
                }
            }
        }
    }
    @else {
        @each $className in $classNames {
            .#{$helper-prefix}#{$className} {
                @each $property,
                $value in $properties {
                    #{$property}: #{$value} !important;
                }
            }
            .#{$helper-prefix}#{$className}-children > * {
                @each $property,
                $value in $properties {
                    #{$property}: #{$value} !important;
                }
            }
        }
    }
}

//---------------------------------------------------
// Margin, Padding
//---------------------------------------------------
$helper-margin-padding-properties: ( m: margin, p: padding, );
$helper-margin-padding-directions: (top: vertical, right: horizontal, bottom: vertical, left: horizontal);
$helper-margin-padding-values: ( n: 0, 5xs: calc($size-default / 10), 4xs: calc($size-default / 8), 3xs: calc($size-default / 6), 2xs: calc($size-default / 4), xs: calc($size-default / 3), sm: calc($size-default / 2), md: $size-default, lg: $size-default * 2, xl: $size-default * 3, 2xl: $size-default * 4, 3xl: $size-default * 6, 4xl: $size-default * 8, 5xl: $size-default * 10, 6xl: $size-default * 12, 7xl: $size-default * 14, 8xl: $size-default * 16, 9xl: $size-default * 18, 10xl: $size-default * 20, auto: auto, inherit: inherit, initial: initial, unset: unset, );
@mixin helper-margin-padding($responsive) {
    @each $p-index,
    $p-value in $helper-margin-padding-properties {
        @each $d-index,
        $d-value in $helper-margin-padding-directions {
            @each $v-index,
            $v-value in $helper-margin-padding-values {
                $classes: ( #{$p-index}-a-#{$v-index}, #{$p-index}-#{str-slice($d-index, 0, 1)}-#{$v-index}, #{$p-index}-#{str-slice($d-value, 0, 1)}-#{$v-index});
                $properties: (#{$p-value}-#{$d-index}: $v-value);
                @include renderClass($classes, $properties, $responsive);
            }
        }
    }
}

@include helper-margin-padding($responsive);
//---------------------------------------------------
// Dropdown menu from CodyHouse (https://codyhouse.co/gem/stripe-navigation)
//---------------------------------------------------

// center vertically and/or horizontally an absolute positioned element
@mixin center($xy: xy) {
    @if $xy==xy {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    @else if $xy==x {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    @else if $xy==y {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }
} // border radius
@mixin border-radius($radius: .25em) {
    border-radius: $radius;
} // antialiasing mode font rendering
@mixin font-smoothing {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
} // clearfix
@mixin clearfix {
    &::after {
        content: '';
        clear: both;
        display: block;
    }
} // color tint and shade
@function shade( $color, $percent) {
    @return mix(#000, $color, $percent);
}

@function tint( $color, $percent) {
    @return mix(#fff, $color, $percent);
}
