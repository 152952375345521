a.collapsible-item-title-link {
    display: block !important;
    text-decoration: none !important;
    font-size: 18px;
}

.collapsible-item-title-link span {
    color: #000 !important;
}

.collapsible-item-heading {
    background-color: #f4f8ef;
    color: #72b73a;
    text-decoration: none;
    font-size: 20px;
}

.collapsible-item-collapse {
    background-color: #ddd;
    min-height: 10px;
    transition: none !important;
    -webkit-transition: none !important;
}

.collapsible-item {
    padding: 5px;
    margin: 10px;
    background: #eee;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #fff inset, 0 -1px 0px #ccc inset;
}

.collapsible-item-title, .collapsible-item-body {
    border: 1px solid #cccccc;
    background: #fff;
}

.collapsible-item-title {
    margin: 0px !important;
    padding: 5px 8px !important;
}

.collapsible-item-body {
    padding: 15px 0 0 10px;
}

.collapsible-item-title-link-text {
    min-width: 50px;
    display: inline-block;
    min-height: 20px;
    height: 100%;
}

.collapsible-item-title-link-icon {
    display: none;
}
