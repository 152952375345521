//==================================================
// Container
//==================================================

$large-container-width: 1440px;
$container-width: 1200px;

//==================================================
// Fonts
//==================================================

$font-gotham: 'Gotham-Book';
$font-gotham-bold: 'Gotham-Bold';
$font-bebas: 'bebas_neuebold';

//==================================================
// Colors
//==================================================

$color-primary: #006daa;
$color-secondary: #d8222a;

$color-cromis-primary: #007B6D;

$color-white: #fff;
$color-yellow-laser-lemon: #fffb69;
$color-blue-aquamarine: #5BF2FF;
$color-green-forest: #24a431;
$color-green-apple: #48ab49;
$color-green-niagara: #0DAE9C;
$color-red-alizarin: #d8232a;
$color-red-alizarin-crimson: #ef2129;
$color-red-razzmatazz: #df0056;
$color-blue-cyan: #00f6ff;
$color-blue-picton: #46c3e5;
$color-blue-malibu: #80bdff;
$color-blue-curious: #1a9ee8;
$color-blue-orient: #005c8f;
$color-blue-azure-radiance: #007bff;
$color-blue-cobalt: #0050b3;
$color-blue-regal: #00387d;
$color-blue-madison: #0f3c6e;
$color-blue-zodiac: #0f1f4a;
$color-grey-wild-sand: #f5f5f5;
$color-grey-gallery: #f0f0f0;
$color-grey-gallery-alt: #efefef;
$color-grey-mercury-lighter: #e9e9e9;
$color-grey-mercury: #e5e5e5;
$color-grey-alto: #dedede;
$color-grey-silver: #ccc;
$color-grey-light: #aeaeae;
$color-grey-gray: #888;
$color-grey-dovegray: #666;
$color-grey-mineshaft: #333;
$color-grey-codgray: #1a1a1a;
$color-black: #000;

$gradient-cerulean-blumine: linear-gradient(270deg, #0099d7 0%, #1d578e 100%);
$gradient-blackrussian: linear-gradient(0deg, #01001a 0%, rgba(#01001a ,0) 100%);

//==================================================
// Variable for box-model helpers
//==================================================

$size-default: 15px;
$size-defaults: (
    n: 0,
    xxxs: calc($size-default / 6),
    xxs: calc($size-default / 4),
    xs: calc($size-default / 3),
    sm: calc($size-default / 2),
    md: $size-default,
    lg: calc($size-default * 2),
    xl: calc($size-default * 3),
    xxl: calc($size-default * 4),
    xxxl: calc($size-default * 6),
);
$size-default-n: 0;
$size-default-xxxs: calc($size-default / 6);
$size-default-xxs: calc($size-default / 4);
$size-default-xs: calc($size-default / 3);
$size-default-sm: calc($size-default / 2);
$size-default-md: $size-default;
$size-default-lg: calc($size-default * 2);
$size-default-xl: calc($size-default * 3);
$size-default-xxl: calc($size-default * 4);
$size-default-xxxl: calc($size-default * 6);

// Sizes : helpers
$size-helper-types: (margin, padding);
$size-helper-sides: (top: vertical, right: horizontal, bottom: vertical, left: horizontal);
$siz1e-helper-sizes: (n, xxxs, xxs, xs, sm, md, lg, xl, xxl, xxxl);

//==================================================
// Browser specific
//==================================================

$edge-flex-calc-adjuster: 0.01px;
