$opacity-transition: opacity .1s ease;
$opacity-slow-transition: opacity .3s ease;

@keyframes loading {
    0% {
        opacity: 1;
    }

    45% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

.divelog {
    position: relative;
    color: $color-white;
    font-size: 14px;

    .cromis-body & {
        position: relative;
        min-height: 100vh;

        &::before {
            content: '';
            position: absolute;
            bottom: -50px;
            left: -15px;
            right: -15px;
            width: 100vw;
            height: 200px;
            background: linear-gradient(180deg, rgba(13, 174, 156, 0) 0%, $color-cromis-primary 100%);
        }
    }

    @import 'main';
    @import 'components';
    @import 'form';
    @import 'layout';
    @import 'pages/pages';

    &-diveview {
        .divelog-container {
            @include media-breakpoint-down(xs) {
                padding-top: 120px !important;
            }
        }

        .divelog-backgroundtop {
            &-fitter {
                overflow: hidden;
                height: 200px;

                @include media-breakpoint-down(sm) {
                    height: 150px;
                }

                @include media-breakpoint-down(xs) {
                    height: 120px;
                }

                img {
                    transform: translateY(-20%);

                    @include media-breakpoint-down(lg) {
                        transform: translateY(-18%);
                    }

                    @include media-breakpoint-down(md) {
                        transform: translateY(-12%);
                    }

                    @include media-breakpoint-down(sm) {
                        transform: translateY(-17%);
                    }

                    @include media-breakpoint-down(xs) {
                        transform: translateY(-19%);
                    }
                }
            }
        }
    }

    &-diveeditinfo,
    &-diveeditobservations,
    &-diveeditpictures {
        .divelog-content {
            margin-top: 150px;

            @include media-breakpoint-down(xs) {
                margin-top: 70px;
            }
        }
    }

    &-dashboard,
    &-diveview,
    &-divingsiteview {
        .divelog-section {
            &-title {
                margin-bottom: inherit;
                color: $color-white;
                font-family: $font-gotham-bold;
                font-size: 40px;
                line-height: 50px;
                text-align: inherit;

                .fa {
                    font-size: 30px;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 35px;

                    .fa {
                        font-size: 25px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    font-size: 32px;

                    .fa {
                        font-size: 20px;
                    }
                }

                @include media-breakpoint-down(xs) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            &-subsubtitle {
                @include media-breakpoint-down(xs) {
                    font-size: 18px;
                }
            }

            &-subtitle {
                margin-bottom: 25px;
                color: $color-yellow-laser-lemon;
                font-family: $font-gotham-bold;
                font-size: 30px;
                line-height: 50px;

                .cromis-body & {
                    color: $color-blue-aquamarine;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 25px;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    &-diveview,
    &-divingsiteview {
        &-editbutton {
            z-index: 999;
            position: absolute;
            top: 175px;
            right: 0;

            @include media-breakpoint-down(sm) {
                top: 125px;
            }

            @include media-breakpoint-down(xs) {
                top: 100px;
            }

            .ffessm-button {
                @include media-breakpoint-down(xs) {
                    padding: 8px 15px;
                    font-size: 13px !important;
                    letter-spacing: 3px;
                }
            }

            .invalidate-form {
                display: inline-block;
            }
        }

        .divelog-header {
            height: 100px;
            top: 0;
        }
    }

    &-geoloc {

        max-width: 1200px;
        margin-left: auto;

        .select2-selection {
            border-radius: 5px;
            padding: 0 !important;
        }

        .select2-selection__placeholder {
            position: relative;
            top: 6px;
            left: 12px;
        }

        .select-container {
            margin-top: 5px;
            padding: 20px 30px;
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            text-align: center;
        }

        .select-hidden {
            display: none !important;
        }

        .select-field {
            display: flex;
            flex-flow: row nowrap;
            gap: 1rem;
        }

        .select-field>div {
            display: flex;
            flex-flow: column wrap;
            flex: 1 0 auto;
        }

        .diving-form-query .select2-selection__rendered {
            position: relative;
            top: 6px;
        }

        .js-divingSiteSelector .select2-selection__rendered {
            position: relative;
            top: -1px !important;
        }

        .select2-selection__placeholder {
            position: relative;
            top: -1px !important;
        }

        &-button-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            gap: 5px;

            @include media-breakpoint-down(sm) {
                flex-flow: column wrap;
                gap: 10px;
            }
        }

        &-form {
            &>form {
                display: flex;
                flex-flow: column wrap;
                gap: 0.75rem;
            }

            &-rest {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                margin-top: 1rem;

                &>div {
                    display: flex;
                    flex-flow: column wrap;
                    width: 100%;
                    flex: 0 0 32.7%;

                    @include media-breakpoint-down(sm) {
                        flex: 1 0 50%;
                    }

                    input,
                    select {
                        width: 100%;
                    }
                }
            }

            button[type="submit"] {
                margin-top: 1rem;
                align-self: flex-end;
                width: 280px;
            }

        }

        &-section {
            display: flex;
            flex-flow: row nowrap !important;
            gap: 10px;

            &>div {
                display: flex;
                flex-flow: column wrap;
                flex: 1;
            }
        }

        &-btn {
            margin-top: 15px;
            align-self: flex-end;
            align-self: end;
            border-radius: 5px;
        }
    }

    .questions-popup {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;

        .popup-title {
            font-size: 24px;
            font-weight: bold;
            color: #FFF;
            text-align: center;
            padding: 5px;
            margin-bottom: 45px;
        }

        .popup-content {
            background-color: $color-cromis-primary;
            max-height: 80%;
            max-width: 70%;
            margin: 50px auto;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            overflow-y: scroll;
        }

        .question-row {
            height: 80px;
        }

        .observation_question {
            width: 50%;
        }

        .observation_answer {
            width: 50%;
            height: 60px;
        }

        .autocomplete-items {
            border: 1px solid #d4d4d4;
            border-top: none;
            position: absolute;
            margin-left: 50%;
            background-color: #FFF;
            color: #000;
            left: 0;
            z-index: 1;
            width: 30%;
            font-size: 14px;
            padding: 6px;
            max-height: 150px;
            overflow-y: auto;
        }

        .questions-popup-close {
            background-color: #4CAF50;
            color: white;
            border: none;
            padding: 12px 24px;
            font-size: 16px;
            border-radius: 8px;
            cursor: pointer;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s, transform 0.2s;
        }

        .close-button {
            float: right;
            margin-top: -15px;
            margin-right: -10px;
            cursor: pointer;
            font-size: 26px;
            color: #f74336;
        }
    }

    #dive-map-area {
        .dive-map-share-button {
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 401;
            background-color: $color-grey-wild-sand;
            border: 2px solid $color-primary;
            border-radius: 2px;
            width: 30px;
            height: 30px;
            cursor: pointer;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 22px;
                color: $color-primary;
            }
        }

        .iframe-container {
            display: flex;

            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s ease;


            button {
                color: white;
                width: 40px;
                background-color: $color-primary;
                border-top: none;
                border-right: none;
                border-left: 1px solid white;
                border-bottom: 1px solid white;
                border-bottom-left-radius: 5px;
                cursor: pointer;
            }

            input {
                width: 100%;
                border-radius: 0 0 5px 0;
            }
        }

        .reveal {
            max-height: 200px;
        }
    }

    .flash-message-container {
        position: fixed;
        bottom: -50px; /* Start below the visible area */
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-green-apple;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: bottom 0.3s ease-in-out;

        &.visible {
            bottom: 20px; /* Bring it up smoothly */
        }

        .flash-message {
            margin: 0;
        }
    }
}

#diving_site_list_divingCategories input {
    margin-right: 4px;
}

#diving_site_list_divingCategories label {
    margin-right: 25px;
}
