// Colors
$blue: #006daa;
$red: #d8232a;
$white: #fff;
$grey-900: #f5f5f5;
$grey-300: #888;
$grey-100: #333;
$black: #000;

$color-primary: $blue;

// Fonts
$font-primary: 'Gotham-Book', sans-serif;
$font-secondary: 'bebas_neuebold', sans-serif;

// Breakpoints
$breakpoints: (
  'small':  ( min-width: 480px ),
  'medium':  ( min-width: 768px ),
  'large': ( min-width: 1200px ),
) !default;