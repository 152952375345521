
.card {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 1.25rem 2rem;
  background-color: $white;
  color: $grey-100;
  transition: all 0.25s ease;

  &:hover {
    box-shadow: 0 0 20px 0 rgba(48, 62, 70, 0.11);
    color: inherit !important;
    transform: scale(1.02);
  }

  &_title {
    position: relative;
    margin: 0 0 0.625rem;
    font-family: $font-secondary;
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    z-index: 2;
  }

  &_doris {
    width: 72px;
    padding: 0;
    margin-top: -10px;
  }
  &_description {
    position: relative;
    line-height: 1.6;
    z-index: 2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.hover-white {
   &:hover {
     color: white !important;
     transform: scale(1.02);
   }
 }