[data-opacity=one] {
    opacity: 1 !important;
    transition: opacity .3s ease 0s;
}

[data-opacity=zero] {
    opacity: 0 !important;
    transition: opacity .3s ease 0s;
}

//---------------------------------------------------
// Margin, Padding
//---------------------------------------------------

// Avoid margins collapse
.has-overflow-hidden {
    overflow: hidden;
}

.has-position-relative {
    position: relative;
}

.has-zindex-0 {
    z-index: 0 !important;
}

// @include marginLoop();

//---------------------------------------------------
// Sizes
//---------------------------------------------------

.is-full-height {
    height: 100%;
}

.is-full-width {
    width: 100%!important;
}

.is-auto-width {
    width: auto!important;
}

//---------------------------------------------------
// Forms
//---------------------------------------------------

.has-calendar-icon {
    input[type=date] {
        background: url(/img/cal.svg) $color-white no-repeat center right 10px;
    }
}

.is-disabled {
    background-color: $color-grey-light !important;
    cursor: not-allowed !important;
}

.has-required-error {
    border-color: $color-secondary;
}

//---------------------------------------------------
// Borders
//---------------------------------------------------

.border-right-thin-grey {
    border-right: 1px solid rgba(151, 151, 151, .3);
}


.separator {
    border: 1px solid #979797;
    opacity: .3;

    &--grey-gallery {
        border-color: $color-grey-gallery;
    }
}

.has-gray-background {
    background-color: $color-grey-wild-sand;
}

.has-white-background {
    background-color: $color-white;
}

.has-blue-background {
    background-color: $color-primary;

    .cromis-body & {
        background-color: $color-cromis-primary;
    }
}

.has-deepblue-background {
    background-color: $color-blue-orient;

    .cromis-body & {
        background-color: $color-green-niagara;
    }
}



.has-full-vw-left {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100vw;
    &--no-margin {
        right: -15px;
    }
}

.has-full-vw-right {
    position: absolute;
    top: 0;
    left: 20px;
    z-index: -1;
    height: 100%;
    width: 100vw;
    &--no-margin {
        left: -15px;
    }
}
