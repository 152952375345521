.events-list {
    position: relative;
    padding-left: 20px;
    border-left: 2px solid rgba(0, 109, 170, .3);

    &--events-calendar {
        padding-top: 15px;
        padding-bottom: 10px;

        ul {
            margin-bottom: 0;

            li {
                margin-bottom: 20px !important;
                font-size: 14px;

                &:last-of-type {
                    margin-bottom: 0 !important;
                }

                & > svg {
                    background-color: $color-grey-gallery;
                }
            }
        }
    }

    &--home {
        ul {
            margin-top: 30px !important;

            @include responsive-lg {
                margin-top: 20px !important;
            }

            li > svg {
                background-color: $color-white;
            }
        }
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;

            @include responsive-lg() {
                margin-bottom: 20px;
                font-size: 14px;
            }
            @include responsive-md() {
                & > svg {
                    left: -7px;
                }
            }

            & > svg {
                position: absolute;
                left: -8px;
                height: 20px;
                width: 14px;
                padding-top: 1px;
            }
        }
    }

    &-date {
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        padding-top: 2px;
        border: 1px solid $color-grey-mercury;
        line-height: 10px;
        text-align: center;

        &-day {
            display: block;
            color: $color-primary;
            font-family: 'Gotham-Bold';
            font-size: 14px;
            line-height: 13px;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        &-month {
            margin-top: 3px;
            color: $color-grey-dovegray;
            font-family: 'Gotham-Book';
            font-size: 8px;
            line-height: 10px;
            text-transform: uppercase;
        }
    }
}
