div.leaflet-popup-content {
    margin: 0;
    padding: $size-default-md;
    font-family: $font-gotham;

    &-wrapper {
        padding: 0;
        border-radius: 3px;
    }

    &-clublist,
    &-sitelist {
        min-width: 150px;
        color: $color-grey-mineshaft;
        text-align: center;

        .title {
            margin-bottom: 10px;
            font-family: $font-gotham-bold;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.5px;
        }

        .subtitle {
            font-size: 12px;
            font-weight: 300;
            line-height: 12px;
        }

        .button {
            display: block;
            height: 30px;
            width: 100%;
            margin: 10px 0 0;
            padding: 8px;
            border-radius: 3px;
            background-color: $color-blue-cobalt;
            color: $color-white;
            opacity: 1;
            font-size: 12px;
            font-weight: 600;
            line-height: 17px;
            transition: opacity 0.2s
                ease 0;

            .cromis-body & {
                background-color: $color-green-niagara;
            }

            &:active,
            &:focus,
            &:hover {
                color: $color-white;
                opacity: 0.7;
                transition: opacity 0.2s
                    ease 0;
            }
        }
    }

    &-clublist {
        .image {
            max-height: 60px;
            max-width: 100px;
            margin: 0 auto 10px;

            img {
                max-height: 60px;
                max-width: 100px;
            }
        }

        .subtitle {
            margin: 0 auto;
            font-size: 11px;

            .small {
                font-size: 10px;
            }

            &.subtitle {
                margin-top: $size-default-sm;
            }
        }
    }

    &-sitelist {
        .image {
            height: 60px;
            width: 60px;
            margin: -45px auto 10px;
            border-radius: 50%;

            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .title {
            margin-bottom: 5px;
        }
    }
}

a.leaflet-popup-close-button {
    height: 15px;
    width: 15px;
    padding: 0;
    font-family: $font-gotham-bold;
    font-size: 20px;
    line-height: 15px;
}

.leaflet-section-input {
    z-index: 499;
    position: absolute;
    width: 400px;
    right: 30px;
    padding: 0 10px;
    margin-top: 10px;

    @include responsive-lg() {
        right: 0px;
    }
    @include responsive-md() {
        width: 100%;
        right: inherit;
    }

    /* search form styling */
    form {
        // position: absolute;
        // top: -12px;
        // left: 28px;
        // z-index: -1;
        // display: none;
        height: auto;
        margin: 0;
        border: 2px solid
            rgba($color-black, 0.2);
        border-radius: 4px;
        background-clip: padding-box;
        background-color: $color-white;

        input {
            height: 30px;
            width: 100%;
            min-width: 200px;
            margin: 0;
            padding: 0;
            border: none;
            border-radius: 4px;
            font-size: 12px;
            text-indent: 8px;
            outline: none;
        }
    }

    a.reset {
        position: absolute;
        top: 2px;
        right: 12px;
        padding: 4px 8px 0;
        border: none;
        background-color: $color-white;
        color: $color-black;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        cursor: pointer;

        &:hover {
            background-color: $color-blue-cobalt;
            color: $color-white;

            .cromis-body & {
                background-color: $color-green-niagara;
            }
        }

    }

    .results {
        background: $color-white;

        & > * {
            overflow: hidden;
            padding: 0 8px;
            color: $color-black;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.active,
            &:hover {
                background-color: $color-blue-cobalt;
                color: $color-white;
                cursor: pointer;

                .cromis-body & {
                    background-color: $color-green-niagara;
                }
            }
        }

        &.active {
            border-top: 1px solid
                #c6c6c6;

            /* add missing border to form */
            &:after {
                content: '';
                position: absolute;
                top: 30px;
                bottom: -2px;
                left: -2px;
                display: block;
                width: 0;
                // border-left: 2px solid
                //     rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.club-cluster {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #005c8f;
    color: white;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    margin-top: -20px;
    margin-left: -20px;
}