.portal {
    max-width: 700px;
    margin: 150px auto 0 auto;

    .ssoLogin {
        margin-bottom: 10px;

        i, small {
            display: none;
        }

        &--loading {
            cursor: initial !important;

            i {
                display: inline-block;
            }

            small {
                display: block;
            }
        }
    }

    p.font-italic {
        a {
            color: $color-primary;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }
    }
}