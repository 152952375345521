$loader-size: 200px;
$small-loader-size: $loader-size / 8;
$loader-z-index: 1001;

@keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.loader-positioner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader-background {
    position: relative;
    height: 100%;
    width: 100%;
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease 0s, visibility 0s ease 0s;

    &--leaflet {
        z-index: $loader-z-index;
        background-color: rgba($color-white, .5);
    }

    &--hidden {
        visibility: visible;
        opacity: 0;
        transition: opacity .3s ease 0s, visibility 0s ease .3s;
    }
}

.leaflet-small-loader-positioner {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: $loader-z-index;
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease 0s, visibility 0s ease 0s;

    &--hidden {
        visibility: visible;
        opacity: 0;
        transition: opacity .3s ease 0s, visibility 0s ease .3s;
    }
}

.loader {
    position: relative;
    display: block;
    height: auto !important;
    width: auto !important;
    min-width: $loader-size;
    padding-top: $loader-size;
    color: rgba($color-black, .87);

    @include responsive-lg {
        min-width: calc(#{$loader-size} / 2);
        padding-top: calc(#{$loader-size} / 2);
    }

    &--small {
        min-width: $small-loader-size;
        padding-top: $small-loader-size;

        @include responsive-lg {
            min-width: $small-loader-size !important;
            padding-top: $small-loader-size !important;
        }

        &:after,
        &:before {
            height: $small-loader-size !important;
            width: $small-loader-size !important;
            margin: 0 0 0 calc(-#{$small-loader-size} / 2) !important;
            border-width: calc(#{$small-loader-size} / 10) !important;

            @include responsive-lg {
                height: $small-loader-size !important;
                width: $small-loader-size !important;
                margin: 0 0 0 calc(-#{$small-loader-size} / 2) !important;
                border-width: calc(#{$small-loader-size} / 10) !important;
            }
        }

        &:before {
            border-color: rgba($color-white, .5) !important;
        }
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: $loader-size;
        height: $loader-size;
        margin: 0 0 0 calc(-#{$loader-size} / 2);
        border-style: solid;
        border-width: calc(#{$loader-size} / 20);
        border-radius: 500rem;

        @include responsive-lg {
            width: calc(#{$loader-size} / 1.5);
            height: calc(#{$loader-size} / 1.5);
            margin: 0 0 0 calc(-#{$loader-size} / 3);
            border-width: calc(#{$loader-size} / 30);
        }
    }

    &:before {
        border-color: rgba($color-black, .1);
    }

    &:after {
        border-color: $color-primary transparent transparent;
        box-shadow: 0 0 0 1px transparent;
        animation: loader .6s linear;
        animation-iteration-count: infinite;

        .cromis-body & {
            border-color: $color-cromis-primary transparent transparent;
        }
    }
}
