/* --------------------------------

 Header

-------------------------------- */
.cd-morph-dropdown {
    position: relative;

    &::before {
        /* never visible - used in JS to check mq */
        content: 'mobile';
        display: none;
    }

    .main-nav {
        display: none;
    }

    .morph-dropdown-wrapper {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        padding: 1.2em 5%;
        box-shadow: inset 0 1px 0 shade($color-white, 10%);
        background-color: $color-white;
    }

    &.nav-open .morph-dropdown-wrapper {
        display: block;
    }

    .dropdown-list ul {
        display: block;
    }

    .dropdown-list > ul > li {
        margin-bottom: 3.3em;
        padding: 4px 0;
    }

    .label {
        display: block;
        font-size: 2.2rem;
        color: $color-grey-codgray;
        margin-bottom: 0.8em;
    }

    .content li {
        @include clearfix;
    }

    .content h2 {
        color: shade($color-white, 35%);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.3rem;
        margin: 20px 0 10px 14px;
    }
    @include MQ(L) {
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        text-align: center;
        background-color: transparent;

        &::before {
            content: 'desktop';
        }

        .nav-trigger {
            display: none;
        }

        .main-nav {
            display: flex;
        }

        &.is-dropdown-visible .main-nav > ul {
            > li > a {
                /* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
                opacity: 0.6;
            }
            > li.active > a {
                opacity: 1;
            }
        }

        .morph-dropdown-wrapper {
            /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
            display: block;
            top: 58px;
            /* overwrite mobile style */
            width: auto;
            padding: 0;
            background-color: transparent;
            box-shadow: none;
            transition: transform 0.3s;
            /* Force Hardware acceleration */
            transform: translateZ(0);
            transform: translateY(20px);
            will-change: transform;
        }

        &.is-dropdown-visible .morph-dropdown-wrapper {
            transform: translateY(0);
        }

        .dropdown-list {
            visibility: hidden;
            position: absolute;
            top: 5px;
            box-shadow: 0 10px 20px rgba(#000, .08);
            transition: visibility 0.3s;
            transform: translateZ(0);
            will-change: transform, width, height;

            a {
                display: block;
                font-family: 'Gotham-Book';
                font-size: 12px;
                letter-spacing: 0;
                color: $color-grey-mineshaft;
                line-height: 20px;

                &:hover {
                    color: $color-secondary;
                }
            }

            .no-csstransitions & {
                display: none;
            }

            &-arrow {
                /* dropdown top triangle */
                content: '';
                position: absolute;
                bottom: 100%;
                width: 0;
                height: 0;
                opacity: 0;
                border: 8px solid transparent;
                border-bottom-color: $color-white;
                transition: opacity 0.3s;
                @include center(x);
            }

            > ul {
                overflow: hidden;
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                margin-top: 0;
            }
        }

        &.is-dropdown-visible .dropdown-list {
            visibility: visible;
            transition: transform 0.3s, width 0.3s, height 0.3s;

            &-arrow {
                opacity: 1;
            }
        }

        .dropdown {
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            transition: opacity 0.3s, visibility 0.3s;

            &.active {
                visibility: visible;
                opacity: 1;
            }

            &.move-left .content {
                transform: translateX(-100px);
            }

            &.move-right .content {
                transform: translateX(100px);
            }
        }

        .content {
            width: 450px;
            padding: 30px;
            text-align: left;
            color: $color-grey-mineshaft;
            background-color: $color-white;
            transition: transform 0.3s;

            &-title {
                font-family: 'Gotham-Bold';
                font-size: 14px;
                letter-spacing: 0;
            }

            > ul {
                width: auto;
                @include clearfix;
            }
        }

        .bg-layer {
            /* morph dropdown background */
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 1px;
            opacity: 0;
            background: $color-white;
            transition: opacity 0.3s;
            transform: translateZ(0);
            transform-origin: top left;
            will-change: transform;
            backface-visibility: hidden;
        }

        &.is-dropdown-visible .bg-layer {
            opacity: 1;
            transition: transform 0.3s, opacity 0.3s;
        }
    }
}
