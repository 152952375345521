.ecosub {
    &-header {
        display: flex;
        align-items: center;
        margin-bottom: $size-default-md;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: $color-grey-mineshaft;

        img {
            width: 175px;
            margin-right: 40px;
        }
    }

    &-actions {
        padding-left: 0;
        list-style-type: none;

        &-action {
            display: flex;
            justify-content: space-between;
            padding-right: 55px;
            background-color: rgba($color-primary, 0.05);
            border-color: rgba($color-primary, 0.1);

            .cromis-body & {
                background-color: rgba($color-cromis-primary, 0.05);
                border-color: rgba($color-cromis-primary, 0.1);
            }

            &--disabled {
                background-color: rgba($color-primary, 0.025);
                border-color: rgba($color-primary, 0.05);

                .cromis-body & {
                    background-color: rgba($color-cromis-primary, 0.025);
                    border-color: rgba($color-cromis-primary, 0.05);
                }

                .ecosub-actions-action {
                    &-content {
                        &-title {
                            opacity: 0.5;
                        }
                    }
                }
            }

            &-content {
                strong {
                    display: inline-block;
                    margin-right: $size-default-md;
                    width: 22px;
                    color: $color-primary;

                    .cromis-body & {
                        color: $color-cromis-primary;
                    }
                }

                a {
                    color: $color-primary;

                    .cromis-body & {
                        color: $color-cromis-primary;
                    }
                }
            }

            &-icon {
                position: absolute;
                right: 10px;
                top: 10px;
                display: block;
                width: 25px;
                height: 25px;
                margin-bottom: 1px;
                text-align: center;
                line-height: 27px;

                &--delete {
                    background-color: rgba($color-red-alizarin, 0.75);
                    color: $color-white !important;
                }

                &--activate,
                &--desactivate {
                    font-size: 22px;
                    line-height: 30px;
                    color: $color-primary !important;

                    .cromis-body & {
                        color: $color-cromis-primary !important;
                    }
                }
            }
        }
    }

    &-table {
        background-color: rgba($color-primary, 0.05);

        .cromis-body & {
            background-color: rgba($color-cromis-primary, 0.05);
        }

        tr {
            &:last-of-type {
                td {
                    border-bottom: 1px solid rgba($color-primary, 0.1);

                    .cromis-body & {
                        border-bottom: 1px solid rgba($color-cromis-primary, 0.1);
                    }
                }
            }
        }

        th {
            color: $color-primary;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        td {
            width: 50%;
            border-color: rgba($color-primary, 0.1);
            color: $color-primary;

            .cromis-body & {
                border-color: rgba($color-cromis-primary, 0.1);
                color: $color-cromis-primary;
            }

            ul {
                margin: 0;
                padding: 0 0 0 12px;
                font-size: 90%;

                &.ul--no-style {
                    list-style-type: none;
                    padding: 0;
                }
            }
        }
    }

    &-badge {
        display: inline-block;
        max-width: 100%;
        font-size: 80%;
        background-color: $color-primary;
        color: $color-white !important;
        padding: 7px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .cromis-body & {
            background-color: $color-cromis-primary;
        }
    }

    &-form {
        font-size: 14px;

        &-instructions {
            margin-bottom: $size-default-md;
            text-align: right;
            font-size: 14px;
        }

        &-title {
            margin-bottom: $size-default-lg;
            font-family: 'Gotham-Bold';
            font-size: 24px;
            font-weight: 600;
            line-height: 50px;
            color: $color-primary;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        &-subtitle {
            margin-bottom: $size-default-lg;
            font-family: 'Gotham-Bold';
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: $color-primary;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        &-submit {
            margin-top: $size-default-xl;
            text-align: right;

            & > *:last-child {
                margin-left: $size-default-lg;
                cursor: pointer;
            }
        }
    }
}
