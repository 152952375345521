.footer {
    font-size: 12px;
    color: $color-white;
    background-color: $color-primary;

    .cromis-body & {
        background-color: $color-cromis-primary;

        position: relative;
        z-index: 1;
    }

    @include responsive-md() {
        img {
            width: 100%;
            max-width: 125px;
        }
    }

    &-infos {
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        padding: 30px 0 50px;
        background-color: $color-primary;

        .cromis-body & {
            background-color: $color-cromis-primary;
        }

        @include responsive-lg() {
            display: block;
            padding: 30px 0 50px;
        }
        @include responsive-md() {
            padding: 30px 0 50px;
        }

        &-networks {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            a {
                margin-right: 45px;
                @include responsive-lg {
                    margin-right: 30px;
                }
            }
            @include responsive-md() {
                margin-top: 30px;
            }
        }
    }

    &-nav {
        padding: 30px 100px 50px;
        line-height: 1.3;
        background-color: $color-blue-orient;

        .cromis-body & {
            background-color: $color-green-niagara;
        }

        @include responsive-xl() {
            padding: 30px 50px 50px;
        }
        @include responsive-md() {
            padding: 10px 15px;
        }

        &-element {
            margin-bottom: $size-default-lg;
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                span {
                    display: block;
                    font-family: 'Gotham-Bold';
                    font-size: 16px;
                }

                ul {
                    li {
                        margin: 10px 0;
                        padding-right: 15px;

                        a {
                            color: $color-white;
                        }
                    }
                }
            }
        }

        ul.submenu-last {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                width: 33%;
                margin: 10px 0;
                font-size: 13px;

                span {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
