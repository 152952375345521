.loginPage {
    position: absolute;
    width: 100%;
    height: 90%;
    background-image: url('/img/img.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: initial;
}

.login {
    margin: 65px 0;

    &-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
        width: 100%;
    }

    &-form {
        width: 325px;
    }

    &-title {
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color-grey-light;
        background-color: $color-grey-gallery-alt;
        cursor: pointer;

        .active {
            color: $color-primary;
            background-color: $color-white;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        &-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            margin-bottom: 0;
            padding: 40px 0;
        }
    }

    &-content {
        display: none;
        align-items: center;
        padding: 30px 10px;
        background-color: $color-white;

        &.active {
            display: flex;
        }

        input {
            display: block;
            width: 100%;
        }

        .checkbox {
            display: inline-block;
            width: auto;
            height: auto;
        }

        a {
            text-align: right;
            color: $color-grey-dovegray;

            &.login-help-link {
                color: $color-primary;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }
        }

        &-footer {
            align-items: center;

            label {
                margin: 0;
                color: $color-grey-dovegray;
            }
        }
    }

    &-remember-me {
        margin-bottom: .75rem !important;

        label {
            cursor: pointer;
        }
    }

    &-button {
        width: 100%;
        cursor: pointer;
    }

    &-forgot {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }

    .ssoLogin {
        padding: 10px 20px;
        color: white;
        height: auto;
        min-height: 40px;
        width: auto;
        margin: auto auto 20px auto;
        display: block;
        text-align: center;

        i, small {
            display: none;
        }

        &--loading {
            cursor: initial !important;
            i {
                display: inline-block;
            }

            small {
                display: block;
            }
        }
    }
}
