&-useredit {
    /////////////////////////////////////////////////////////////////
    // PROFILE
    /////////////////////////////////////////////////////////////////
    &-profile {
        & > .row {
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-picture {
            margin-right: auto;
            margin-left: auto;

            @include media-breakpoint-down(md) {
                width: 200px;
                margin-bottom: $size-default-xxl;
            }

            &-inner {
                height: 242px;
                width: 242px;
                padding: $size-default-md;
                border-radius: 15px;
                background-color: transparent;
                background-color: $color-white;

                @include media-breakpoint-down(lg) {
                    height: 268px;
                    width: 268px;
                }
                @include media-breakpoint-down(md) {
                    height: 200px;
                    width: 200px;
                }
            }

            &-lorem {
                margin-top: $size-default-md;
                font-style: italic;
                text-align: center;
            }

            .divelog-picture {
                background-color: $color-white;
            }
        }
    }

    .diveSet-close {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: red;
        cursor: pointer;
    }

    .add_item_diveSet {
        color: greenyellow;
        cursor: pointer;
        font-size: 30px;
    }
}
