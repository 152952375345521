&-login {
    .divelog-container {
        max-width: 100% !important;
    }
    /////////////////////////////////////////////////////////////////
    // INTRO
    /////////////////////////////////////////////////////////////////
    &-intro {
        margin-bottom: $size-default-lg;
        padding: 0 100px;
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding: 0 50px;
        }
        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }

    /////////////////////////////////////////////////////////////////
    // FLEX
    /////////////////////////////////////////////////////////////////
    &-flex {
        display: flex;
        flex-flow: row nowrap;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &-left {
            flex: 1 0;
        }

        &-right {
            flex: 0 0 330px;
            margin-left: $size-default-lg;

            @include media-breakpoint-down(md) {
                flex-basis: auto;
                margin-left: 0;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // ASIDE
    /////////////////////////////////////////////////////////////////
    &-aside {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;
        padding: $size-default-lg;
        background-color: rgba($color-black, .3);

        @include media-breakpoint-down(xs) {
            padding: $size-default-md;
        }

        .title {
            margin-bottom: $size-default-lg;
            color: $color-white;
            font-family: $font-gotham-bold;
            font-size: 20px;
            line-height: 20px;

            @include media-breakpoint-down(xs) {
                margin-bottom: $size-default-md;
                font-size: 16px;
                line-height: 16px;
            }
        }

        p {
            margin-bottom: 0;

            & + p {
                margin-top: $size-default-md;
            }
        }

        button {
            width: 100%;
            margin-top: $size-default-lg;
            margin-bottom: $size-default-lg;
        }
    }

    /////////////////////////////////////////////////////////////////
    // PARTNERS
    /////////////////////////////////////////////////////////////////
    &-partners {
        margin-top: $size-default-lg;

        @include media-breakpoint-down(xs) {
            margin-top: $size-default-md;
        }

        &-title {
            margin-bottom: $size-default-md;
            color: $color-white;
            font-family: $font-gotham-bold;
            font-size: 16px;
            line-height: 28px;
        }

        &-images {
            margin-top: $size-default-lg;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
            @include media-breakpoint-down(xs) {
                margin-top: $size-default-md;
            }

            img {
                max-width: 300px;
                opacity: .9;

                @include media-breakpoint-down(xs) {
                    max-width: 260px;
                }
            }
        }
    }
}
