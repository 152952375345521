.ffessm_dropdown {
    position: relative;
    display: inline-block;

    a {
        &:hover {
            color: $color-red-alizarin !important;
        }
    }

    &--dark {
        .ffessm_dropdown {
            &-link {
                color: $color-white !important;
            }

            &-content {
                a {
                    color: $color-grey-mineshaft !important;

                    &:hover {
                        color: $color-red-alizarin !important;
                    }
                }
            }
        }
    }

    &:hover {
        .ffessm_dropdown {
            &-link {
                color: $color-red-alizarin;
            }

            &-content {
                display: block;
                cursor: pointer;
            }
        }
    }

    &-link {
        color: $color-grey-mineshaft;
        cursor: pointer;
    }

    &-content {
        position: absolute;
        top: 40px;
        right: -15px;
        z-index: 6;
        display: none;
        overflow-y: auto;
        max-height: 320px;
        min-width: 220px;
        box-shadow: 0 8px 16px 0 rgba($color-black, .2);
        background-color: $color-white;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: $color-grey-gallery;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-alto;
        }

        a {
            display: block !important;
            padding: 12px 16px;
            color: $color-grey-mineshaft;
            text-align: right;
            text-decoration: none;

            &:hover {
                color: $color-red-alizarin;
            }
        }
    }

    &-access {
        margin-left: 20px;

        &::after {
            content: "▼";
            position: absolute;
            margin-left: 20px;
            top: 18px;
            z-index: 1;
            text-align: center;
            height: 6%;
            pointer-events: none;
            font-size: 12px;
        }

        &-content {
            position: absolute;
            right: 2vw;
            z-index: 6;
            display: none;
            overflow-y: auto;
            max-height: max-content;
            min-width: 220px;
            box-shadow: 0 8px 16px 0 rgba($color-black, .2);
            background-color: $color-white;
            font-size: 13px;
            padding-top: 5px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: $color-grey-gallery;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-grey-alto;
            }

            a {
                display: block !important;
                padding: 2px 16px;
                color: $color-grey-mineshaft !important;
                text-align: right;
                text-decoration: none;
                line-height: 35px !important;

                &:hover {
                    color: $color-red-alizarin;
                }
            }
        }

        &-mobile {
            display: none;

            @include responsive-md {
                display: block;
                position: absolute;
                right: 10px;
                top: 5px;

                &::after {
                    content: "▼";
                    position: absolute;
                    text-align: center;
                    right: -5px;
                    top: 0;
                    z-index: 1;
                    width: 6%;
                    height: 6%;
                    pointer-events: none;
                    font-size: 12px;
                }

                &-content {
                    position: absolute;
                    top: 56px;
                    display: none;
                    right: -24px;
                    max-height: max-content;
                    min-width: 220px;
                    box-shadow: 0 8px 16px 0 rgba($color-black, .2);
                    background-color: $color-white;
                    font-size: 13px;
                    padding-top: 10px;
                    z-index: 6;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 10px;
                    }

                    &::-webkit-scrollbar-track {
                        background: $color-grey-gallery;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $color-grey-alto;
                    }

                    a {
                        display: block !important;
                        padding: 2px 16px;
                        color: $color-grey-mineshaft;
                        text-align: right;
                        text-decoration: none;
                        line-height: 35px;

                        &:hover {
                            color: $color-red-alizarin;
                        }
                    }
                }
            }
        }
    }
}
