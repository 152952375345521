.events {
    margin-top: 65px;
    @include responsive-md {
        margin-top: 30px;
    }

    &-calendar {
        min-height: 715px;

        @include responsive-lg {
            min-height: 523px;
        }
        @include responsive-md {
            min-height: 450px;
        }
        @include responsive-sm {
            min-height: 300px;
        }
    }

    &-aside {
        margin-top: 56px;

        @include responsive-md {
            margin-top: $size-default-lg;
        }
    }

    &-item {
        position: relative;
        padding-bottom: $size-default-xxxl;
        @include responsive-md {
            padding-bottom: $size-default-lg;
        }

        &::after {
            content: '';
            border: 1px solid rgba($color-primary, 0.2);
            position: absolute;
            top: 0;
            bottom: 0;
            left: calc(50% - 1px);
            z-index: -1;

            .cromis-body & {
                border: 1px solid rgba($color-cromis-primary, 0.2);
            }

            @include responsive-lg {
                left: calc(32.55% - 1px);
            }
            @include responsive-md {
                left: 0;
            }
        }

        &--alt {
            .events-item {

                &-image {
                    padding-right: 0;
                    padding-left: $size-default-xxxl;
                    @include responsive-xl {
                        padding-left: $size-default-xl;
                    }
                    @include responsive-lg {
                        padding-right: $size-default-xl;
                        padding-left: 0;
                    }
                }

                &-calendar {
                    left: -20px;
                    right: inherit;
                    @include responsive-lg {
                        left: inherit;
                        right: -20px;
                    }
                }

                &-colcontent {
                    &::before {
                        left: inherit;
                        right: -17.5px;
                        @include responsive-lg {
                            left: -17.5px;
                            right: inherit;
                        }
                    }

                    &::after {
                        left: inherit;
                        right: -6px;
                        @include responsive-lg {
                            left: -6px;
                            right: inherit;
                        }
                    }
                }

                &-content {
                    margin-left: 0;
                    margin-right: $size-default-xxxl;
                    @include responsive-xl {
                        margin-right: $size-default-xl;
                    }
                    @include responsive-lg {
                        margin-right: 0;
                        margin-left: $size-default-xl;
                    }

                    &::after {
                        left: inherit;
                        right: -30px;
                        @include responsive-lg {
                            left: -30px;
                            right: inherit;
                        }
                    }
                }
            }
        }

        &-row {
            &-colimage {
                @include responsive-md {
                    display: none;
                }
            }
        }

        &-image {
            padding-right: $size-default-xxxl;
            @include responsive-xl {
                padding-right: $size-default-xl;
            }

            &-inner {
                position: relative;
            }
        }

        &-calendar {
            position: absolute;
            top: -20px;
            right: -20px;
            width: 105px;
            height: 103px;
            background-color: $color-white;
            z-index: 1;
            @include responsive-lg {
                width: 75px;
                height: 75px;
            }

            &--mobile {
                display: none;
                @include responsive-md {
                    display: block;
                    top: 0 !important;
                    right: 15px !important;
                    width: 40px;
                    height: 40px;

                    .events-item-calendar {
                        &-inner {
                            &::before {
                                display: none !important;
                            }

                            &::after {
                                display: none !important;
                            }
                        }
                    }
                }
            }

            &-inner {
                position: relative;
                height: 100%;
                width: 100%;
                padding: 15px;
                text-align: center;
                @include responsive-lg {
                    padding: 10px;
                }
                @include responsive-md {
                    padding: 5px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 20px;
                    width: 10px;
                    height: 15px;
                    background-color: $color-grey-gallery;
                    @include responsive-lg {
                        left: 15px;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    right: 20px;
                    width: 10px;
                    height: 15px;
                    background-color: $color-grey-gallery;
                    @include responsive-lg {
                        right: 15px;
                    }
                }
            }

            &-day {
                color: $color-primary;
                font-weight: bold;
                font-family: 'Gotham-Bold';
                font-size: 35px;

                .cromis-body & {
                    color: $color-cromis-primary;
                }

                @include responsive-lg {
                    font-size: 30px;
                    line-height: 42.5px;
                }
                @include responsive-md {
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            &-month {
                color: $color-primary;
                font-size: 11px;
                text-transform: uppercase;
                text-align: center;

                .cromis-body & {
                    color: $color-cromis-primary;
                }

                @include responsive-lg {
                    font-size: 9px;
                }
            }
        }

        &-colcontent {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -17.5px;
                width: 35px;
                height: 35px;
                background-color: $color-grey-wild-sand;
                @include responsive-md {
                    top: -17.5px;
                    left: 0 !important;
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 11px;
                left: -6px;
                width: 12px;
                height: 12px;
                border: 2px solid $color-primary;
                border-radius: 50%;
                background-color: $color-grey-wild-sand;

                .cromis-body & {
                    border: 2px solid $color-cromis-primary;
                }

                @include responsive-md {
                    top: -6px;
                    left: 10px !important;
                }
            }
        }

        &-content {
            position: relative;
            margin-left: $size-default-xxxl;
            background-color: $color-white;
            padding: 35px 40px;
            @include responsive-xl {
                margin-left: $size-default-xl;
            }
            @include responsive-md {
                margin-left: $size-default-lg !important;
                margin-right: 55px !important;
                padding: 15px 20px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: -30px;
                width: 0;
                height: 0;
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                border-top: 30px solid $color-white;
                @include responsive-md {
                    left: -15px !important;
                    border-left-width: 15px !important;
                    border-right-width: 15px !important;
                    border-top-width: 15px !important;
                }
            }

            &-title {
                line-height: 1.5;
            }
        }

        *.events-item-title {
            display: block;
            margin-bottom: 25px;
            font-family: 'Gotham-Bold';
            font-size: 24px;
            color: $color-grey-mineshaft;

            &:hover {
                color: $color-primary;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }
            
            @include responsive-lg {
                margin-bottom: 15px;
            }
            @include responsive-md {
                font-size: 18px;
            }
        }

        &-description {
            margin-bottom: 25px;
            @include responsive-lg {
                margin-bottom: 15px;
            }
            @include responsive-md {
                display: none;
            }
        }

        &-infos {
            display: flex;
            align-items: center;
        }
    }
}
