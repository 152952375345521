@font-face {
  font-family: 'bebas_neuebold';
  src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'), url('../fonts/bebasneue_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../fonts/Gotham-Bold.eot');
  src: url('../fonts/Gotham-Bold.woff') format('woff'), url('../fonts/Gotham-Bold.ttf') format('truetype'), url('../fonts/Gotham-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/Gotham-Book.eot');
  src: url('../fonts/Gotham-Book.woff') format('woff'), url('../fonts/Gotham-Book.ttf') format('truetype'), url('../fonts/Gotham-Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: flex;
	flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: $grey-900;
  color: $grey-100;
  font-family: $font-primary;
  font-size: 0.875rem;
  line-height: 1.4;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 77.5rem;
  margin: auto;
}
.portalContainer {
  padding: 0px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}