&-diveview {
    /////////////////////////////////////////////////////////////////
    // PROFILE
    /////////////////////////////////////////////////////////////////
    &-profile {
        position: relative;
        margin-top: 150px;

        @include media-breakpoint-down(xs) {
            margin-top: 100px;
            margin-bottom: 100px;
        }

        &-background {
            position: absolute;
            top: -650px;
            right: -390px;
            left: -390px;
            z-index: -2;

            @include media-breakpoint-down(lg) {
                top: -585px;
            }
            @include media-breakpoint-down(md) {
                top: -500px;
            }
            @include media-breakpoint-down(sm) {
                top: -465px;
            }
            @include media-breakpoint-down(xs) {
                top: -290px;
                right: inherit;
                left: 50%;
                width: 800px;
                transform: translateX(-50%);
            }

            img {
                width: 100%;
            }
        }

        &-content {
            position: relative;
            margin-bottom: 30px;

            @include media-breakpoint-down(xs) {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            &--scubadiving {
                height: 420px;

                @include media-breakpoint-down(lg) {
                    height: 380px;
                }
                @include media-breakpoint-down(md) {
                    height: 330px;
                }
                @include media-breakpoint-down(sm) {
                    height: 320px;
                }
                @include media-breakpoint-down(xs) {
                    height: 200px;
                }

                .divelog-fieldview-field {
                    &:nth-of-type(0n+1) {
                        top: 0;
                        left: 0;
                    }

                    &:nth-of-type(0n+2) {
                        top: 305px;
                        left: 325px;

                        @include media-breakpoint-down(lg) {
                            top: 265px;
                            left: 245px;
                        }
                        @include media-breakpoint-down(md) {
                            top: 227px;
                            left: 155px;
                        }
                        @include media-breakpoint-down(sm) {
                            top: 215px;
                            left: 75px;
                        }
                    }

                    &:nth-of-type(0n+3) {
                        top: 0;
                        right: 10px;
                    }

                    &:nth-of-type(0n+4) {
                        top: 80px;
                        right: 0;

                        @include media-breakpoint-down(lg) {
                            top: 70px;
                        }
                    }
                }
            }

            &--freediving {
                height: 250px;

                @include media-breakpoint-down(lg) {
                    height: 225px;
                }
                @include media-breakpoint-down(md) {
                    height: 200px;
                }
                @include media-breakpoint-down(xs) {
                    height: 200px;
                }

                .divelog-fieldview-field {
                    &:nth-of-type(0n+1) {
                        top: 0;
                        left: 0;
                    }

                    &:nth-of-type(0n+2) {
                        top: 0;
                        right: 10px;
                    }

                    &:nth-of-type(0n+3) {
                        top: 70px;
                        right: 75px;

                        @include media-breakpoint-down(lg) {
                            top: 60px;
                            right: 390px;
                        }
                        @include media-breakpoint-down(md) {
                            top: 40px;
                            right: 260px;
                        }
                        @include media-breakpoint-down(sm) {
                            right: 225px;
                        }
                    }
                }
            }

            &--snorkeling {
                height: 190px;

                @include media-breakpoint-down(md) {
                    height: 175px;
                }

                .divelog-fieldview-field {
                    &:nth-of-type(0n+1) {
                        top: -20px;
                        left: 0;

                        @include media-breakpoint-down(md) {
                            top: -15px;
                        }
                        @include media-breakpoint-down(sm) {
                            top: -10px;
                        }
                    }

                    &:nth-of-type(0n+2) {
                        top: -20px;
                        right: 10px;

                        @include media-breakpoint-down(md) {
                            top: -15px;
                        }
                        @include media-breakpoint-down(sm) {
                            top: -10px;
                        }
                    }

                    &:nth-of-type(0n+3) {
                        top: 70px;
                        right: 0;

                        @include media-breakpoint-down(md) {
                            top: 75px;
                            right: 220px;
                        }
                        @include media-breakpoint-down(sm) {
                            right: 175px;
                        }
                    }
                }
            }

            .divelog-fieldview-field {
                position: absolute;

                @include media-breakpoint-down(xs) {
                    position: static;
                    width: 50%;
                    margin-bottom: 15px;
                }

                &-value {
                    font-family: $font-gotham-bold;
                    font-size: 23px;
                    line-height: 23px;

                    @include media-breakpoint-down(md) {
                        line-height: 15px;
                    }
                }
            }
        }

        &-category {
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }

            &:last-of-type {
                margin-bottom: 150px;
            }

            &-content {
                display: flex;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }
                @include media-breakpoint-down(xs) {
                    width: calc(100% - 75px);
                }
            }

            &-fields {
                display: flex;

                @include media-breakpoint-down(xs) {
                    flex-wrap: wrap;
                }
            }

            &-image {
                img {
                    display: block;
                    max-height: 75px;
                    max-width: 75px;

                    @include media-breakpoint-down(sm) {
                        max-height: 100px;
                        max-width: 100px;
                    }
                    @include media-breakpoint-down(xs) {
                        max-height: 75px;
                        max-width: 75px;
                    }
                }
            }

            &-label {
                display: flex;
                align-items: center;
                height: 36px;
                width: 150px;
                margin-top: 20px;
                margin-left: 30px;
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;

                @include media-breakpoint-down(sm) {
                    height: inherit;
                    width: 100%;
                    margin-top: 10px;
                }
                @include media-breakpoint-down(xs) {
                    margin-top: 5px;
                }
            }

            .divelog-fieldview-field {
                margin-top: 20px;
                margin-left: 30px;

                @include media-breakpoint-down(sm) {
                    margin-top: 15px;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // PROFILE
    /////////////////////////////////////////////////////////////////
    &-stages {
        &-content {
            display: flex;
            flex-flow: column nowrap;

            &-item {
                & + & {
                    margin-top: $size-default-md;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // EQUIPMENT
    /////////////////////////////////////////////////////////////////
    &-equipment {
        position: relative;
        margin-top: 100px;

        &-background {
            position: absolute;
            top: -50px;
            bottom: 0;
            left: 50%;
            z-index: -2;
            transform: translate(-50%);

            img {
                height: 100%;
            }
        }

        &-content {
            display: flex;
            justify-content: space-between;
            min-height: 330px;

            @include media-breakpoint-down(sm) {
                min-height: 260px;
            }
        }

        &-left {
            width: 215px;

            @include media-breakpoint-down(xs) {
                width: calc(50% - 7.5px);
                margin-right: 7.5px;
            }
        }

        &-right {
            width: 215px;

            @include media-breakpoint-down(xs) {
                width: calc(50% - 7.5px);
                margin-left: 7.5px;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // SPECIES
    /////////////////////////////////////////////////////////////////
    &-species {
        .divelog-fieldview-comment {
            margin-bottom: $size-default-lg;

            @include media-breakpoint-down(xs) {
                margin-bottom: $size-default-md;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // PHOTOS
    /////////////////////////////////////////////////////////////////
    &-photos {
        &-content {
            display: flex;
            margin-right: -15px;
            margin-left: -15px;

            @include media-breakpoint-down(sm) {
                margin-right: -7.5px;
                margin-left: -7.5px;
            }
        }

        &-medias {
            width: calc(50% - 15px);
            margin-right: 15px;
            margin-left: 15px;

            @include media-breakpoint-down(sm) {
                width: calc(50% - 7.5px);
                margin-right: 7.5px;
                margin-left: 7.5px;
            }

            &-inner {
                display: flex;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;

                @include media-breakpoint-down(sm) {
                    margin-right: -7.5px;
                    margin-left: -7.5px;
                }
            }
        }

        .divelog-media {
            display: block;
            width: calc(50% - (60px / 2));
            margin-right: 15px;
            margin-bottom: 30px;
            margin-left: 15px;

            @include media-breakpoint-down(sm) {
                width: calc(50% - (30px / 2));
                margin-right: 7.5px;
                margin-bottom: 15px;
                margin-left: 7.5px;
            }
            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            .divleog-picture {
                height: 180px;
            }

            &-label {
                margin-top: 10px;
                color: $color-white;
            }
        }

        .popin {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            display: block;
            background-color: rgba($color-black, .5);

            &.hidden {
                background-color: transparent;
                transition: background-color .5s ease 0s;
            }

            &.loading {
                animation: loading 2s infinite 1s;
                transition: background-color .5s ease 0s;
            }

            &.hidden,
            &.loading {
                .content {
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity .5s ease 0s, visibility 0s ease .5s;
                }
            }

            &-close {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                height: 100px;
                width: 100px;
                padding: 20px;
                color: rgba($color-white, .5);
                font-size: 68px;
                font-weight: 700;
                line-height: 75px;
                cursor: pointer;
                transition: color .2s ease;

                &:hover {
                    color: $color-white;
                    transition: color .2s ease;
                }
            }

            .content {
                position: relative;
                height: 100%;
                width: 100%;
                visibility: visible;
                opacity: 1;
                transition: opacity .5s ease 0s, visibility 0s ease 0s;
            }

            .slide {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                max-height: 100%;
                width: 100%;
                max-width: inherit;

                &--video {
                    .slide-inner {
                        height: unset !important;
                        width: calc(100% - 20px);
                        max-width: calc(56.25%) !important;

                        @include media-breakpoint-down(md) {
                            max-width: calc(67%) !important;
                        }
                        @include media-breakpoint-down(sm) {
                            max-width: calc(60%) !important;
                        }
                        @include media-breakpoint-down(xs) {
                            max-width: calc(63%) !important;
                        }
                    }
                }

                .slide-inner {
                    display: inline-block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: calc(100% - 200px);
                    max-width: calc(100% - 400px);

                    @include media-breakpoint-down(md) {
                        max-width: calc(100% - 250px);
                    }
                    @include media-breakpoint-down(sm) {
                        max-width: calc(100% - 225px);
                    }
                    @include media-breakpoint-down(xs) {
                        max-width: calc(100% - 120px);
                    }
                }

                iframe,
                img {
                    height: unset;
                    max-height: 100%;
                    width: unset;
                    max-width: 100%;
                }

                .iframe-wrapper {
                    position: relative;
                    height: 0;
                    width: 100%;
                    padding-bottom: 56.25%;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .owl-carousel {
                position: relative;
                height: 100%;
                width: 100%;

                .owl-stage-outer {
                    height: 100%;
                    width: 100%;

                    .owl-stage {
                        height: 100%;
                    }
                }

                .owl-item {
                    height: 100%;
                    width: calc(100vw - 15px) !important;

                    @include media-breakpoint-down(sm) {
                        width: calc(100vw) !important;
                    }
                }

                .owl-next,
                .owl-prev {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 100px;
                    background-color: transparent;
                    transition: background-color .2s ease;

                    @include media-breakpoint-down(md) {
                        width: 75px;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 60px;
                    }
                    @include media-breakpoint-down(xs) {
                        width: 40px;
                    }

                    &:hover {
                        background-color: rgba($color-black, .5);
                        transition: background-color .2s ease;

                        span {
                            color: $color-white;
                            transition: color .2s ease;
                        }
                    }

                    span {
                        color: rgba($color-white, .3);
                        font-size: 150px;
                        transition: color .2s ease;

                        @include media-breakpoint-down(md) {
                            font-size: 115px;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 100px;
                        }
                        @include media-breakpoint-down(xs) {
                            font-size: 70px;
                        }
                    }
                }

                .owl-dots,
                .owl-nav {
                    &.disabled {
                        display: block;
                    }
                }

                // .owl-nav {
                // &:after {
                //     content: '✕';
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     height: 100px;
                //     width: 100px;
                //     padding: 20px;
                //     color: #fff;
                //     // opacity: .5;
                //     font-size: 68px;
                //     font-weight: 700;
                //     line-height: 75px;
                //     // transition: opacity 0.2s ease;
                //     cursor: pointer;
                //
                //     //     &:hover {
                //     //         opacity: 1;
                //     //         transition: opacity 0.2s ease;
                //     //     }
                // }
                // }

                .owl-prev {
                    left: 0;
                }

                .owl-next {
                    right: 0;
                }

                .owl-dots {
                    position: absolute;
                    bottom: 35px;
                    left: 50%;
                    margin-bottom: 0;
                    text-align: center;
                    transform: translateX(-50%);

                    .owl-dot {
                        height: 20px;
                        width: 20px;
                        margin-right: 15px;
                        margin-left: 15px;
                        border: 3px solid $color-white;
                        border-radius: 50%;
                        background-color: transparent;
                        opacity: .5;
                        transition: $opacity-transition;

                        @include media-breakpoint-down(sm) {
                            height: 15px;
                            width: 15px;
                            margin-right: 10px;
                            margin-left: 10px;
                            border-width: 2px;
                        }
                        @include media-breakpoint-down(xs) {
                            margin-right: 7.5px;
                            margin-bottom: 10px;
                            margin-left: 7.5px;
                        }

                        &:hover {
                            opacity: .7;
                            transition: $opacity-transition;
                        }

                        &.active {
                            background-color: $color-white;
                            opacity: 1;
                            transition: $opacity-transition;
                        }
                    }
                }
            }
        }
    }
}

&-label {
    &-pending {
        color: gold;

        &.export {
            font-size: 1.2rem;
            color: goldenrod;
        }
    }

    &-validated {
        color: lightgreen;

        &.export {
            font-size: 1.2rem;
            color: green;
        }
    }

    &-invalidated {
        color: red;

        &.export {
            font-size: 1.2rem;
        }
    }
}
