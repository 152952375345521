.oneclickmenu {
    position: absolute;
    top: 0;
    right: -200px;
    width: 300px;
    height: 600px;
    background-color: $color-primary;
    transition: all ease-out 0.3s;

    .cromis-body & {
        background-color: $color-cromis-primary;
    }

    @include responsive-xl {
        height: 450px;
    }
    @include responsive-md() {
        display: none;
    }

    &:hover {
        right: 0;
        z-index: 2;

        ul {
            padding-left: 15px;

            li {
                a {
                    display: block;
                    right: 30px;
                    width: 100%;
                    padding-left: 95px;
                    font-family: 'Gotham-Book';
                    font-size: 16px;
                    color: $color-white;
                }
            }
        }

        span {
            margin-left: 190px;
        }
    }

    &-title {
        display: inline-block;
        font-family: 'bebas_neuebold';
        font-size: 24px;
        line-height: 12;
        letter-spacing: 4px;
        text-transform: uppercase;
        color: $color-white;
        transition: all ease-out 0.3s;
        transform: rotate(90deg);
        @include responsive-xl {
            line-height: 8;
        }
        @include responsive-md {
            width: 200px;
        }
    }

    ul {
        margin-top: -45px;
        padding-left: 35px;
        transition: all ease-out 0.3s;
        list-style: none;
        @include responsive-xl {
            margin-top: 0;
        }

        li {
            display: flex;
            position: relative;
            margin: 20px 0;
            @include responsive-xl {
                margin: 10px 0;
            }

            a {
                position: absolute;
                right: -200px;
                color: $color-primary;
                transition: all ease-out 0.3s 0.3s;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &-icon {
        width: 30px;
        height: 24px;
        margin-right: 30px;
        text-align: center;

        img {
            max-width: 30px;
            max-height: 24px;
        }
    }
}

.oneclickmenu-responsive {
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 50px;
    height: 250px;
    background-color: $color-primary;
    transition: all 0.3s;

    .cromis-body & {
        background-color: $color-cromis-primary;
    }

    @include responsive-md() {
        display: block;
    }

    ul {
        display: none;
    }

    .oneclickmenu-title {
        width: 100px;
        margin: 200px 0 0 35px;
        font-size: 18px;
        line-height: 1;
        transform: rotate(-90deg);
        transform-origin: bottom left;
    }

    &-close {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &--opened {
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;

        .oneclickmenu-responsive-close {
            display: block;
        }

        ul {
            display: block;
            position: absolute;
            top: 125px;
            width: 100%;
            margin-top: -45px;
            padding-left: 35px;
            transition: all ease-out 0.3s;
            list-style: none;

            li {
                display: flex;
                position: relative;
                margin: 20px 0;

                a {
                    display: block;
                    position: absolute;
                    right: 30px;
                    left: 55px;
                    width: 100%;
                    font-family: 'Gotham-Book';
                    font-size: 16px;
                    color: $color-white;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &-icon {
            width: 30px;
            margin-right: 30px;
            text-align: center;

            img {
                max-width: 30px;
                max-height: 30px;
            }
        }

        .oneclickmenu-title {
            position: absolute;
            right: -90px;
        }
    }
}
