/////////////////////////////////////////////////////////////////
// BACKGROUND
/////////////////////////////////////////////////////////////////

&-background {
    position: absolute;
    top: 0;
    right: -15px;
    bottom: 0;
    left: -15px;
    z-index: -2;
    background-color: $color-blue-cobalt;
    background-image: url('/img/divelog/bg.png');

    .cromis-body & {
        background-color: $color-green-niagara;
        background-image: none;
    }
}
/////////////////////////////////////////////////////////////////
// BACKGROUND BOTTOM
/////////////////////////////////////////////////////////////////

&-backgroundbottom {
    position: absolute;
    right: -300px;
    bottom: 0;
    left: -300px;
    z-index: -1;
    user-select: none;

    img {
        width: 100%;
    }
}
/////////////////////////////////////////////////////////////////
// BACKGROUND TOP
/////////////////////////////////////////////////////////////////

&-backgroundtop {
    position: absolute;
    right: -300px;
    left: -300px;
    z-index: -1;
    user-select: none;

    img {
        width: 100%;
    }
}
/////////////////////////////////////////////////////////////////
// COL
/////////////////////////////////////////////////////////////////

&-col-4,
&-col-6 {
    &--padded-left {
        padding-right: 70px;

        @include media-breakpoint-down(md) {
            padding-right: 0;
        }
    }

    &--padded-both {
        padding-right: 35px;
        padding-left: 35px;

        @include media-breakpoint-down(md) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &--padded-right {
        padding-left: 70px;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }
}
/////////////////////////////////////////////////////////////////
// CONTAINER
/////////////////////////////////////////////////////////////////

&-container {
    padding-top: 200px;
    padding-bottom: 530px;

    @include media-breakpoint-down(md) {
        padding-bottom: 400px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 150px !important;
        padding-bottom: 350px;
    }
    @include media-breakpoint-down(xs) {
        padding-bottom: 250px;
    }
}

&--login {
    .divelog-container {
        padding-bottom: 100px;

        @include media-breakpoint-down(md) {
            padding-bottom: 100px;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 150px !important;
            padding-bottom: 100px;
        }
        @include media-breakpoint-down(xs) {
            padding-bottom: 100px;
        }
    }
}
/////////////////////////////////////////////////////////////////
// CONTENT
/////////////////////////////////////////////////////////////////

&-content {
    display: flex;
    // TODO Update the pictures and remove this ?
    min-height: 640px;

    & > * {
        width: 100%;
    }
}

&--login {
    .divelog-content {
        min-height: auto;
    }
}
/////////////////////////////////////////////////////////////////
// FLASH
/////////////////////////////////////////////////////////////////

&-flash {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    margin-right: -15px;
    margin-left: -15px;
    font-size: 18px;
    font-weight: 600;

    &--modal {
        height: inherit;
        margin-right: 0;
        margin-left: 0;
        background-color: transparent !important;

        &.divelog-flash {
            &--success {
                color: $color-green-forest !important;
            }

            &--error,
            &--warning {
                color: $color-red-alizarin-crimson !important;
            }
        }

        .divelog-flash {
            &-cross {
                display: none;
            }
        }
    }

    &--success {
        background-color: $color-green-forest;
    }

    &--error,
    &--warning {
        background-color: $color-red-alizarin-crimson;
    }

    &.hidden {
        height: 0;
        transition: height .5s ease;

        & > * {
            opacity: 0;
            transition: opacity .125s ease;
        }
    }

    &-messages {
        @include media-breakpoint-down(xs) {
            flex-basis: 240px;
            margin-left: -40px;
        }
    }

    &-cross {
        position: absolute;
        right: 20px;
        opacity: .7;
        cursor: pointer;
        transition: $opacity-transition;

        &:hover {
            opacity: 1;
            transition: $opacity-transition;
        }

        img {
            width: 25px;
        }
    }
}
/////////////////////////////////////////////////////////////////
// FOOTER
/////////////////////////////////////////////////////////////////

&-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;

    @include media-breakpoint-down(xs) {
        flex-direction: column-reverse;
        margin-top: 40px;
    }

    &--center {
        justify-content: center;

        & > * + * {
            margin-left: 30px;

            @include media-breakpoint-down(md) {
                margin-left: 20px;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 10px;
            }
            @include media-breakpoint-down(xs) {
                margin-bottom: 15px;
                margin-left: 0;
            }
        }
    }

    &-left {
        & > * + * {
            margin-left: 30px;

            @include media-breakpoint-down(md) {
                margin-left: 20px;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 10px;
            }
        }
    }

    &-right {
        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }

        & > * + * {
            margin-left: 30px !important;

            @include media-breakpoint-down(md) {
                margin-left: 20px !important;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 10px !important;
            }
        }

        .form-group {
            display: inline;
            margin: 0;
        }

        button {
            cursor: pointer;
        }
    }

    .ffessm-button {
        @include media-breakpoint-down(md) {
            padding: 8px 15px;
            font-size: 13px !important;
            letter-spacing: 3px;
        }
    }
}
/////////////////////////////////////////////////////////////////
// HEADER
/////////////////////////////////////////////////////////////////

&-header {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    margin-top: 50px;
    margin-right: 40px;
    margin-left: 40px;

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
        margin-right: 0;
        margin-left: 0;
    }
    @include media-breakpoint-down(sm) {
        align-items: center;
    }

    &-flexer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &-left {
        display: flex;
        align-items: center;

        svg {
            display: none;

            @include media-breakpoint-down(sm) {
                display: block;
                margin-right: 20px;
            }

            &:hover {
                @include media-breakpoint-down(sm) {
                    opacity: .7;
                    cursor: pointer;
                    transition: $opacity-transition;
                }
            }
        }
    }

    &-logo {
        z-index: 14;
        display: block;
        height: 65px;
        max-width: 300px;

        @include media-breakpoint-down(md) {
            height: 40px;
        }
        @include media-breakpoint-down(sm) {
            height: 27px;
        }

        &--category {
            height: 95px;
            margin-top: -20px;
        }

        img {
            height: 100%;
        }
    }

    &-menu {
        &--mobile {
            display: flex;
            justify-content: center;
            margin-top: 25px;

            & > * + * {
                @include media-breakpoint-down(sm) {
                    margin-left: 20px !important;
                }
                @include media-breakpoint-down(xs) {
                    margin-left: 10px !important;
                }
            }

            a.ffessm-button,
            div.ffessm-button {
                @include media-breakpoint-down(sm) {
                    padding: 10px 20px !important;
                    font-size: 16px !important;
                    letter-spacing: 3px !important;
                }
                @include media-breakpoint-down(xs) {
                    height: 39px;
                    padding: 8px 15px !important;
                    font-size: 13px !important;
                }
            }
        }

        & > * + * {
            margin-left: 30px;

            @include media-breakpoint-down(lg) {
                margin-left: 20px;
            }
            @include media-breakpoint-down(md) {
                margin-left: 10px;
            }
        }

        .ffessm-button {
            @include media-breakpoint-down(lg) {
                padding: 10px 20px;
                font-size: 16px !important;
                letter-spacing: 3px;
            }
            @include media-breakpoint-down(md) {
                padding: 8px 15px;
                font-size: 13px !important;
                letter-spacing: 3px;
            }
        }
    }

    &-ad {
        margin-left: 50px;

        img {
            max-width: 750px;
        }
    }

    &-view {
        display: flex;
        align-items: center;

        &-date {
            margin-top: 15px;
            margin-right: 40px;
            font-family: $font-gotham-bold;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;

            @include media-breakpoint-down(xs) {
                position: absolute;
                bottom: 15px;
                left: 0;
                font-size: 14px;
                line-height: 18px;
            }
        }

        &-number {
            &-label {
                font-family: $font-gotham-bold;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }

            &-value {
                font-family: $font-gotham-bold;
                font-size: 66px;
                font-weight: 500;
                line-height: 50px;

                @include media-breakpoint-down(xs) {
                    font-size: 40px;
                    line-height: 28px;
                    text-align: right;
                }
            }
        }
    }
}
/////////////////////////////////////////////////////////////////
// INNER
/////////////////////////////////////////////////////////////////

&-inner {
    @media screen and (min-width: 1430px) {
        max-width: 1200px;
        margin-left: auto;
    }
    @include media-breakpoint-down(xl) {
        max-width: 1060px;
        margin-left: auto;
    }
    @include media-breakpoint-down(lg) {
        max-width: 865px;
    }
    @include media-breakpoint-down(md) {
        max-width: 635px;
    }
}
/////////////////////////////////////////////////////////////////
// MODAL
/////////////////////////////////////////////////////////////////

.modal {
    &-dialog {
        max-width: 1140px;
        border: 0;

        @include media-breakpoint-down(lg) {
            max-width: calc(100vw - (1.75rem * 2));
        }
        @include media-breakpoint-down(xs) {
            max-width: calc(100vw - (.5rem * 2));
        }
    }

    &-content {
        border: 0;
        border-radius: 5px;
        background-color: $color-blue-cobalt;

        .cromis-body & {
            background-color: $color-green-niagara;
        }
    }

    &-blocker {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        visibility: hidden;
        background-color: rgba($color-white, .5);
        opacity: 0;
        pointer-events: none;
        transition: $opacity-transition;
        transition: opacity .5s ease 0s, visibility 0s ease .5s;

        &.visible {
            visibility: visible;
            opacity: 1;
            transition: opacity .5s ease 0s, visibility 0s ease 0s;
        }
    }

    &-header {
        border-bottom: 0;

        .close {
            text-shadow: 0 1px 0 $color-black;

            span {
                color: $color-white;
            }
        }
    }

    &-body {
        padding: 0 50px 40px;

        @include media-breakpoint-down(xs) {
            padding: 0 15px 40px;
        }
    }

    &-title {
        color: $color-white;
        font-family: $font-gotham-bold;
        font-size: 20px;
    }
}
/////////////////////////////////////////////////////////////////
// SIDEBAR
/////////////////////////////////////////////////////////////////

&-sidebar {
    &--showing {
        @include media-breakpoint-down(xs) {
            animation-duration: .3s;
            animation-name: fadeIn;
        }
    }
    &--hiding {
        @include media-breakpoint-down(xs) {
            animation-duration: .3s;
            animation-name: fadeOut;
        }
    }
    &--visible {
        @include media-breakpoint-down(xs) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 3;
        }

        .divelog-sidebar {
            &-shadow {
                visibility: visible;
                opacity: 1;
                transition: .2s opacity 0s, 0s visibility 0s;

                @include media-breakpoint-down(xs) {
                    display: none;
                }
            }

            &-bar {
                width: 350px;
                box-shadow: 0 0 20px 0 rgba(2,2,3,.17);
                background-color: #13345d;
                transition: .5s width 0s, .3s background-color 0s, .3s box-shadow 0s, top .3s ease-out;

                .cromis-body & {
                    background-color: #0f423a;
                }

                @include media-breakpoint-down(sm) {
                    width: 350px;
                }
                @include media-breakpoint-down(xs) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    background: linear-gradient($color-blue-cobalt, $color-blue-zodiac);
                }
            }

            &-logo {
                visibility: visible;
                opacity: 1;
                transition: .2s opacity .3s, 0s visibility 0s;

                @include media-breakpoint-down(xs) {
                    position: static;
                    top: inherit;
                    left: inherit;
                }
            }

            &-list {
                @include media-breakpoint-down(xs) {
                    position: static;
                    top: inherit;
                    left: inherit;
                }
            }

            &-link {
                @include media-breakpoint-down(sm) {
                    visibility: visible;
                    opacity: 1;
                    transition: border-left-color .5s ease, .2s opacity .2s, 0s visibility .2s;
                }

                &:hover {
                    background-color: rgba(#0e2c50, .1);

                    .cromis-body & {
                        background-color: rgba(#12645c, .1);
                    }

                    @include media-breakpoint-down(xs) {
                        background-color: rgba($color-black, .5);
                    }
                }

                &.active {
                    background-color: #0e2c50;

                    .cromis-body & {
                        background-color: #12645c;
                    }

                    @include media-breakpoint-down(xs) {
                        background-color: rgba($color-black, .3);
                    }
                }
            }

            &-label {
                & > span {
                    visibility: visible;
                    opacity: 1;
                    transition: .2s opacity .2s, 0s visibility .2s;
                }
            }
        }
    }

    &-shadow {
        position: absolute;
        top: 0;
        right: -15px;
        bottom: 0;
        left: -15px;
        z-index: 11;
        visibility: hidden;
        background-color: rgba(16,19,22,.6);
        opacity: 0;
        transition: opacity .2s ease 0s, visibility 0s ease .2s;
    }

    &-bar {
        position: fixed;
        top: 120px;
        bottom: 0;
        left: 0;
        z-index: 11;
        width: 100px;
        box-shadow: none;
        background-color: transparent;
        transition: width .3s ease 0s, background-color .2s ease .1s, box-shadow .2s ease .1s, top .3s ease-out;

        @include media-breakpoint-down(sm) {
            width: 0;
        }
        @include media-breakpoint-down(xs) {
            display: none;
        }

        &--absolute {
            position: absolute;
            left: -15px;
            top: 0;
        }
    }

    &-logo {
        position: absolute;
        top: 0;
        left: 0;
        height: 65px;
        width: calc(100% - 110px);
        margin: 50px 55px;
        visibility: hidden;
        opacity: 0;
        transition: opacity .2s ease 0s, visibility 0s ease .2s;

        @include media-breakpoint-down(md) {
            height: 40px;
        }
        @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
            height: 27px;
            width: calc(100% - 34px);
            margin: 20px 17px;
        }
        @include media-breakpoint-down(xs) {
            display: flex;
            align-items: center;
            height: 80px;
            width: 100%;
            margin: 0 0 10px;
            padding: 20px;
        }

        svg {
            display: none;

            @include media-breakpoint-down(sm) {
                display: block;
                margin-right: 20px;
            }

            &:hover {
                @include media-breakpoint-down(sm) {
                    opacity: .7;
                    cursor: pointer;
                    transition: $opacity-transition;
                }
            }
        }

        img {
            height: 100%;
        }
    }

    &-list {
        position: absolute;
        width: 100%;
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;

        @include media-breakpoint-down(sm) {
            top: 67px;
        }

        &--bottom {
            top: unset !important;
            bottom: 0;
        }

        li {
            &:first-of-type {
                margin-top: 180px;

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }

            &:last-of-type {
                margin-bottom: 300px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        width: 100%;
        border-left: 5px solid transparent;
        color: $color-white !important;
        transition: border-left-color .5s ease;

        @include media-breakpoint-down(sm) {
            visibility: hidden;
            opacity: 0;
            transition: border-left-color .5s ease, .1s opacity 0s, 0s visibility .2s;
        }

        &:hover {
            border-left-color: rgba($color-yellow-laser-lemon, .7);
            font-weight: 600;
            transition: border-left-color .3s ease;

            .cromis-body & {
                border-left-color: rgba($color-blue-aquamarine, .7);
            }

            & > * {
                opacity: .7;
                transition: opacity .3s ease;
            }
        }

        &.active {
            border-left-color: $color-yellow-laser-lemon;
            font-weight: 600;
            transition: border-left-color .3s ease, background-color .3s ease;

            .cromis-body & {
                border-left-color: $color-blue-aquamarine;
            }

            & > * {
                opacity: 1;
                transition: opacity .3s ease;
            }
        }

        & > * {
            opacity: .5;
            transition: opacity .3s ease;
        }
    }

    &-image {
        display: flex;
        justify-content: center;
        height: 100%;
        min-width: 20px;
        margin-left: 40px;
        text-align: center;

        img {
            vertical-align: -3px;
            align-self: center;
        }
    }

    &-label {
        display: flex;
        overflow: hidden;
        margin-top: 3px;
        margin-left: 35px;

        & > span {
            visibility: hidden;
            opacity: 0;
            transition: .1s opacity 0s, 0s visibility .2s;
        }
    }
}
