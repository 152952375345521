.list {
    padding-top: 25px;
    border-top: 1px solid $color-grey-mercury;
    border-bottom: 1px solid $color-grey-mercury;

    &-menu {
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color-grey-light;
        cursor: pointer;

        .active {
            color: $color-primary;
            background-color: $color-white;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }

        &-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 60px;
            margin-bottom: 0;
            padding: 0;
            background-color: $color-grey-gallery-alt;
        }
    }

    &-content {
        display: none;

        &.active {
            display: block;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            margin-top: 1px;
            padding: 35px;
            background-color: $color-white;
            list-style: none;

            &:hover {
                z-index: 2;
                box-shadow: 0 2px 40px 0 rgba(0, 0, 0, .1);

                a {
                    text-decoration: none;
                }

                h2 {
                    color: $color-secondary;
                }
            }

            a {
                color: $color-grey-mineshaft;
            }

            h2 {
                font-size: 24px;
            }
        }
    }
}
