&-specieslist {
    /////////////////////////////////////////////////////////////////
    // ORDERING
    /////////////////////////////////////////////////////////////////
    &-ordering {
        display: flex;
        margin-top: 20px;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        &-title {
            font-family: $font-gotham-bold;
            font-size: 16px;
            line-height: 28px;
        }

        &-item {
            margin-top: 6px;
            margin-left: 30px;
            user-select: none;
            transition: $opacity-transition;

            @include media-breakpoint-down(xs) {
                margin-top: 10px;
                margin-left: 0;
            }

            &:not(.active):hover {
                opacity: .7;
                cursor: pointer;
                transition: $opacity-transition;
            }

            &.active {
                color: $color-yellow-laser-lemon;
                font-weight: 600;
                transition: $opacity-transition;

                .cromis-body & {
                    color: $color-blue-aquamarine;
                }
            }
        }
    }
    /////////////////////////////////////////////////////////////////
    // SPECIES
    /////////////////////////////////////////////////////////////////
    &-species {
        &-title {
            margin-bottom: 15px;
            color: $color-yellow-laser-lemon;
            font-family: $font-gotham-bold;
            font-size: 30px;
            line-height: 40px;

            .cromis-body & {
                color: $color-blue-aquamarine;
            }

            small {
                margin-left: 10px;
                vertical-align: 3px;
                font-family: $font-gotham;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}
