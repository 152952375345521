&-diveeditinfo {
    /////////////////////////////////////////////////////////////////
    // GENERAL
    /////////////////////////////////////////////////////////////////
    &-general {
        #dive_informations_date {
            &::placeholder {
                @include media-breakpoint-down(xs) {
                    color: transparent;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // GROUP
    /////////////////////////////////////////////////////////////////
    &-group {
        // .divelog-stamp {
        //     margin-top: 26px;
        // }
        &-dive {
            text-align: center;

            select {
                width: 150px;
            }
        }

        &-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 25px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: center;
            }
        }

        &-left {
            display: flex;
            margin-right: 30px;

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
            @include media-breakpoint-down(xs) {
                flex-direction: column-reverse;
                width: 100%;
            }

            &-inputs {
                width: 280px;

                @include media-breakpoint-down(md) {
                    width: 250px;
                }
                @include media-breakpoint-down(sm) {
                    width: 280px;
                }
                @include media-breakpoint-down(xs) {
                    width: 100%;
                }

                input {
                    max-width: 100% !important;
                }
            }

            &-link {
                a {
                    display: block;
                    margin-top: 10px;
                    margin-left: 30px;

                    @include media-breakpoint-down(md) {
                        margin-top: 2px;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-top: 10px;
                    }
                    @include media-breakpoint-down(xs) {
                        margin-top: 0;
                        margin-bottom: 15px;
                        margin-left: 0;
                    }
                }
            }

            .supervisor-alert {
                margin-top: 15px;
                white-space: nowrap;

                @include media-breakpoint-down(sm) {
                    white-space: inherit;
                }
            }
        }

        &-right {
            width: 280px;

            @include media-breakpoint-down(md) {
                width: 220px;
            }
            @include media-breakpoint-down(sm) {
                width: 280px;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // SITE
    /////////////////////////////////////////////////////////////////
    &-site {
        $dive-edit-site-columns-separator-width: 150px;
        $dive-edit-site-columns-separator-width-lg: 100px;
        $dive-edit-site-columns-height: 266px;

        &-columns {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        &-column {
            flex: 0 1 calc(50% - (#{$dive-edit-site-columns-separator-width} / 2));
            height: $dive-edit-site-columns-height;
            padding: $size-default-lg;
            background-color: rgba($color-white, .1);

            @include media-breakpoint-down(lg) {
                flex: 0 1 calc(50% - (#{$dive-edit-site-columns-separator-width-lg} / 2));
            }
            @include media-breakpoint-down(md) {
                flex: 1 1 auto;
                height: auto;
            }
            @include media-breakpoint-down(xs) {
                padding: $size-default-md;
            }

            &--white {
                border-top-left-radius: 15px;
                background-color: rgba($color-white, .1);

                @include media-breakpoint-down(md) {
                    border-top-right-radius: 15px;
                }
            }

            &--white-cromis {
                flex: auto;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                background-color: rgba($color-white, .1);
            }

            &--black {
                border-top-right-radius: 15px;
                background-color: rgba($color-black, .1);

                @include media-breakpoint-down(md) {
                    border-top-right-radius: 0;
                }
            }

            &-text {
                height: 65px;
                margin-bottom: $size-default-md;

                @include media-breakpoint-down(lg) {
                    font-size: 13px;
                }
                @include media-breakpoint-down(md) {
                    height: auto;
                }
            }

            &-text-cromis {
                height: 35px;
                text-align: center;
                margin-bottom: $size-default-md;

                @include media-breakpoint-down(lg) {
                    font-size: 13px;
                }
                @include media-breakpoint-down(md) {
                    height: auto;
                }
            }

            .divelog-section-subtitle {
                text-align: center;
            }
        }

        &-separator {
            position: relative;
            display: flex;
            flex: 0 0 $dive-edit-site-columns-separator-width;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(lg) {
                flex: 0 0 $dive-edit-site-columns-separator-width-lg;
            }
            @include media-breakpoint-down(md) {
                flex: 0 0 auto;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                height: 0;
                width: 0;
                border-color: rgba($color-white, .1) transparent transparent transparent;
                border-style: solid;
                border-width: $dive-edit-site-columns-height $dive-edit-site-columns-separator-width 0 0;

                @include media-breakpoint-down(lg) {
                    border-width: $dive-edit-site-columns-height $dive-edit-site-columns-separator-width-lg 0 0;
                }
                @include media-breakpoint-down(md) {
                    bottom: 50%;
                    height: auto;
                    width: 100%;
                    border: 0;
                    background-color: rgba($color-white, .1);
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                height: 0;
                width: 0;
                border-color: transparent transparent rgba($color-black, .1) transparent;
                border-style: solid;
                border-width: 0 0 $dive-edit-site-columns-height $dive-edit-site-columns-separator-width;

                @include media-breakpoint-down(lg) {
                    border-width: 0 0 $dive-edit-site-columns-height $dive-edit-site-columns-separator-width-lg;
                }
                @include media-breakpoint-down(md) {
                    top: 50%;
                    height: auto;
                    width: 100%;
                    border: 0;
                    background-color: rgba($color-black, .1);
                }
            }

            &-inner {
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                height: $size-default-xxl;
                width: $size-default-xxl;
                border-radius: 50%;
                background-color: $color-white;
                color: $color-blue-cobalt;
                font-family: $font-bebas;
                font-size: 30px;
                text-transform: uppercase;

                .cromis-body & {
                    color: $color-green-niagara;
                }

                @include media-breakpoint-down(xs) {
                    height: $size-default-xl;
                    width: $size-default-xl;
                    font-size: 22px;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // PROFILE
    /////////////////////////////////////////////////////////////////
    &-profile {
        position: relative;

        &-background {
            position: absolute;
            top: -455px;
            // top: -490px;
            right: -390px;
            left: -390px;
            z-index: -2;

            @include media-breakpoint-down(lg) {
                top: -390px;
            }
            @include media-breakpoint-down(md) {
                top: -312px;
            }
            @include media-breakpoint-down(sm) {
                top: -285px;
            }

            img {
                width: 100%;

                @include media-breakpoint-down(xs) {
                    width: 1325px;
                }
            }
        }

        &-content {
            position: relative;
            margin-top: 75px;
            padding-bottom: 30px;

            &--scubadiving {
                padding-bottom: 0;
            }

            &--freediving {
                padding-bottom: 135px;

                @include media-breakpoint-down(lg) {
                    padding-bottom: 110px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 260px;
                    padding-bottom: 15px;
                }
            }

            &--snorkeling {
                margin-top: 190px;
                padding-bottom: 0;
            }

            .form-group-horizontaler {
                .form-group {
                    @include media-breakpoint-down(xs) {
                        display: block;
                    }

                    .inputs-wrapper {
                        .form-group {
                            @include media-breakpoint-down(xs) {
                                margin-bottom: 0;
                            }
                        }
                    }

                    label {
                        max-width: 100px !important;

                        @include media-breakpoint-down(xs) {
                            max-width: inherit !important;
                            margin-right: 0;
                            margin-bottom: .5rem;
                            text-align: left;
                        }
                    }
                }
            }
        }

        &-absoluter {
            position: relative;
            height: 385px;
            margin-bottom: 30px;

            @include media-breakpoint-down(lg) {
                height: 345px;
            }
            @include media-breakpoint-down(md) {
                height: 300px;
            }
            @include media-breakpoint-down(sm) {
                height: 275px;
            }
            @include media-breakpoint-down(xs) {
                height: auto;
            }
        }

        &-flexer {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start !important;
            }
        }

        &-duration {
            flex: 0 0 260px;

            &-total {
                margin-top: 45px;

                @include media-breakpoint-down(xs) {
                    margin-top: 1.5rem;
                }
            }

            .form-with-unit {
                &--small {
                    input[type='number'] {
                        width: 47px !important;
                    }
                }

                input {
                    width: 68px !important;
                }
            }
        }

        &-depth {
            position: absolute;
            bottom: 6px;
            left: 280px;

            @include media-breakpoint-down(lg) {
                left: 200px;
            }
            @include media-breakpoint-down(md) {
                left: 70px;
            }
            @include media-breakpoint-down(sm) {
                left: 30px;
            }
            @include media-breakpoint-down(xs) {
                position: static;
                bottom: auto;
                left: auto;
            }

            .form-with-unit {
                input {
                    width: 120px !important;
                }
            }
        }

        &-stages {
            margin-top: 45px;

            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }

            .row {
                width: calc(100% + 30px);
            }
        }

        &-categories {
            margin-top: 30px;
        }

        &-category {
            opacity: .3;
            transition: $opacity-transition;

            @include media-breakpoint-down(md) {
                display: flex;
                align-items: flex-end;
                margin-bottom: 50px;
            }
            @include media-breakpoint-down(xs) {
                align-items: flex-start;
            }

            &:hover {
                opacity: .7;
                transition: $opacity-transition;
            }

            &.active {
                opacity: 1;
                transition: $opacity-transition;

                .divelog-diveeditinfo-profile-category-content {
                    display: block;

                    @include media-breakpoint-down(md) {
                        display: flex;
                    }
                    @include media-breakpoint-down(xs) {
                        flex-wrap: wrap;
                        width: calc(100vw - 130px - 60px);
                    }
                }
            }

            &-pointer {
                cursor: pointer;

                @include media-breakpoint-down(md) {
                    width: 130px;
                    margin-right: 30px;
                }
                @include media-breakpoint-down(xs) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 180px;
                }
            }

            &-content {
                display: none;
            }

            &-checkbox {
                min-height: 71px;

                @include media-breakpoint-down(md) {
                    min-height: inherit;
                }
                @include media-breakpoint-down(md) {
                    max-width: 130px;
                }
                @include media-breakpoint-down(xs) {
                    min-height: 34px;
                }

                .form-group {
                    .checkbox {
                        @include media-breakpoint-down(md) {
                            flex-direction: row-reverse;
                        }

                        &-checkmark {
                            @include media-breakpoint-down(md) {
                                flex-basis: 20px;
                                flex-grow: 0;
                                flex-shrink: 0;
                            }
                        }

                        label {
                            @include media-breakpoint-down(md) {
                                margin-top: 2px;
                                margin-left: 14px;
                                font-size: 13px;
                                line-height: 16px;
                                text-align: left;
                            }
                        }
                    }
                }
            }

            &-image {
                margin-bottom: 30px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }

                img {
                    display: block;
                    max-height: 150px;
                    max-width: 150px;
                    margin: 0 auto;

                    @include media-breakpoint-down(lg) {
                        max-height: 130px;
                        max-width: 130px;
                    }
                }
            }

            .form-group + .form-group {
                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }
            }

            &-depth {
                @include media-breakpoint-down(xs) {
                    flex-grow: 1;
                    margin-top: 23px;
                }
            }

            &-comment {
                @include media-breakpoint-down(md) {
                    margin-left: 30px;
                }
                @include media-breakpoint-down(xs) {
                    width: calc(100% + 160px);
                    margin-top: 20px;
                    margin-left: 0;
                    margin-left: -160px;
                }

                .form-group {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }

                textarea {
                    @include media-breakpoint-down(md) {
                        min-height: 130px;
                    }
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // EQUIPMENT
    /////////////////////////////////////////////////////////////////
    &-equipment {
        position: relative;

        &-background {
            position: absolute;
            right: 250px;
            left: 250px;
            z-index: -2;

            @include media-breakpoint-down(lg) {
                top: 125px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }

            img {
                width: 100%;
            }
        }

        &-content {
            display: flex;
            justify-content: space-between;
            min-height: 295px;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
        }

        &-left {
            width: 300px;

            @include media-breakpoint-down(sm) {
                width: calc(50% - 15px);
                margin-right: 7.5px;
            }
            @include media-breakpoint-down(xs) {
                width: 100%;
                margin-right: 0;
            }
        }

        &-right {
            width: 300px;

            @include media-breakpoint-down(sm) {
                width: calc(50% - 15px);
                margin-left: 7.5px;
            }
            @include media-breakpoint-down(xs) {
                width: 100%;
                margin-left: 0;
            }
        }

        &-square-left,
        &-square-right {
            position: absolute;
            top: 45px;
            border-color: $color-white;
            border-top: 2px solid;
            opacity: .2;
        }

        &-square-left {
            left: calc(300px + 15px);
            border-right: 2px solid;

            &--scubadiving {
                height: 35px;
                width: 150px;

                @include media-breakpoint-down(lg) {
                    height: 0;
                    width: 0;
                }
            }

            &--freediving {
                height: 0;
                width: 0;
            }
        }

        &-square-right {
            right: calc(300px + 15px);
            border-left: 2px solid;

            &--scubadiving {
                height: 85px;
                width: 190px;

                @include media-breakpoint-down(lg) {
                    height: 0;
                    width: 0;
                }
            }

            &--freediving {
                height: 0;
                width: 0;
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // CONDITIONS
    /////////////////////////////////////////////////////////////////
    &-conditions {
        &-left {
            width: 425px;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            .form-with-unit {
                input {
                    width: 70px !important;
                }
            }
        }

        &-right {
            @include media-breakpoint-down(lg) {
                padding-left: 25px;
            }
            @include media-breakpoint-down(md) {
                margin-top: 15px;
                padding-left: 0;
            }

            &-content {
                margin-top: 29px;

                @include media-breakpoint-down(md) {
                    margin-top: 0;
                }
            }
        }

        .form-group-customradio .control-label {
            width: 70px;
        }

        .form-group-horizontaler {
            .form-group {
                @include media-breakpoint-down(xs) {
                    display: block;
                }

                .inputs-wrapper {
                    .form-group {
                        @include media-breakpoint-down(xs) {
                            margin-bottom: 0;
                        }
                    }
                }

                label {
                    @include media-breakpoint-down(xs) {
                        margin-right: 0;
                        margin-bottom: .5rem;
                        text-align: left;
                    }
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////
    // COMMENTARY
    /////////////////////////////////////////////////////////////////
    &-commentary {
        &-evaluation {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }

        textarea {
            min-height: 150px;
            width: 600px;
            margin-right: auto;
            margin-left: auto;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}

.ui-autocomplete {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index:1000 !important;
}