.partnerssection {
    margin-bottom: 50px;

    .nav {
        justify-content: center;
        margin-bottom: 45px;
        color: $color-grey-dovegray;
        font-family: $font-gotham;
        font-size: 12px;

        &-link {
            margin: 0;
            padding: 0 10px;

            &.active,
            &:active,
            &:focus,
            &:hover,
            &[aria-selected=true] {
                color: $color-primary;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }

            &.active {
                font-weight: 600;
            }
        }
    }

    &-partners {
        position: relative;
        display: flex;
        align-items: center;
        height: 180px;

        @include responsive-md {
            height: 150px;
        }
        @include responsive-sm {
            height: 100px;
        }

        &-link {
            display: block;
        }

        &-element {
            max-height: 180px;
            max-width: 150px;

            @include responsive-md {
                max-height: 150px;
                max-width: 100px;
            }
            @include responsive-sm {
                max-height: 100px;
            }
        }

        img {
            filter: grayscale(100%);
            @include responsive-md() {
                width: 65px !important;
            }
            &:hover {
                filter: grayscale(0);
            }
        }

        .owl {
            &-carousel {
                position: static;
            }

            &-stage-outer {
                padding-right: 60px;
                padding-left: 60px;

                @include responsive-md {
                    padding-right: 50px;
                    padding-left: 50px;
                }
                @include responsive-sm {
                    padding-right: 30px;
                    padding-left: 30px;
                }
            }

            &-stage {
                display: flex;
                align-items: center;
            }

            &-prev,
            &-next {
                position: absolute;
                top: 0;
                height: 180px;
                width: 60px;
                background: $color-white !important;
                color: rgba($color-primary, .5) !important;
                font-size: 70px !important;
                font-weight: 600 !important;
                transition: color .2s ease;

                .cromis-body & {
                    color: rgba($color-cromis-primary, .5) !important;
                }

                @include responsive-md {
                    height: 150px;
                    width: 50px;
                }
                @include responsive-sm {
                    height: 100px;
                    width: 30px;
                    font-size: 40px !important;
                }

                &:hover {
                    color: rgba($color-primary, 1) !important;
                    transition: color .2s ease;

                    .cromis-body & {
                        color: rgba($color-cromis-primary, 1) !important;
                    }
                }
            }

            &-prev {
                left: 0;
                text-align: left;
            }

            &-next {
                right: 0;
                text-align: right;
            }

            &-dots {
                display: none;
            }
        }
    }
}
