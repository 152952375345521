.footer-portal-down {
}
  .footer {
    padding: 2.5rem 1.25rem;
    background-color: $blue;
    color: $white;
    text-align: center;

    @include respond-to('small') {
      text-align: left;
    }
  }

  .footer-logo {
    grid-column: 1 / 5;

    @include respond-to('medium') {
      grid-column: 1 / 2;
    }

    @include respond-to('large') {
      grid-column: 1 / 3;
    }
  }

  .address {
    grid-column: 1 / 5;

    @include respond-to('medium') {
      grid-column: 2 / 3;
    }

    @include respond-to('large') {
      grid-column: 3 / 6;
    }
  }

  .menu {
    grid-column: 1 / 5;

    @include respond-to('medium') {
      grid-column: 3 / 4;
    }

    @include respond-to('large') {
      grid-column: 6 / 8;
    }
  }

  .socials {
    grid-column: 1 / 5;

    @include respond-to('medium') {
      grid-column: 4 / 5;
    }

    @include respond-to('large') {
      grid-column: 9 / 13;
    }
  }
