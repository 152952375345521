.ffessm_form {
    input[type=file] {
        font-size: 10px;
        font-weight: 400;
        margin-top: 6px;
        padding: 7.5px;
    }

    input[type=submit] {
        cursor: pointer;
    }

    &.form-inline,
    .form-inline {
        .form-group {
            align-items: flex-start;
            margin-left: $size-default-md;
        }

        label {
            margin-right: $size-default-lg;
            padding-top: 10px;
        }
    }

    &.form-search,
    .form-search {
        .form-group {
            margin-left: 0px;
            & + .form-group {
                margin-left: $size-default-xxl;
                @media (max-width: 801px) {
                    margin-left: 0px;
                }
            }
        }
        label {
            font-family: 'bebas_neuebold';
            font-size: 15px !important;
            letter-spacing: 1px;
        }
    }

    .form-group {
        &.has-error {
            .form-control {
                border: 2px solid $color-red-alizarin;
            }
        }

        &-helper {
            margin-top: $size-default-xs;
            font-size: 12px;
            color: $color-grey-gray;
        }

        .control-label {
            font-size: 14px;
        }
    }

    select.form-control {
        padding-right: 35px;
        cursor: pointer;
    }

    .form-control {
        border: 2px solid $color-grey-gallery;
        border-radius: 0;
        font-weight: 600;

        &:focus {
            border-color: $color-primary;
            box-shadow: none;

            .cromis-body & {
                border-color: $color-cromis-primary;
            }
        }
    }

    .checkbox {
        display: flex;
        margin-bottom: 0;

        input {
            opacity: 0;

            &:checked ~ .checkbox-checkmark {
                border: 2px solid $color-primary;

                .cromis-body & {
                    border: 2px solid $color-cromis-primary;
                }

                .checkbox-checkmark-inner {
                    display: block;
                }
            }
        }

        label {
            margin-bottom: 0;
            padding-left: 25px;
            cursor: pointer;
        }

        &-checkmark {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border: 2px solid $color-grey-gallery;
            border-radius: 50%;
            background-color: $color-white;
            cursor: pointer;

            &-inner {
                position: absolute;
                top: 3px;
                left: 3px;
                display: none;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-primary;

                .cromis-body & {
                    background-color: $color-cromis-primary;
                }
            }
        }

        & + .help-block {
            ul {
                padding-left: 37px;
            }
        }
    }
}
