.contentpage {
    padding-top: 120px;
    background-color: $color-grey-wild-sand;

    @include responsive-md() {
        padding-top: 98px;
    }

    &--full {
        .contentpage-content {
            margin-top: 0;
            padding-right: 0;

            &-title {
                padding-right: 0;
            }
        }
    }

    &--large {
        .contentpage {
            &-discoveries-separator {
                margin-top: $size-default-xxxl;
            }
        }
    }

    &-header {
        min-height: 250px;
        margin: 0 -15px;
        background: $gradient-cerulean-blumine;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        // TODO refacto: campaign is no longer in header
        &-campaign {
            max-width: 100%;;
            margin: auto auto $size-default-lg auto;
            text-align: center;

            iframe {
                max-width: 100%;
            }
        }
    }

    &-background {
        position: absolute;
        display: flex;
        margin: 0 -15px;

        @include responsive-md() {
            display: none;
        }

        &-left {
            display: inline-block;
            width: 61vw;
            margin-top: -130px;
            background-color: $color-grey-wild-sand;

            @include responsive-mdh() {
                width: 80vw;
            }
        }

        &-right {
            height: 700px;
            width: 39vw;
            background-color: $color-white;

            @include responsive-mdh() {
                width: 20vw;
            }
        }
    }

    &-col {
        &-background {
            position: absolute;
            top: 0;
            left: -15px;
            z-index: 1;
            height: 100%;
            width: 100vw;
            background-color: $color-white;
        }
    }

    &-content {
        z-index: 2;
        margin-top: -130px;
        padding: $size-default-xl $size-default-lg $size-default-xl 0;

        @include responsive-lg {
            padding: $size-default-lg 0;
        }
        @include responsive-md() {
            margin-top: 0;
            padding: $size-default-md 0 $size-default-lg;
            font-size: 14px;
        }

        &-breadcrumb {
            @include responsive-md {
                display: none;
            }

            &-gradient {
                position: absolute;
                top: -120px;
                left: -100vw;
                z-index: -1;
                height: 250px;
                width: 200vw;
                background: linear-gradient(0deg, #01001a 0%, rgba(1, 0, 26, 0) 100%);
                opacity: .5;
            }
        }

        &-title {
            margin-bottom: $size-default-xl;
            color: #0f1d48;
            font-family: 'Gotham-Bold';
            font-size: 37px;
            line-height: 50px;
            text-transform: inherit;

            @include responsive-md() {
                margin-bottom: $size-default-lg;
                padding-right: 0;
                font-size: 22px;
                line-height: 37px;
            }

            &-subtitle {
                display: block;
                font-size: 20px;
                line-height: 30px;

                &--bold {
                    font-family: 'Gotham-Bold';
                }

                img {
                    height: 18px;
                    max-width: 18px;
                    margin-right: 5px;
                    vertical-align: -3px;
                }
            }
        }

        h2 {
            margin: 70px 0 50px;
            color: $color-primary;
            font-family: 'Gotham-Bold';
            font-size: 24px;

            .cromis-body & {
                color: $color-cromis-primary;
            }

            @include responsive-md() {
                margin: 45px 0 30px;
                font-size: 18px;
            }
        }

        h3 {
            margin: 50px 0 30px;
            color: #0f1d48;
            font-family: 'Gotham-Bold';
            font-size: 24px;

            @include responsive-md() {
                margin: 30px 0 20px;
                font-size: 16px;
            }
        }

        h4 {
            margin: 35px 0 20px;
            color: #0f1d48;
            font-family: 'Gotham-Bold';
            font-size: 20px;

            @include responsive-md() {
                margin: 20px 0 15px;
                font-size: 14px;
            }
        }

        h5 {
            margin: 25px 0 20px;
            color: #0f1d48;
            font-family: 'Gotham-Bold';
            font-size: 17px;

            @include responsive-md() {
                margin: 15px 0;
                font-size: 13px;
            }
        }

        &-metas {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 45px 0;

            @include responsive-md() {
                justify-content: space-between;
                margin: 30px 0;
            }

            &--wrap {
                @include responsive-lg {
                    flex-direction: column;
                    align-items: flex-start;

                    & > * {
                        width: 100% !important;
                        margin-bottom: $size-default-sm;
                    }
                }
            }

            &-fields {
                display: flex;

                @include responsive-md {
                    display: block;
                }

                .form-group {
                    @include responsive-sm {
                        width: 100%;
                        margin-bottom: $size-default-md;
                        margin-left: 0 !important;
                    }
                }
            }

            &-filter {
                display: inline-block;
                width: inherit;
                padding-right: 40px;
                cursor: pointer;
                outline: none;
            }

            &-author {
                color: $color-primary;
                font-size: 12px;

                .cromis-body & {
                    color: $color-cromis-primary;
                }
            }

            &-reset {
                margin-left: $size-default-md + 2px;

                @include responsive-md {
                    margin-top: $size-default-md;
                    margin-left: 0;
                }
                @include responsive-sm {
                    margin-top: 0;
                }

                a {
                    height: 34px;
                    margin-top: 2px;
                    background-color: $color-grey-gray !important;
                    opacity: 1;
                    transition: opacity .2s ease 0s;

                    &:hover {
                        opacity: .7;
                        transition: opacity .2s ease 0s;
                    }
                }

                img {
                    height: 10px;
                    margin-right: 5px;
                    vertical-align: -1px;
                }
            }
        }

        &-headline {
            margin-bottom: 45px;
            color: $color-grey-mineshaft;
            font-family: 'Gotham-Bold';
            font-size: 18px;

            @include responsive-md {
                margin-bottom: 40px;
                font-size: 14px;
            }
        }

        &-text {
            a {
                color: $color-primary !important;
                text-decoration: underline;

                .cromis-body & {
                    color: $color-cromis-primary !important;
                }
            }

            a:hover {
                color: $color-secondary !important;
                text-decoration: underline;
            }

            strong {
                font-family: $font-gotham-bold;
            }

            img {
                margin: 1rem;
            }

            .videodetector {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;

                iframe {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }

                .remove-videodetector {
                    display: none;
                }
            }
        }

        &-mainpicture {
            margin-bottom: 40px;
            text-align: center;

            img {
                max-width: 100%;
            }
        }

        .smalltext {
            font-size: .8rem;
        }
    }

    &-ads {
        iframe {
            @include media-breakpoint-down(lg) {
                display: block;
                margin: 1.5rem auto;
            }
        }
    }

    &-aside {
        position: relative;
        z-index: 3;
        padding: 55px 30px 60px 15px;
        background-color: $color-white;

        @include responsive-lg {
            padding: 30px 0;
        }

        &-campaign {
            &--300x250 {
                display: none;

                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }

            &--300x600 {
                height: 600px !important;
                width: 300px !important;
            }
        }

        &-separator {
            margin-top: $size-default-lg;
            margin-bottom: $size-default-lg;
        }

        &-related {
            font-size: 14px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    & + li {
                        margin-top: 20px;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        line-height: 19px;
                    }
                }
            }

            &-image {
                width: 100px;
                margin-right: 15px;
            }
        }

        &-downloads,
        &-links {
            ul {
                margin: 0;
                padding: 0 0 0 10px;
                list-style: none;

                li {
                    & + li {
                        margin-top: 30px;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        line-height: 19px;
                    }

                    svg {
                        flex-grow: 0;
                        flex-shrink: 0;
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    &-discoveries {
        padding-top: $size-default-xxl;
        padding-bottom: $size-default-xl;

        @include responsive-lg {
            padding-top: $size-default-xl;
            padding-bottom: $size-default-lg;
        }

        &-separator {
            margin-top: $size-default-xl;

            @include responsive-lg {
                display: none;
            }
        }

        &-container {
            position: relative;

            @include responsive-md {
                margin-right: -15px;
            }
        }

        &-slider {
            @include responsive-md {
                display: none;
                margin: 0;
            }

            [class^='col-'] {
                @include responsive-md {
                    padding: 0;
                }
            }
        }

        &-element {
            display: block;

            @include responsive-md {
                width: 220px;
            }

            &-title {
                margin-top: 30px;
                margin-bottom: .5rem;
                font-family: inherit;
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.2;

                @include responsive-lg {
                    margin-top: 15px;
                    font-size: 1.3rem;
                }
                @include responsive-lg {
                    font-size: 15px;
                    line-height: 19px;
                }
            }

            &-title-compact {
                margin-top: 12px;
                margin-bottom: 45px;
                font-family: inherit;
                font-size: 1.3rem;
                font-weight: 500;
                line-height: 1.2;

                @include responsive-lg {
                    font-size: 1.1rem;
                }
                @include responsive-lg {
                    font-size: 15px;
                    line-height: 19px;
                }
            }
        }

        &-nav {
            position: absolute;
            top: -50px;
            right: 0;
            z-index: 2;
            display: none;

            @include responsive-md() {
                display: block;
            }

            button {
                display: block;
                height: 50px;
                width: 50px;
                border: 0;
                background-color: $color-grey-wild-sand;
                cursor: pointer;

                @include responsive-lg {
                }

                svg {
                    vertical-align: -3px;
                }

                &:hover {
                    background-color: $color-primary;

                    .cromis-body & {
                        background-color: $color-cromis-primary;
                    }

                    svg {
                        path {
                            fill: $color-white;
                        }
                    }
                }
            }
        }

        &-metas {
            margin-top: 15px;
            color: $color-primary;
            font-size: 12px;

            .cromis-body & {
                color: $color-cromis-primary;
            }
        }
    }
}
