.show {
    h2 {
        text-transform: uppercase;
    }

    .blue {
        font-weight: 400;
        color: $color-primary;

        .cromis-body & {
            color: $color-cromis-primary;
        }
    }

    span {
        font-weight: 700;
        color: $color-grey-mineshaft;

        &.address {
            &::after {
                content: url('/img/home.svg');
                padding: 0 5px;
            }
        }

        &.mail {
            &::after {
                content: url('/img/mail.svg');
                padding: 0 5px;
            }
        }

        &.phone {
            &::after {
                content: url('/img/phone.svg');
                padding: 0 5px;
            }
        }
    }

    p {
        color: $color-grey-dovegray;
    }
}
