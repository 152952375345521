.login_portal {
  margin: 15px 0;
}

.login {
  display: flex;
  align-items: center;
  color: $grey-300;

  &_label {
    display: none;
    margin-right: 2rem;
    text-align: right;

    @include respond-to('small') {
      display: block;
    }
  }

  &_name {
    margin-bottom: 0.125rem;
    color: $grey-100;
    font-family: $font-secondary;
    font-size: 1.25rem;
    letter-spacing: 0.1875rem;
    text-transform: uppercase;
  }

  &_picto {
    flex-shrink: 0;
    min-width: 1.125rem;
  }
}